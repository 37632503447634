<template>
 <v-row >
    <v-col sm="12">
      <v-container>
           <v-row no-gutters>
    <v-col cols="12" sm="12">
       <div class="job-title">
          <p><b v-if="cardData.Custom3code === 'PJ'">WBS</b> <b v-if="cardData.Custom3code != 'PJ'">CC</b> <span> - {{cardData.ChargeCode}} - {{cardData.costObject}}</span> </p>       </div>
        <v-simple-table fixed-header outline >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Expense Type</th>
                <th class="text-left">Transaction Date</th>
                <th class="text-left">Vendor</th>
                <th class="text-left">Location</th>
                <th class="text-left">Transaction Amount</th>
                <th class="text-left">Payment Type</th>
                <th class="text-left">Personal Expense</th>
                <th class="text-left">Receipt</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="concurItems in cardData.items" :key="concurItems.id">
                <td>{{concurItems.ExpenseTypeName}}</td>
                <td>{{showDate(concurItems.transactionDate)}}</td>
                <td>{{concurItems.vendor}}</td>
                <td>{{concurItems.location}}</td>
                <td>{{showCurrency(concurItems.transactionAmount)}} {{concurItems.transactionCurrency}}</td> 
                <td>{{concurItems.paymentType}}</td>
                <td>{{concurItems.personalExpense}}</td>
                <td> 
                  <a v-on:click="showFile(concurItems.ReportEntryID)"> <v-icon> mdi-file-image</v-icon></a>

                  <v-dialog v-model="dialog" width="800" :retain-focus="false">
                    <v-card justify="center" align="center">
                      <v-card-title
                        class="headline grey lighten-2"
                        primary-title
                      >
                        Attachments 
                      </v-card-title>
                       <clip-loader justify="center" align="center" v-if="loading === true" color="#00607A" height="20" width="20"></clip-loader>
                      <iframe v-if="showing === true" :src="lineitemReceipt.data.imageURL" type="application/pdf" width="100%" 
                        height="400px" frameborder="0" style="position:relative;z-index:999; padding:10px" ref="frame" @load="load">
                      </iframe>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="dialog = false"
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </td>  
              </tr>
            </tbody>
          </template>
        </v-simple-table>
     </v-col>
  </v-row>
      </v-container>
      </v-col>
    </v-row>
</template>
<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
export default {
  props: ['cardData','imageURL'],
  components:{
    ClipLoader
  },
  data() {
   return  {
      lineitemReceipt:'',
      dialog: false,
      showing: false,
      iframe:'',
      loaded: false,
      loading: false,
    }
  },
  methods: {
    async showFile (lineitemId)  {
      this.showing = false;
      this.dialog = true;
       this.loading = true;
       const lineitemReceipt  = await this.$axios.get("concur/image/lineitem/" + lineitemId ) 
       .then(response => {
        return response.data;
      }); 
       this.lineitemReceipt= lineitemReceipt;
       this.loading = false;
      this.showing = true;
    },
      load: function(){
        this.loaded = true;
      }, 
      showCurrency(amount) {
      if (!amount) return ""
      return (
        this.$store.state.currencyFormat === "comma"
        ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
        : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
      )
    },
      showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },
      review(card){
        this.$emit('reviewed', card);
      },
  }
 }  
</script>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr.compassTable > td{
  text-align: center;
}
.theme--light.v-data-table tr td{
  text-align: center !important;
}
.v-application .job-title p{
  margin: 0 5px;
  padding: 0 0 5px;
  color:#000;
  text-transform: uppercase;
}
.job-title p span{
  font-weight:normal;
  text-transform:capitalize;
  font-size: 13px;
}
</style>