<template>
 <v-row >
    <v-col sm="12">
      <v-container>
           <v-row no-gutters>
    <v-col cols="12" sm="12">
      <v-simple-table fixed-header outline >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Expense Type</th>
                <th class="text-left">Transaction Date</th>
                <th class="text-left">Details</th>
                <th v-bind:class="{active: expentype}" style="display:none" >No of Nights</th>
                <th v-bind:class="{active: expentype}" style="display:none">Nightly Rates InclTax</th>
                <th style="width: 130px;" class="text-left">Amount</th>
                <th style="width: 130px;" class="text-left">Requested</th>
              </tr>
            </thead>
            <tbody v-for="concurItems in cardData.expectedExpenses" :key="concurItems.id">
              <tr>
                <td>{{concurItems.expenseTypeName}}</td>
                <td>{{showDate(concurItems.date)}}</td>
                <td>{{concurItems.details}}</td>
                <td v-bind:class="{active: expentype}" style="display:none" >{{concurItems.noOfNights}}</td>
                <td v-bind:class="{active: expentype}" style="display:none" >{{concurItems.nightlyRatesInclTax}}</td>
                <td>{{concurItems.transactionCurrency}} {{showCurrency(concurItems.amount)}} </td> 
                <td>{{cardData.amountCurrency}} {{showCurrency(concurItems.requestedAmount)}} </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
     </v-col>
  </v-row>
      </v-container>
      </v-col>
    </v-row>
</template>
<script>

export default {
  props: ['cardData','imageURL'],
  
  data() {
   return  {
      expentype: false,
    }
  },
  async mounted(){
    const Accommodation = this.cardData.expectedExpenses;
      for (var i =0; i<Accommodation.length; i++) { 
       
        if(Accommodation[i].expenseTypeName === "ACCOMMODATION EXPENSES"){
          this.expentype = true;
        }
       
      }
  },
  methods: {
      showCurrency(amount) {
      if (!amount) return ""
      return (
        this.$store.state.currencyFormat === "comma"
        ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
        : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
      )
    },
      showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },
      review(card){
        this.$emit('reviewed', card);
      },
  }
 }  
</script>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr.compassTable > td{
  text-align: center;
}
.theme--light.v-data-table tr td{
  text-align: center !important;
}
.v-application .job-title p{
  margin: 0 5px;
  padding: 0 0 5px;
  color:#000;
  text-transform: uppercase;
}
.job-title p span{
  font-weight:normal;
  text-transform:capitalize;
  font-size: 13px;
}
.theme--light.v-data-table.v-data-table--fixed-header thead th.active, .theme--light.v-data-table tr td.active{
  display: table-cell !important;

}
</style>