<template>
 <v-row >
    <v-col sm="12">
      <v-container>
        <v-row>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Entity</h4>
                <p class="apprInfo">{{cardData.companyCode}} - {{cardData.companyName}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">PO Number</h4>
                <p>{{cardData.purchaseOrder}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg2>
            <v-container>
              <div>
                <h4 class="apprHeader">Payment Term</h4>
                <p>{{cardData.paymentTerm}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg2>
            <v-container>
              <div>
                <h4 class="apprHeader">Tax%</h4>
                <p>{{cardData.taxPercentage}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg2>
            <v-container>
              <div>
                <h4 class="apprHeader">Tax Amount</h4>
                <p v-if="cardData.tax === 0 ">0</p>
                <p v-else>{{showCurrency(cardData.tax)}}</p>
              </div>
            </v-container>
          </v-flex>
          </v-row>

           <v-row no-gutters>
    <v-col cols="12" sm="12">
        <v-simple-table fixed-header outline >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Line Number</th>
                <th class="text-left">Material</th>
                <th class="text-left">Quantity</th>
                <th class="text-left">Base Unit</th>
                <th class="text-left">Net Value</th>
                <th class="text-left">Tax</th>
              </tr>
            </thead>
            <tbody>
              
              <tr class="compassTable" v-for="compassitems in cardData.items" :key="compassitems.id">
                <td width="120px">{{compassitems.orderLineNumber.slice(4, 5)}}</td>
                <td>{{compassitems.materialNumber}}</td>
                <td>{{compassitems.quantity}}</td>
                <td>{{compassitems.unit}}</td>
                <td>{{showCurrency(compassitems.price)}} {{compassitems.priceCurrency}}</td> 
                <td>
                  <span v-if="compassitems.taxAmount === 0 ">0</span>
                  <span v-else>{{showCurrency(compassitems.taxAmount)}} {{compassitems.taxAmountCurrency}}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
     </v-col>
  </v-row>
      </v-container>
      </v-col>
    </v-row>
</template>
<script>
export default {
  props: ['cardData', 'header'],
 methods: {
    showCurrency(amount) {
        if (!amount) return ""
        return (
          this.$store.state.currencyFormat === "comma"
          ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
          : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
        )
      }
 }
 }  
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr.compassTable > td{
  text-align: center;
}
</style>