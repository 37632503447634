<template>
  <div>
    <v-card>
      <div outlined v-for=" item in items" :key="item.id" class="mt-5">
                <v-container>
                  <div v-if="item.linenumber">
                    <h4 class="content-title">Line Number: {{item.linenumber}}</h4>
                  
                </div>
                  <v-row>
                  <v-flex v-if="header.type === 'invoices' || header.type === 'invoiceReconciliation'" xs12 md4 lg3>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Order</h4>
                        <p class="apprInfo">{{item.order}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex v-if="header.type === 'invoices' || header.type === 'invoiceReconciliation'" xs12 md4 lg3>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Order Line #</h4>
                        <p class="apprInfo">{{item.orderlineNumber === 0 ? '': item.orderlineNumber}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex v-if="header.type === 'invoices' || header.type === 'invoiceReconciliation'" xs12 md4 lg3>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Description</h4>
                        <p class="apprInfo">{{item.description}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex v-if="header.type === 'invoices' || header.type === 'invoiceReconciliation'" xs12 md4 lg3>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Account Assignment</h4>
                        <p class="apprInfo">{{item.account ? item.account.name : ''}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex v-if="header.type === 'requisitions'" xs12 md4 lg3>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Description</h4>
                        <p v-if="header.source === 'ezbuy'" class="apprInfo">{{item.description}}</p>
                        <p v-if="header.source === 'smartbuy'" class="apprInfo">{{item.ItemDescription}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex v-if="header.type === 'requisitions'" xs12 md4 lg3>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Supplier</h4>
                        <p class="apprInfo">{{item.supplier}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md4 lg3>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Quantity</h4>
                        <p>{{item.quantity}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md4 lg3>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Unit</h4>
                        <p>{{item.unit}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex v-if="header.type === 'invoices' || header.type === 'invoiceReconciliation'" xs12 md4 lg3>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Price</h4>
                        <p>{{showCurrency(item.price)}} {{item.priceCurrency}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex v-if="header.type === 'requisitions'" xs12 md4 lg3>
                  <v-container>
                      <div>
                        <h4 class="apprHeader">Account Assignment</h4>
                        <p>{{item.accountAssignment ? item.accountAssignment : ''}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex v-if="header.type === 'requisitions'" xs12 md4 lg3>
                  <v-container>
                      <div>
                        <h4 class="apprHeader">Deliver To</h4>
                        <p>{{item.deliverTo ? item.deliverTo : ''}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex  v-if="header.type === 'requisitions'" xs12 md4 lg3>
                  <v-container>
                      <div>
                        <h4 class="apprHeader">Price</h4>
                        <p>{{showCurrency(item.price)}} {{item.priceCurrency}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex v-if="header.type === 'requisitions'" xs12 md4 lg3>
                  <v-container>
                      <div>
                        <h4 class="apprHeader">Total Cost</h4>
                        <p><b style="color: green">{{showCurrency(item.amount)}} {{item.amountCurrency}}</b></p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex v-if="header.type === 'invoices' || header.type === 'invoiceReconciliation'" xs12 md4 lg3>
                  <v-container>
                      <div>
                        <h4 class="apprHeader">Gross Amount</h4>
                        <p><b style="color: green">{{showCurrency(item.grossAmount)}} {{item.grossAmountCurrency}}</b></p>
                      </div>
                    </v-container>
                  </v-flex>
                </v-row>
                </v-container>
                 <v-container>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                   
                      <v-simple-table fixed-header outline >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">GL Account</th>
                              <th class="text-left">Cost Center</th>
                              <th class="text-left">Project/WBS</th>
                              <th class="text-left">Asset No.</th>
                              <th class="text-left">Type</th>
                              <th class="text-left">Proportion</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="row in item.splitAccountings" :key="row.id">
                              <td >{{`${row.generalLedgerId}(${row.generalLedgerDescription})`}}</td>
                              <td >{{row.costCenterId ? `${row.costCenterId}(${row.costCenter})` : ''}}</td>
                              <td >{{row.WBSElementId ? `${row.WBSElementId}(${row.WBSElement})` : ''}}</td> 
                              <td >{{row.asset ? row.asset : ''}}</td>
                              <td >{{row.type}}</td>
                              <td >{{row.proportion ? row.proportion : '100%'}}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                   
                  </v-col>
                </v-row>
                 </v-container>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['items', 'header'],
  data() {
    return {
      show: null
    }
  },
  methods: {
    showCurrency(amount) {
        if (!amount) return ""
        return (
          this.$store.state.currencyFormat === "comma"
          ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
          : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
        )
      }
  }
  }
</script>

<style lang='scss' scoped>
  .detailsTitle {
    margin-left: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin-top: 10px;
  }

.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
    margin-left: 15.5px;
    margin-right: 40px;
    max-width: 100%;
}
</style>