<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-row no-gutters align="center" justify="center">
          <v-tabs
            v-model="tab"
            align-with-title
            grow
          >
            <v-tabs-slider color="blue"></v-tabs-slider>

            <v-tab>
              <span> <v-icon>mdi-alert-circle</v-icon> Errors ({{ errorNum}}) </span>
            </v-tab>
            <v-tab>
              <span> <v-icon>mdi-comment-quote</v-icon> Feedback ({{ feedbackNum }}) </span>
            </v-tab>
            <v-tab>
              <span> <v-icon>mdi-share-circle</v-icon> Delegates ({{ delegateNum }}) </span>
            </v-tab>
          </v-tabs>
          <v-flex v-if="tab === 0" >
            <v-card-title>
              <v-btn v-if="errorsSelected.length" color="red" @click="errorDeleteDialog = true"> 
                Delete {{errorsSelected.length}} record{{ errorsSelected.length > 1 ? 's' : ''}}? 
              </v-btn>
              <v-spacer v-else></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="errorSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table 
              v-model="errorsSelected"
              :headers="headers" 
              :items="errors" 
              :search="errorSearch"
              show-select 
              calculate-widths
              item-key="id" 
              :loading="errorsLoading"
              loading-text="Loading errors...please wait"
            >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn small v-if="item.isCreateError" @click="viewSource(item)">
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-code-json
                </v-icon>
                View JSON
              </v-btn>
            </template>
            
            </v-data-table>
            <v-dialog scrollable v-model="jsonDialog" persistent>
              <v-card>
                <v-card-title class="justify-center" style="background-color: black; color: white;">
                  Source JSON for Failed Record Creation
                  <v-spacer></v-spacer>
                  <v-card-actions>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn dark v-on="on" :color="copied ? 'success' : 'blue'" @click="copy()"><v-icon>{{copied ? 'mdi-check' : 'mdi-content-copy' }}</v-icon>{{ copied ? 'Copied!'  : ''}}</v-btn>
                      </template>
                      <span>Copy JSON Object</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn dark v-on="on" color="red" @click="jsonDialog = false; json = null;">X</v-btn>
                      </template>
                      <span>Close</span>
                    </v-tooltip>
                  </v-card-actions>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="!json">
                  <v-progress-circular indeterminate color="primary" :size="100" style="margin: 30px"></v-progress-circular>
                  <strong>Loading JSON... </strong>
                </v-card-text>
                <v-card-text v-else>
                  <pre> {{ malformed ? json : JSON.stringify(json, null, '\t') }} </pre>
                </v-card-text>
                <v-card-subtitle v-if="malformed">This JSON could not be properly parsed. <v-btn x-small color="primary" @click="validate()">Click here to copy text and open JSONLint.</v-btn></v-card-subtitle>
              </v-card>
            </v-dialog>
            <v-dialog v-model="errorDeleteDialog" max-width="50%">
              <v-card>
                <v-card-title class="justify-center">Are you sure you want to delete {{errorsSelected.length}} record{{ errorsSelected.length > 1 ? 's' : ''}}?</v-card-title> 
                <v-card-actions class="justify-center">
                  <v-btn color="red" @click="destroy('errors')">Delete Error{{errorsSelected.length > 1 ? 's' : ''}}</v-btn>
                  <v-btn @click="errorDeleteDialog = false">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
          <v-flex v-if="tab === 1" >
            <v-card-title>
              <v-btn v-if="feedbackSelected.length" color="red" @click="feedbackDeleteDialog = true"> 
                Delete {{feedbackSelected.length}} record{{ feedbackSelected.length > 1 ? 's' : ''}}? 
              </v-btn>
              <v-spacer v-else></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="feedbackSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table 
              v-model="feedbackSelected"
              :headers="feedbackHeaders" 
              :items="feedback" 
              :search="feedbackSearch"
              show-select 
              item-key="id" 
              :loading="feedbackLoading"
              loading-text="Loading feedback...please wait"
            >
           <!-- <template  v-slot:[`item.user`]="{ value }">
              <a style="text-decoration: none" :href="`mailto:${value.email}`">
                {{ value.name }}
              </a>
            </template>   -->
         
            <template  v-slot:[`item.attachment_url`]="{ value }">
              <v-icon v-if="value != ''" v-model="attachmentFile" @click="attachmentFileurl(value)"> mdi-image</v-icon> 
            </template>
            </v-data-table>
            <v-dialog v-model="attachmentFile" max-width="50%">
                <v-card class="fileAttachment">
                <!-- <img :src="this.attachmentFilename"/>-->
                <iframe :src="this.attachmentFilename" width="100%" height="100%" frameborder="0"></iframe>
                <v-icon @click="attachmentClose()" class="close-btn" >mdi-close</v-icon>
                </v-card>
            </v-dialog>
            <v-dialog v-model="feedbackDeleteDialog" max-width="50%">
              <v-card>
                <v-card-title class="justify-center">Are you sure you want to delete {{feedbackSelected.length}} record{{ feedbackSelected.length > 1 ? 's' : ''}}?</v-card-title> 
                <v-card-actions class="justify-center">
                  <v-btn color="red" @click="destroy('feedback')">Delete Feedback Record{{feedbackSelected.length > 1 ? 's' : ''}}</v-btn>
                  <v-btn @click="feedbackDeleteDialog = false">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
          <v-flex v-if="tab === 2" >
            <v-card-title>
              <v-btn v-if="delegatesSelected.length" color="red" @click="delegateDeleteDialog = true"> 
                Delete {{delegatesSelected.length}} record{{ delegatesSelected.length > 1 ? 's' : ''}}? 
              </v-btn>
              <v-spacer v-else></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="delegateSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table 
              v-model="delegatesSelected"
              :headers="delegateHeaders" 
              :items="delegates" 
              :search="delegateSearch"
              show-select 
              item-key="id" 
              :loading="delegateLoading"
              loading-text="Loading delegate relationships...please wait"
            >
          <!--  <template v-slot:[`item.delegator`]="{ value, item }">
              {{ value.name }} ({{ value.id }}) {{ item.details ? `(Base Id: ${item.details.delegatorbaseId})` : ''}}
            </template>
            <template v-slot:[`item.delegatee`]="{ value, item }">
              {{ value.name }} ({{ value.id }}) {{ item.details ? `(Base Id: ${item.details.delegateebaseId})` : ''}}
            </template>-->
            </v-data-table>
            <v-dialog v-model="delegateDeleteDialog" max-width="50%">
              <v-card>
                <v-card-title class="justify-center">Are you sure you want to delete {{delegatesSelected.length}} record{{ delegatesSelected.length > 1 ? 's' : ''}}?</v-card-title> 
                <v-card-actions class="justify-center">
                  <v-btn color="red" @click="destroy('delegates')">Delete Delegate Record{{delegatesSelected.length > 1 ? 's' : ''}}</v-btn>
                  <v-btn @click="feedbackDeleteDialog = false">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-row>
      </v-col>
     </v-row>
  </div>
</template>

<script>
import moment from 'moment';
export default {

  data() {
    return {
      copied: null,
      delegateCards: [],
      delegateDeleteDialog: null,
      delegateHeaders: [
        { text: 'System', value: 'system' },
        { text: 'Delegator', value: 'delegator.name' },
        { text: 'Delegatee', value: 'delegatee.name' },
        { text: 'Start Time', value: 'startTime' },
        { text: 'End Time', value: 'endTime' },
        { text: 'Created At', value: 'createdAt'}
      ],
      delegateLoading: true,
      delegateSearch: '',
      delegatesSelected: [],
      errorCards: [],
      errorDeleteDialog: null,
      errorSearch: '',
      errorsLoading: true,
      errorsSelected: [],
      feedbackCards: [],
      feedbackDeleteDialog: null,
      feedbackHeaders: [
        { text: 'Submitted By', value: 'user.name' },
        { text: 'Feebacks', value: 'feedback_comment'},
        { text: 'Issues', value: 'issue_description' },
        { text: 'Onboarding', value: 'onboarding_request' },
        { text: 'Rating', value: 'rating' },
        { text: 'IsHelpfull', value: 'is_helpful' },
       // { text: 'Comment', value: 'comment' },
        { text: 'Attachement', value: 'attachment_url' },
        { text: 'Version', value: 'version' },
        { text: 'Created At', value: 'createdAt'}
      ],
      feedbackLoading: true,
      feedbackSearch: '',
      feedbackSelected: [],
      headers: [
        { text: 'Request ID', value: 'referenceId' },
        { text: 'Source System', value: 'system' },
        { text: 'Error Message', value: 'message' },
        { text: 'Source JSON', value: 'actions'},
        { text: 'Created At', value: 'createdAt' } 
      ],
      json: null,
      jsonDialog: null,
      malformed: null,
      skip: 0,
      tab: null,
      total: null,
      attachmentFile: false,
      attachmentFilename:'',
    }
  },
 

  async mounted () { 
    //this.readDataFromAPI();

   while (this.total === null || this.total > this.errorCards.length) {
      await this.$axios.get(`/errors?skip=${this.skip}`)
      .then(response => {
        this.total = response.data.total;
        const cards = response.data.errors.map(err => {
          err.createdAt = moment(err.createdAt).format('MMMM Do YYYY, h:mm:ss a');
          return err;
        })
        this.errorCards.push(...cards)
        this.skip += 500;
      })
      //console.log(this.total)
      .catch(() => {
        this.$router.push('/')
      });
       }
   
    this.errorsLoading = false;
    this.feedbackCards = await this.$axios.get('/feedback')
      .then(response => {
        return response.data.map(fb => {
          fb.createdAt = moment(fb.createdAt).format('MMMM Do YYYY, h:mm:ss a');
          return fb;
        })
      })
      .catch(() => {
        this.$router.push('/');
      });

      console.log(this.feedbackSearch);

    this.feedbackLoading = false;
    this.delegateCards = await this.$axios.get('/delegates')
      .then(response => {
        return response.data.delegates.map(del => {
          del.createdAt = moment(del.createdAt).format('MMMM Do YYYY, h:mm:ss a');
          del.startTime = moment(del.startTime).format('MMMM Do YYYY');
          del.endTime = moment(del.endTime).format('MMMM Do YYYY');
          return del;
        })
      })
      .catch(() => {
        this.$router.push('/');
      });
    this.delegateLoading = false;
    if (this.attachmentFile == false){
      this.attachmentFilename = '';
    }
  },
  computed: {
    feedback () {
      return this.feedbackCards;
    },
    feedbackNum () {
      if (this.feedbackLoading) {
        return '...'
      }
      return this.feedbackCards.length;
    },
    errors () {
      return this.errorCards;
    },
    errorNum () {
      if (this.errorsLoading) {
        return '...'
      }
      return this.errorCards.length;
    },
    delegates () {
      return this.delegateCards;
    },
    delegateNum () {
      if (this.delegateLoading) {
        return '...'
      }
      return this.delegateCards.length;
    }
  },

 methods: {
  
  /*async readDataFromAPI() {
      //const { page, itemsPerPage } = this.options;
      let totalLimit =  this.errors.length;
      console.log(this.errorCards.length);
      let pageNumber =  1;
     await this.$axios
        .get(
          "/errors?limit=" + totalLimit + "&page=" + pageNumber
        )
        .then((response) => {
          //Then injecting the result to datatable parameters.
          this.total = response.data.total;
          const cards = response.data.errors.map(err => {
          err.createdAt = moment(err.createdAt).format('MMMM Do YYYY, h:mm:ss a');
          return err;
        })
        this.errorCards.push(...cards)
       
        });
    },*/
    attachmentFileurl(value){
      this.attachmentFilename = value;
      this.attachmentFile= true;
    },
    attachmentClose(){
      this.attachmentFilename ='';
      this.attachmentFile= false;
    },
   async copy() {
     const json = this.malformed ? this.json : JSON.stringify(this.json, null, '\t')
     await navigator.clipboard.writeText(json)
     this.copied = true;
     setTimeout(() => this.copied = false, 1500)
   },
   async validate() {
     await this.copy();
     setTimeout(() => window.open("https://jsonlint.com/", "_blank"), 1000);

   },
   timestamp(time) {
     return moment(time).format('MMMM Do YYYY, h:mm:ss a');
   },
   async viewSource(item) {
     this.jsonDialog = true;
     this.json = await this.$axios.get(`errors/${item.id}?JSONonly=true`).then(res => res.data.sourceJSON);
     this.malformed = typeof this.json === 'string';

   },
   async destroy(route) {
     if (route === 'errors') {
        let ids = this.errorsSelected.map(err => err.id);
        await this.$axios.delete(`/${route}`, { data: {idArray: ids} })
          .then(() => {
            this.errorCards = this.errorCards.filter(card => !ids.includes(card.id));
            this.errorsSelected = [];
          })
          .catch(err => console.log(err));
        this.errorDeleteDialog = false;
     } else if (route === 'feedback') {
       let ids = this.feedbackSelected.map(fb => fb.id)
       await this.$axios.delete(`/${route}`, { data: {idArray: ids} })
        .then(() => {
          this.feedbackCards = this.feedbackCards.filter(card => !ids.includes(card.id)
          );
          this.feedbackSelected = [];
        })
        .catch(err => console.log(err));
        this.feedbackDeleteDialog = false;
     } else {
       let ids = this.delegatesSelected.map(del => del.id)
       await this.$axios.delete(`/${route}`, { data: {idArray: ids} })
        .then(() => {
          this.delegateCards = this.delegateCards.filter(card => !ids.includes(card.id));
          this.delegatesSelected = [];
        })
        .catch(err => console.log(err));
        this.delegateDeleteDialog = false;
     }
   }
 }
  
}
</script>

<style>
  .v-data-table {
    margin: 0px 15px !important;
}
.fileAttachment{
  padding: 20px 25px;
}
.fileAttachment .v-icon.close-btn{
  right: 10px;
    top: 5px;
    padding: 0 !important;
    line-height: 22px;
    font-size: 22px;
    height: 22px !important;
    width: 22px !important;
}
</style>
