
<template>
  <v-card max-width="100%" class="mt-5">
    <v-row no-gutters style="border-bottom:1px solid #ddd;" class=" aparsloading">
      <loading  :active.sync="isLoading">
</loading>
      <v-col cols="8" xs='12' sm='12' md="7" lg="8">
        <v-container max-height="100%">
            <v-layout row wrap>
              <Overlay :card="card" />
              <v-flex xs12 md4 lg4>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Request ID</h4>
                    <p class="apprInfo">{{card.header.referenceId.replace(/[^-]*-/,"")}}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md4 lg4>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Created</h4>
                    <p>{{ showDate(card.header.sourceCreatedAt) }}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md4 lg4>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Days Outstanding</h4>
                    <p>{{Math.round((new Date() - new Date(card.header.availableForApprovalAt))/1000/60/60/24)}}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md4 lg4>
                <v-container>
                  <div>
                    <h4 class="apprHeader">System/Approval Type</h4>
                    <p>{{ `${card.header.source} / ${card.header.type}`}}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md4 lg4>
                <v-container>
                  <div>
                    <h4 class="apprHeader">On Behalf of</h4>
                    <!--  <p>{{card.header.requestee.name ? card.header.requester.name : card.header.requester.coreId}}</p> -->
                    <p>{{card.header.requestee === undefined ? card.data.aparsDetails.requestee : card.header.requestee.name}}</p>
                  
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md4 lg4>
              <v-container v-if="card.header.amount">
                  <div>
                    <h4 class="apprHeader">Total Amount</h4>
                    <p><b>{{showCurrency(card.header.amount)}} {{card.header.amountCurrency}}</b></p>
                
                 
                  </div>
                </v-container>
              </v-flex>
          </v-layout>
        </v-container>
      </v-col>
      <v-col cols="12" sm='12' md="5" lg="4" align="center" justify="center">
          <v-container style="backgroundColor: rgba(0, 121, 153, 0.04)" fill-height>
             <v-col cols="12" sm="12" md="12"
                          class="flex-grow-0 flex-shrink-1"
                          justify="center" align="center">
                      <v-row  justify="center" align="center">
                            <v-btn
                              v-if="card.delegated"
                              color="primary"
                              class="custom-btn delegated-btn"
                              dark
                              large
                              text
                              v-bind="attrs"
                              v-on="on"
                            >
                              DELEGATED
                            </v-btn>
                      </v-row >
                    </v-col>
            <v-card-actions class="fixIeFormatting">
              <v-row align="center" justify="center">
                <!--<Review :card="card" @reviewed="review" /> -->

 <div v-if="card.hideApproval === false"> 
  <div cols="12" sm="12" class="text-center col-sm-12 col-md-12 col-lg-12" style="padding:0;">
    <v-col>
      <div v-if="card.data.aparsDetails.sponsorApprvovelFlag === 0 || card.data.aparsDetails.accessLevelApproverFlag > 0 ">
      <v-btn outlined rounded text style="margin-right: 5px; backgroundColor: white; fontSize: 13px" @click="card.commentDialog=true; card.reviewType='Approve'">
        <v-icon v-bind:style="{padding: '5px', color: 'green', fontSize:'20px'}">mdi-thumb-up</v-icon>
        <b>Approve</b>
      </v-btn>
      <v-btn outlined rounded text style="margin-left: 5px; backgroundColor: white; fontSize: 13px" @click="card.commentDialog=true; card.reviewType='Reject'">
        <v-icon v-bind:style="{padding: '5px', color: 'red', fontSize:'20px'}">mdi-thumb-down</v-icon>
        <b>Reject</b>
      </v-btn>
       </div>
      <div v-if="card.data.aparsDetails.sponsorApprvovelFlag > 0 && card.data.aparsDetails.accessLevelApproverFlag === 0 ">
      <v-btn outlined rounded text style="margin-right: 5px; backgroundColor: white; fontSize: 13px" @click="card.commentDialog=true; card.reviewType='Submit'">
        <v-icon v-bind:style="{padding: '5px', color: 'green', fontSize:'20px'}">mdi-thumb-up</v-icon>
        <b>Approve / Reject</b>
        <v-icon v-bind:style="{padding: '5px', color: 'red', fontSize:'20px'}">mdi-thumb-down</v-icon>
      </v-btn>
      </div>
    </v-col>
    <v-dialog :key="`${card.id}-${card.reviewType}`" v-model="card.commentDialog" max-width="700px">
      <v-card style="background-color: #E5E5E5; padding:10px" >
        <v-card-title class="justify-center">{{ card.reviewType }} Request {{ card.header.referenceId.replace(/[^-]*-/,"")}}?</v-card-title>
        
        <div v-if="card.data.aparsDetails.sponsorApprvovelFlag > 0 && card.data.aparsDetails.accessLevelApproverFlag === 0 ">        
            <v-simple-table fixed-header outline style="margin-top:10px">
              <template v-slot:default>
                <thead>
                  <tr>
                   <th class="text-left">Access Level</th>
                   <th class="text-left">Authorizer</th>
                   <th class="text-left">Owner</th>
                   <th class="text-left">Approve/Reject</th>
                  </tr>
                </thead>
                <tbody>
                   <tr v-for="(itemapars,index) in card.data.aparsDetails.LevelDetails" :key="index.id">
                      <td>{{itemapars.accessLevelName}}</td>
                      <td>{{itemapars.authorizerName}}</td>
                      <td>{{itemapars.ownerName}}</td>
                      <td>                      
                          <v-radio-group v-model="multilevel[index]" row :mandatory="true">
                          <v-radio v-for="btnLabel in items" :key="btnLabel.id"              
                          :label="`${btnLabel}`"
                          :value="`${btnLabel}`"
                          ></v-radio>             
                          </v-radio-group> 
                      </td>
                  </tr></tbody>
              </template>
            </v-simple-table>
        </div>
        <v-textarea style="margin: 10px 15px 0;" v-model="card.header.comments" placeholder="Please enter a comment about this request (optional)"></v-textarea>
        <v-card-actions class="justify-center" >
          <v-btn v-if="card.data.aparsDetails.sponsorApprvovelFlag === 0 || card.data.aparsDetails.accessLevelApproverFlag > 0"  color="{ card.requestType == 'Approve' ? primary : danger }" :disabled="card.header.source === 'fieldglass' && card.reviewType === 'Reject' && !card.rejectReason" v-on:click='review()'>{{ card.reviewType }}</v-btn>
          <v-btn v-if="card.data.aparsDetails.sponsorApprvovelFlag > 0 && card.data.aparsDetails.accessLevelApproverFlag === 0 " color="{ card.requestType == 'Approve' ? primary : danger}" v-on:click='reviewAccesslevel()'>{{ card.reviewType }}</v-btn> 
          <v-btn v-on:click='cancel()'>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
 </div>

                <v-col cols="12" sm="12" md="12"
                  style="min-width: 100px;"
                      class="flex-grow-0 flex-shrink-1"
                      justify="center" align="center">
                  <v-row  justify="center" align="center">
                    <v-col sm='12' md="5" lg="5">
                      <v-btn text @click="show = !show" style="color: #00607A">
                        Details
                        <v-icon>
                          {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                      </v-btn>
                    
                    </v-col>
                      <v-divider vertical class="vDivider"></v-divider>
                    <v-col
                      sm='12' md="5" lg="5"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn dark v-bind="attrs" v-on="on" text class="pa-2" style="color: #00607A" target="_blank" :href="card.header.sourceLink">
                            Go To App
                            <v-icon>
                              mdi-launch
                            </v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size:11px;">Pulse secure connection required</span>
                      </v-tooltip>
                    </v-col>
                  </v-row >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-container>
      </v-col>
    </v-row>

      <v-expand-transition>
        <div v-show="show" class="grey lighten-5">
        <v-container style="padding-bottom: 0; padding-top: 0;">
          <v-layout row wrap justify-end>            
            <v-flex shrink style="padding-right: 70px; padding-top: 5px">
              <v-btn icon @click="show = null">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
          <v-container
            id="scroll-target"
            style="max-height: 420px"
            class="overflow-y-auto"
          >
            <v-card outlined>
              
              <v-col cols=12>               
                <h3 class="content-title">Details</h3>
                  <AparsDetails v-if="card.data && card.data.aparsDetails" :cardData="card.data.aparsDetails"  :header="card.header"></AparsDetails>
                
              </v-col>
            </v-card>
          </v-container>  
        </div>
      </v-expand-transition>
    </v-card>
</template>

<script>
import AparsDetails from '../useraccessCard/AparsDetails';
import Overlay from '../viewComponents/Overlay';
import Loading from 'vue-loading-overlay';
//import Review from '../viewComponents/Review'

export default {
  props: ['card'],
  components: {
    Loading,
    AparsDetails,
    Overlay,
    //Review
  },
  data() {
   return  {
      show: null,
      items: ['approve', 'reject'],
      multilevel:[0,0],
      AccessLevelDetails:[],
      isLoading : true,
      fullPage: true,
      name:'',
    }
  },

  async mounted () {
    this.isLoading = true; 
    //const username = await this.$msal.getAllAccounts()[0].name; 
      const sponsorPending = this.card.data.aparsDetails.sponsorApprvovelFlag;
      const accesspending = this.card.data.aparsDetails.accessLevelApproverFlag;
      if(sponsorPending >0 && accesspending == 0){
        this.card.data.aparsDetails.LevelDetails = await this.card.data.aparsDetails.accessLevelDetails.filter(_=>_.authorizer== this.card.employeeId || _.owner == this.card.employeeId);
       
      }
      this.isLoading = false;
 },
 
  
  methods: {
    showCurrency(obj, category) {
        const amount = obj.header ? obj.header[category] : obj[category];
        if (!amount) return ""
        return (
          this.$store.state.currencyFormat === "comma"
          ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
          : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
        )
      },
      showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },


async review(){      
      const user = await this.$msal.getAllAccounts()[0];
      const remove = await this.$store.dispatch('review', this.card, user.username)
      if (remove) {
        this.$emit('reviewed', this.card);
      }      
    },
    async reviewAccesslevel(){ 
      let success;
      this.card.commentDialog = false;
      this.card.pendingOverlay = true; 
      //const reviewType = this.card.reviewType.toLowerCase();
      const cardName = this.card.header.source;
      
      if (cardName === "APARS"){
        var itemsApars = {};
          const accesslevel = this.card.data.aparsDetails.LevelDetails;
          const itemsAparstest = this.multilevel;
          let AccessLevelId = [];
          let AccessLevelDetails = [];
          let ApprovedFlag = [];
          const username = this.$msal.getAllAccounts()[0].name;
          const comment = this.card.header.comments;
          const ApproverId = username.employeeId;
          const accessLevelApprover = this.card.data.aparsDetails.sponsorApprvovelFlag;
          let Approvername;
          if (accessLevelApprover == 1){          
            Approvername = "Accesslevel";
          }
          for(var j=0; j< accesslevel.length; j++){
           itemsApars[accesslevel[j].accessLevelName]= itemsAparstest[j];
           if(itemsAparstest[j] == "approve"){
             accesslevel[j].authorizerFlag = 1;
           }
           else{
             accesslevel[j].authorizerFlag = 2;
           }
            ApprovedFlag[j] = accesslevel[j].authorizerFlag; 
            AccessLevelId[j] = accesslevel[j].id;
            AccessLevelDetails[j] = {'AccessLevelId' : AccessLevelId[j], 'ApprovedFlag': ApprovedFlag[j], 'ApproverId':ApproverId};
          }     
          const multiApproveId = this.card.header.referenceId;
          
      try {
        for(var i=0; i< accesslevel.length; i++){
          if(accesslevel[i].authorizerFlag == 1 || accesslevel.length > 1){
            await this.$axios.post(`/requests/${multiApproveId}/approve`, {'comment':comment, 'details':{'Approver':Approvername, AccessLevelDetails }});
          }
          else{
            await this.$axios.post(`/requests/${multiApproveId}/reject`, {'comment':comment, 'details':{'Approver':Approvername, AccessLevelDetails }});
          }
         this.card.pendingOverlay = false;
          if(accesslevel[i].authorizerFlag == 1 || accesslevel.length > 1){
            this.card.approvalOverlay = true;
          } else {
            this.card.rejectedOverlay = true;
          }
          await new Promise(resolve => {
            setTimeout(resolve, 2000);
          })
          this.card.approvalOverlay = true;
          if(accesslevel[i].authorizerFlag == 1 || accesslevel.length > 1){
            this.card.approvalOverlay = false;
          } else {
            this.card.rejectedOverlay = false;
          }
          success = true;
          this.$emit('reviewed', this.card);
        }
       } catch (err){
          this.card.pendingOverlay = false;
          this.card.failedOverlay = true;
          await this.$axios.post(`/errors`, { referenceId: this.card.id, system: this.card.header.source, action: this.card.reviewType}).catch(() => console.log('error failed to create'));
          await new Promise(resolve => {
            setTimeout(resolve, 7000);
          })
          this.card.failedOverlay = false;
        }
        this.card.header.comments = '';
        this.multilevel= [0,0];        
        return success;
      }
    },
    cancel() {
      this.card.commentDialog = false; 
      this.card.header.comments="";  
      this.multilevel= [0,0]; 
    }

  }
}
</script>
<style scoped>
.static{
   display: none;
 }
 .static.active{
   display: block;
 }
 tr.static.active{
   display: table-row;
 }
 .aparsloading .vld-overlay .vld-background{
  display: none !important;
 }
</style>

