import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const opts = {
  video: ["src", "poster"],
  source: "src",
  img: "src",
  image: ["xlink:href", "href"],
  use: ["xlink:href", "href"],
};


export default new Vuetify(opts);