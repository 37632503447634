import { render, staticRenderFns } from "./Paginations.vue?vue&type=template&id=57ac7356&scoped=true"
import script from "./Paginations.vue?vue&type=script&lang=js"
export * from "./Paginations.vue?vue&type=script&lang=js"
import style0 from "./Paginations.vue?vue&type=style&index=0&id=57ac7356&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ac7356",
  null
  
)

export default component.exports