import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router';
import { TextEncoder } from 'text-encoding';
import IdleVue from 'idle-vue';
import { msalInstance } from "vue-msal-browser"

Vue.config.productionTip = false;
axios.defaults.headers.common['Cache-Control'] = 'private, no-store';

// polyfill TextEncoder for IE Edge
if (typeof window.TextEncoder === 'undefined') {
  window.TextEncoder = TextEncoder;
}

Vue.use(Vuex)

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Cache-Control'] = 'private, no-store';
axios.interceptors.request.use(async config => {
  if (!await msalInstance.authenticateRedirect()) {
    await msalInstance.loginRedirect(), 2000;
  }
  const accessTokenRequest = {
    scopes: ["user.read"],
    prompt: "consent",
    account: msalInstance.getAllAccounts()[0],
  };    
  const entraaccessToken = await msalInstance.acquireTokenSilent(accessTokenRequest).then(tokenResponse => {
    let newToken = tokenResponse.accessToken;
      return newToken   
  })  
  config.headers.authorization = `Bearer ${entraaccessToken}`;
  return config;
}, error => {
  return Promise.reject(error);
});
axios.interceptors.response.use({}, async error => {
  if (/403/.test(error?.response?.status)) {
      await msalInstance.loginPopup(), 1500;
  }
  return Promise.reject(error);
})

Vue.use({
    install (Vue) {
    Vue.prototype.$axios = axios;
  }
})


const store = new Vuex.Store({
  state: {
    currencyFormat: null,
    dateFormat: null,
    isAdmin: null,
    procurementCount: null,
    timeCount: null,
    userAccessCount: null,
    travelCount: null,
    financeCount: null,
    assetsCount: null,
    totalCount: null,
    errorMessage: '',
    msalAccessToken:null
  },
  mutations: {
    setPreferences (state, payload) {
      state.currencyFormat = payload.currencyFormat;
      state.dateFormat = payload.dateFormat;
      state.isAdmin = payload.admin
    },
    setAccessToken (state, payload) {
      state.msalAccessToken = payload.accessToken;      
    },
    setCounts (state, payload) {
      state.procurementCount = payload.procurement;
      state.userAccessCount = payload.useraccess;
      state.financeCount = payload.finance;
      state.timeCount = payload.time;
      state.travelCount = payload.travel;
      state.assetsCount = payload.assets;
      state.totalCount = payload.total;
    },
    setError (state, payload) {
      state.errorMessage = payload.error;
    }
  },
  actions: {  
    async fetchPreferences({commit}) {
      const preferences = await this._vm.$axios.get(`/preferences`).catch(err => console.log('cannot get preferences: ', err.message));
      commit('setPreferences', preferences.data);
    },
    async updatePreferences({commit}, payload) {
      await this._vm.$axios.post(`/preferences`, payload.newPrefs);
      commit('setPreferences', payload.newPrefs); 
    },
    async fetchCounts({commit}) {
      const counts = await this._vm.$axios.get('/requests/count?where={"status":"pending"}')
      .catch((err) => {
          if (err.message.match(/400/)) {
            router.push({ path: "/nullUser" });
          }
        });     
        commit('setCounts', counts.data);
      },
      async AzureAuthentication({ commit, getters, dispatch }) {
        try {
          let existingTokenResponse = getters.mainTokenResponse;
          let newTokenResponse;
         
          // The user has already logged in. We try to get his token silently
          if (existingTokenResponse)
            newTokenResponse = await msalInstance.acquireTokenSilent({
              account: existingTokenResponse.account,
              scopes: msalInstance.extendedConfiguration.auth.scopes,
            });
          // The user has not logged in. We check if he comes back from a redirect with a token
          else newTokenResponse = await msalInstance.handleRedirectPromise();
            
          // if(newTokenResponse){
          //   commit('setAccessToken', newTokenResponse);
          // }
          // No token found, we redirect the user
          if (!newTokenResponse) {
            //await msalInstance.loginRedirect(msalInstance.loginRequest);
            return false;
          }
          // There is an existing token, we authentify the user
          else if (newTokenResponse) {
            // We add the access token as an authorization header for our Axios requests to our API
            this._vm.$axios.defaults.headers.common["AuthorizationMSAL"] = newTokenResponse.accessToken;
  
              commit('setAccessToken', newTokenResponse);
            if (msalInstance.extendedConfiguration.graph) {
              // The graph is set, we check if the user has already a picture in the local storage
              // if he does not we grab a token silently for our graph scope and call Microsoft graph to get the picture
              if (!localStorage.getItem("userPicture")) {
                let graphTokenResponse = await msalInstance.getSilentToken(
                  newTokenResponse.account,
                  msalInstance.extendedConfiguration.graph.scopes
                );
                let graphResponse = await msalInstance.callMSGraph(
                  msalInstance.extendedConfiguration.graph.url,
                  graphTokenResponse.accessToken
                );
                dispatch("AzureSetPicture", graphResponse);
              }
            }
            return true;
          }
        } catch (error) {
          console.error(error);
        }
      },
   
    async review({commit},card,approverId) {
        let success;
        card.commentDialog = false;
        card.pendingOverlay = true;
        const reviewType = card.reviewType.toLowerCase();
        const comment = card.header.comment ? card.header.comment : '';
        const aparsComment = card.header.comments;
        const rejectId = card.rejectReason;
        const cardName = card.header.source;
        const referenceId = card.header.referenceId;
        let details;
        let Approvername;
        
        if (cardName === "APARS"){
        const SponsorApprover = card.data.aparsDetails.sponsorApprvovelFlag;
          if (SponsorApprover === 0){
            Approvername = "Sponsor";
          }
          else{
            Approvername = "Processor";
          }
          const AccessLevel = [];
          details = {'AccessLevelDetails' :AccessLevel, 'Approver' :Approvername }
        }
        try {
          if (cardName === "APARS"){
            await this._vm.$axios.post(`/requests/${card.id}/${reviewType}`, { 'comment': aparsComment, details, approverId});
        }
        else if (cardName === 'replicon'){
          await this._vm.$axios.post(`/requests/${card.id}/${reviewType}`, { comment, referenceId , rejectId, approverId});
        }
        else if (cardName === "nspta"){
          const nsptaAccesstoken = sessionStorage.getItem("nsptatoken");
          let details;
          details = {'NSPTA_Access_Token': nsptaAccesstoken}
          await this._vm.$axios.post(`/requests/${card.id}/${reviewType}`, { comment, 'Source': cardName, 'Approver_Id': referenceId, details});
        }
          else{
            await this._vm.$axios.post(`/requests/${card.id}/${reviewType}`, { comment, rejectId, approverId});
          } 
         card.pendingOverlay = false;
       
          if (reviewType === 'approve') {
            card.approvalOverlay = true;
          } else {
             if(cardName === "concur" || reviewType === 'clarification'){
              card.successOverlay = true;
             }
             else{
                card.rejectedOverlay = true;
             }
          }
          await new Promise(resolve => {
            setTimeout(resolve, 2000);
          })
          card.approvalOverlay = true;
         
          if (reviewType === 'approve') {
            card.approvalOverlay = false;
          } else {
            if(cardName === "concur" || reviewType === 'clarification'){
              card.successOverlay = false;
             }
             else {
            card.rejectedOverlay = false;
             }
          }
          success = true;
        } catch (err){
          commit('setError', {error: err.response.data});
          card.reviewError = err.response.data;
          card.pendingOverlay = false;
          card.failedOverlay = true;
          this._vm.$axios.post(`/errors`, { referenceId: card.id, system: card.header.source, action: card.reviewType, error: JSON.stringify(err.response.data)}).catch(() => console.log('error failed to create'));
          await new Promise(resolve => {
            setTimeout(resolve, 7000);
          })
          card.failedOverlay = false;
          if(cardName === "concur"){
            window.location.reload();            
          }
        }
        card.header.comment = '';
        card.header.comments = '';
        card.rejectReason = '';
        return success;
    }
  }
})

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  msalInstance,
  events: ['mousedown', 'keydown'],
  idleTime: 5000,
  startAtIdle: false
})

new Vue({
  vuetify,
  router,
  store,
  msalInstance,
  render: h => h(App)
}).$mount('#app')