<template>
    <v-app >
        <Navbar/>
        <IsIdle v-if="isIdle" />
        <v-main  v-bind:style="{ backgroundColor: '#fff'}">
          <router-view></router-view>
        </v-main>
        <v-footer class="justify-center">
          <v-card-subtitle>
            Version: {{version}} 
          </v-card-subtitle>
          <!-- <v-spacer></v-spacer> -->
          <!-- <v-card-subtitle> 
            Build Number: {{buildNumber}} 
          </v-card-subtitle> -->
        </v-footer>
    </v-app>
</template>

<script>
import Navbar from '../src/components/viewComponents/Navbar';
import IsIdle from '../src/components/viewComponents/IsIdle';

export default {
  name: 'App',
  components: {
    Navbar,
    IsIdle,
    
  },
  data: () => ({
    version: process.env.VUE_APP_VERSION,
    buildNumber: "TBD",
    isActive: false,
   
  }),
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle
    },
  },
};
</script>
<style>
#app {
  font-family:Arial, Helvetica, sans-serif;
}
.v-application .v-sheet.v-footer {
    background-color: #ECEEF1;
}
.v-footer .v-card__subtitle{
      padding: 0;
}
.v-main.active{
  padding: 8.5% 0px 0px !important;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .fixIeFormatting {
        width: 100%
      }
}
</style>