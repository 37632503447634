<template>
  <v-row >
    <v-col sm="12">
      <v-container>
        <v-row>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Request Type</h4>
                <p class="apprInfo">{{cardData.requestType}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Profile</h4>
                <p>{{cardData.profile}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Initiation</h4>
                <p>{{cardData.initiation}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Priority</h4>
                <p>{{cardData.priority}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Category</h4>
                <p>{{cardData.category}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Business Justification</h4>
                <p>{{cardData.businessJustification}}</p>
              </div>
            </v-container>
          </v-flex>
        </v-row>
      </v-container>
      </v-col>
    </v-row>
</template>

<script>
export default {
  props: ['cardData', 'requestee'],
}
</script>