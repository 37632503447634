
<template>
      
          <v-container>
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                  <v-tabs class="girs-tab">
                    <v-tab v-if="cardData.Labour1.length !=0">Time Tracking Resources</v-tab>
                    <v-tab v-if="cardData.Labour2.length !=0">Non Time Tracking Resources</v-tab>
                    <v-tab v-if="cardData.toOther.length !=0">Travel & Expenses</v-tab>
                    <v-tab v-if="cardData.IWO_Summary">IWO Summary</v-tab>
                     
                        <v-tab-item v-if="cardData.Labour1.length !=0">
                          <v-simple-table fixed-header outline >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Employee ID</th>
                                  <th class="text-left">Master WBS</th>
                                  <th class="text-left">SOW</th>
                                  <th class="text-left">Start Date</th>
                                  <th class="text-left">End Date</th>
                                  <th class="text-left">Profit Center</th>
                                  <th class="text-left">Hours</th>
                                  <th class="text-left">Hourly Rate</th>
                                  <th class="text-left">Orig Manager</th>
                                  <th class="text-left">Perf Co Code</th>
                                  <th class="text-left">Fin Mgr Orig.side</th>
                                  <th class="text-left">Fin Mgr Perf.side</th>                                   
                                  <th class="text-left">Master WBS Co code</th>
                                  <th class="text-left">WBS Type</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="compassTable"  v-for="timeTracking in cardData.Labour1" :key="timeTracking.id">
                                  <td>{{timeTracking.employeeId}}</td>
                                   <td>{{timeTracking.masterWBS}}</td>
                                  <td>{{timeTracking.sowDescription}}</td>
                                  <td>{{showDate(timeTracking.startDate)}}</td>
                                  <td>{{showDate(timeTracking.endDate)}}</td>
                                  <td>{{timeTracking.profitCentre}}</td> 
                                  <td>{{timeTracking.hours}}</td>
                                  <td>{{timeTracking.hourlyRate}}</td>                                  
                                  <td>{{timeTracking.projManager}}</td>
                                  <td>{{timeTracking.perfCoCode}}</td>
                                  <td>{{timeTracking.originatingFinanceManager}}</td>
                                  <td>{{timeTracking.performingFinanceManager}}</td>
                                  <td>{{timeTracking.masterWBSCocode}}</td>
                                  <td>{{timeTracking.wbsType}}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-tab-item>
                        <v-tab-item v-if="cardData.Labour2.length !=0">
                          <v-simple-table fixed-header outline >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Resource Name</th>
                                  <th class="text-left">Resource co code</th>
                                  <th class="text-left">Master WBS</th>
                                  <th class="text-left">SOW</th>
                                  <th class="text-left">Start Date</th>
                                  <th class="text-left">End Date</th>
                                  <th class="text-left">Profit Centre</th>
                                  <th class="text-left">Hours</th>
                                  <th class="text-left">Hourly Rate</th>                                 
                                  <th class="text-left">Orig Manager</th>
                                  <th class="text-left">Fin Mgr Orig.side</th>
                                  <th class="text-left">Fin Mgr Perf.side</th>
                                  <th class="text-left">Master WBS Co code</th>
                                  <th class="text-left">WBS Type</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="compassTable"  v-for="nontimeTracking in cardData.Labour2" :key="nontimeTracking.id">
                                  <td>{{nontimeTracking.resourceName}}</td>
                                  <td>{{nontimeTracking.resourceCoCode}}</td>
                                  <td>{{nontimeTracking.masterWBS}}</td>
                                  <td>{{nontimeTracking.sowDescription}}</td>
                                  <td>{{showDate(nontimeTracking.startDate)}}</td>
                                  <td>{{showDate(nontimeTracking.endDate)}}</td>
                                  <td>{{nontimeTracking.profitCentre}}</td> 
                                  <td>{{nontimeTracking.hours}}</td>
                                  <td>{{nontimeTracking.hourlyRate}}</td>                                  
                                  <td>{{nontimeTracking.projManager}}</td>
                                  <td>{{nontimeTracking.originatingFinanceManager}}</td>
                                  <td>{{nontimeTracking.performingFinanceManager}}</td>
                                  <td>{{nontimeTracking.masterWBSCocode}}</td>
                                  <td>{{nontimeTracking.wbsType}}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-tab-item>
                        <v-tab-item v-if="cardData.toOther.length !=0">
                          <v-simple-table fixed-header outline >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Type of Costs</th>
                                  <th class="text-left">EXP Co Code</th>
                                  <th class="text-left">Master WBS</th>
                                  <th class="text-left">SOW</th>
                                  <th class="text-left">Start Date</th>
                                  <th class="text-left">End Date</th>
                                  <th class="text-left">Profit Center</th>
                                  <th class="text-left">Total Costs</th>                                
                                  <th class="text-left">Fin Mgr Orig.side</th>
                                  <th class="text-left">Fin Mgr Perf.side</th>
                                  <th class="text-left">Master WBS Co Code</th>
                                  <th class="text-left">WBS Type</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="compassTable"  v-for="travelExpenses in cardData.toOther" :key="travelExpenses.id">
                                  <td>{{travelExpenses.typeofCost}}</td>
                                  <td>{{travelExpenses.expCoCode}}</td>
                                  <td>{{travelExpenses.masterWBS}}</td>
                                  <td>{{travelExpenses.sowDescription}}</td>
                                  <td>{{showDate(travelExpenses.startDate)}}</td>
                                  <td>{{showDate(travelExpenses.endDate)}}</td>
                                  <td>{{travelExpenses.profitCentre}}</td> 
                                  <td>{{travelExpenses.totlaCost}}</td>                                
                                  <td>{{travelExpenses.originatingFinanceManager}}</td>
                                  <td>{{travelExpenses.performingFinanceManager}}</td>
                                  <td>{{travelExpenses.masterWBSCocode}}</td>
                                  <td>{{travelExpenses.wbsType}}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-tab-item>
                        <v-tab-item v-if="cardData.IWO_Summary">
                          <v-row>
                                        <v-flex xs12 md4 lg3>
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">Revision Reason</h4>
                                              <p class="apprInfo">{{cardData.IWO_Summary.revision}} </p>
                                            </div>
                                          </v-container>
                                        <v-container>
                                            <div>
                                              <h4 class="apprHeader">Total Labour Cost</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.totalLbrCst === 0 ? '0.00': cardData.IWO_Summary.totalLbrCst)}} </p>
                                            </div>
                                          </v-container>
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">OverHead(%)</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.overheadpercentage === 0 ? '0.00' : cardData.IWO_Summary.overheadpercentage)}} </p>
                                            </div>
                                          </v-container>
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">SubTotal(A)</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.subTotalA === 0 ? '0.00' : cardData.IWO_Summary.subTotalA)}} </p>
                                            </div>
                                          </v-container>

                                        </v-flex>
                                        <v-flex xs12 md4 lg6 :class="'SubtotalB'">
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">Travel</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.travel === 0 ? '0.00' : cardData.IWO_Summary.travel)}} </p>
                                            </div>
                                          </v-container>
                                      
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">Allocations</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.allocations=== 0 ? '0.00' : cardData.IWO_Summary.allocations)}} </p>
                                            </div>
                                          </v-container>
                                      
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">Fixed Assets</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.fixedAssets=== 0 ? '0.00' : cardData.IWO_Summary.fixedAssets)}} </p>
                                            </div>
                                          </v-container>
                                      
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">Property And Facilities</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.propertyAndFacilities=== 0 ? '0.00' : cardData.IWO_Summary.propertyAndFacilities)}} </p>
                                            </div>
                                          </v-container>
                                      
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">Other Miscellaneous</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.otherMiscellaneous=== 0 ? '0.00' : cardData.IWO_Summary.otherMiscellaneous)}} </p>
                                            </div>
                                          </v-container>
                                        
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">Volumetric Consumption Charges</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.volumetricConsumptionCharges=== 0 ? '0.00' : cardData.IWO_Summary.volumetricConsumptionCharges)}} </p>
                                            </div>
                                          </v-container>
                                      
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">Software Licenss Charges</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.softwareLicenssCharges=== 0 ? '0.00' : cardData.IWO_Summary.softwareLicenssCharges)}} </p>
                                            </div>
                                          </v-container>
                                      
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">Transfer Pricing Allocation</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.transferPricingAllocation=== 0 ? '0.00' : cardData.IWO_Summary.transferPricingAllocation)}} </p>
                                            </div>
                                          </v-container>
                                      
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">Global Function Allocation</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.globalFunctionAllocation=== 0 ? '0.00' : cardData.IWO_Summary.globalFunctionAllocation)}} </p>
                                            </div>
                                          </v-container>
                                      
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">SubTotal(B)</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.subtotalB=== 0 ? '0.00' : cardData.IWO_Summary.subtotalB)}} </p>
                                            </div>
                                          </v-container>
                                        </v-flex>
                                        <v-flex xs12 md4 lg3>
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">IWO Currency</h4>
                                              <p class="apprInfo">{{cardData.IWO_Summary.IWOCurrency}} </p>
                                            </div>
                                          </v-container>
                                      
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">Estimated Cost Without MarkUP</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.totalEstimatedCostWithoutMarkUP=== 0 ? '0.00' : cardData.IWO_Summary.totalEstimatedCostWithoutMarkUP)}} </p>
                                            </div>
                                          </v-container>
                                      
                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">Markup(%)</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.markuppercentage=== 0 ? '0.00' : cardData.IWO_Summary.markuppercentage)}} </p>
                                            </div>
                                          </v-container>

                                          <v-container>
                                            <div>
                                              <h4 class="apprHeader">Estimated Cost Including MarkUP</h4>
                                              <p class="apprInfo">{{showCurrency(cardData.IWO_Summary.totalEstimatedCostIncludingMarkUP=== 0 ? '0.00' : cardData.IWO_Summary.totalEstimatedCostIncludingMarkUP)}} </p>
                                            </div>
                                          </v-container>
                                        </v-flex>
                          </v-row>  
                        </v-tab-item>
                  </v-tabs> 
                  </v-col>
                  </v-row>
                
          </v-container>
   
</template>
<script>
export default {
  props: ['cardData', 'header'],
 methods: {
    showCurrency(amount) {
        if (!amount) return ""
        return (
          this.$store.state.currencyFormat === "comma"
          ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
          : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
        )
      },
        showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },
 }
 }  
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr.compassTable > td{
  text-align: center;
}
.SubtotalB{
  overflow: hidden;
}
.SubtotalB .container{
  float: left;
  width: 50%;
}
h4.iwosummary{
  font-size: 18px;
    border-bottom: 1px solid #444;
    padding: 0 0 5px;
    margin: 0 0 10px;
    text-transform: uppercase;
}
.v-application .v-card p.apprInfo{
  min-height: 20px;

}
.v-tabs.girs-tab .v-tab{
  text-transform: capitalize;
}
</style>