<template>
  <div>
    <Toolbar v-bind:title="'HR'" v-bind:imgSrc="require('../assets/HR_tab.png')"></Toolbar> 
  </div>
</template>

<script>
import Toolbar from '../components/viewComponents/toolbar';
export default {
  components: {
    Toolbar
  }
}
</script>