<template>
  <div>
    <v-overlay 
      opacity='.25'
      :absolute="absolute" 
      :value="card.pendingOverlay"
      >
        <v-alert
          color="gray"
          dark
          icon="mdi-autorenew"
          prominent
        >
          <strong>Submitting...</strong>
        </v-alert>
      </v-overlay>
      <v-overlay 
      opacity='.25'
      :absolute="absolute" 
      :value="card.successOverlay"
      >
        <v-alert
          color="green"
          dark
          icon="mdi-checkbox-marked-circle-outline"
          prominent
        >
          <strong>Submitted!</strong>
        </v-alert>
      </v-overlay>
      <v-overlay 
      opacity='.25'
      :absolute="absolute" 
      :value="card.deleteOverlay"
      >
        <v-alert
          color="green"
          dark
          icon="mdi-checkbox-marked-circle-outline"
          prominent
        >
          <strong>Removed!</strong>
        </v-alert>
      </v-overlay>
      <v-overlay 
      opacity='.25'
      :absolute="absolute" 
      :value="card.approvalOverlay"
      >
        <v-alert
          color="green"
          dark
          icon="mdi-checkbox-marked-circle-outline"
          prominent
        >
          <strong>Approved!</strong>
        </v-alert>
      </v-overlay>
      <v-overlay 
      opacity='.25'
      :absolute="absolute" 
      :value="card.rejectedOverlay"
      >
        <v-alert
          color="red"
          dark
          icon="mdi-checkbox-marked-circle-outline"
          prominent
        >
          <strong>Rejected!</strong>
        </v-alert>
      </v-overlay>
      <v-overlay 
      opacity='.25'
      :absolute="absolute" 
      :value="card.failedOverlay"
      >
        <v-alert
          color="orange"
          dark
          icon="mdi-cloud-alert"
          prominent
        >
          <strong>{{ message }}</strong>
        </v-alert>
      </v-overlay>
  </div>
</template>

<script>
export default {
  props: ['card'],
  data() {
    return {
      absolute: true
    }
  },
  computed: {
    message() {
      if (this.card.header && ['fieldglass', 'ezbuy'].includes(this.card.header.source) ) {
        const err = this.$store.state.errorMessage;
        return `Sorry, your request could not be completed because of the following error: "${err.description ? err.description : JSON.stringify(err)}"`
      } 
      else if (this.card.header && ['uam'].includes(this.card.header.source) ) {
        const err = this.$store.state.errorMessage;
        return `Sorry, your request could not be completed because of the following error: "${err.Message ? err.Message : JSON.stringify(err)}"`
      }
      else if (this.card.header && ['expense Reports'].includes(this.card.header.type)) {
        const err = this.$store.state.errorMessage;
        return `${err.Message ? err.Message : JSON.stringify(err)}`
      }
       else if (this.card.header){
        return 'Sorry, Server Error. Please Click Go To App to view this record in the source system'
      }
      else {
        return 'Sorry, Server Error. Please try again later'
      }
    }
  }
}
</script>
<style scoped>
.theme--dark.v-sheet.gray{
      background-color: #5f249f!important;
    border-color: #5f249f!important;
}
</style>