<template>
  <div>
    <Toolbar v-bind:title="'FAQ'"  v-bind:imgSrc="require('../assets/Finance_tab.png')"></Toolbar>
    <v-container>
      <v-col md="12">
        <v-row justify="center">
          <v-expansion-panels class="faqblock">
            <v-expansion-panel>
              <v-expansion-panel-header>
                What is Approval Central?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                It is a DXC IT-managed application that centralizes multiple approvals in one location. It was created to simplify the approval process for colleagues who manage people, as well as time, finance, travel and expense requests.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                What applications are synced to Approval Central?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                <li>	Automated Physical Access Request System (APARS)</li>
                <li>  Compass billing documents</li>
                <li>	Concur expense reports and RTAs</li>
                <li>	DXC Time - time sheet and time off</li>
                <li>	ezBuy purchase requisitions</li>
                <li>	ezBuy vendor invoices (non-Purchase Orders)</li>
                <li>	Polaris Worldwide Authorization System (WWAS)</li>
                <li>	SailPoint</li>
                <!--li>	SmartBuy purchase requisitions</li-->
                <li>	SmartLabor II</li>
                <li>  UAM (User Access Management)</li>
                <li>	Virtual SM Security Request for Privilege Access (SRPA)</li>
              </ul>
              <p style="margin:10px 0">  <b>You can also review pending requests in:</b></p>
                <ul>
                  <li>	DXC DD</li>
                  <li>	ezBuy invoice reconciliation</li>
              </ul>

              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
               How do I access Approval Central?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Login to <a href="/" target="_blank">Approval Central</a> with your DXC credentials. Any submitted requests (from synced applications) that you are responsible for reviewing will appear in the individual tiles. 
              <p style="text-align:center;margin:10px 0"><img src="../assets/tiles.png" width="500px"/></p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                How do I complete approvals/rejections?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>Follow the instructions available in <a target="_blank" href="https://csc.service-now.com/sp?id=kb_article_view&sys_kb_id=be9f10411b60b4945ae421f3b24bcb0c&glide_sso_id=7b5e13254fb5d600a198a4eab110c723">this Approval Central article</a>. If you are based in the Americas, use UPtime for any IT resources and support, so refer to <a target="_blank" href="https://uptime.dxc.com/knowledges/detail/KB0113989">this article about Approval Central</a>. The process is simple, if you see a request to resolve in one of the tiles, click it and follow the prompts to <img src="../assets/approve.png" style="vertical-align: middle;" width="100px"/> or <img src="../assets/reject.png" style="vertical-align: middle;" width="100px"/>.</p> 
                <p>You can also include comments to further explain your decision to the requestor. You must include a comment for all request rejections. This data is refreshed hourly but can take up to two hours for application source requests to update. Which means don’t expect to see an approval for a Concur-related request immediately show up in that platform. It takes up to two hours for the data to sync.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          <v-expansion-panel>
              <v-expansion-panel-header>
                Will any actions I take in Approval Central immediately appear in the original source applications? 
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Yes, any actions taken in Approval Central immediately appear in the original approval application.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                In what order are requests listed? 
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                The worklist of requests pending approval are displayed and ordered oldest to newest.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                What information is displayed in Approval Central?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Each request contains information on who the request is for, date created and request number.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                What if I don’t see the  <img src="../assets/approve.png" style="vertical-align: middle;flex: none;" width="100px"/> or <img src="../assets/reject.png" style="vertical-align: middle;flex: none;" width="100px"/> option in a listed request? 
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                That means the approval/rejection feature is not available for that application. Instead click on  <img src="../assets/gotoapp.png" style="vertical-align: middle;" width="80px"/> 
                to return to the source application and complete your review there.  
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Is there a way to reveal a more detailed description of the request? 
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Yes, click on  <img src="../assets/details.png" style="vertical-align: middle;" width="80px"/> to reveal more about the request. Click <img src="../assets/details.png" style="vertical-align: middle;" width="80px"/>  again to close that window.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Where do I find attachments for specific requests? 
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Depending on the application, some require attachments along with the request for approval. Look for the paper clip icon  to review any attachments included with the request. You can also download the attachment.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                What happens to the request in Approval Central once I complete the review and select approve or reject?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Once approved or rejected, you will see a confirmation message indicating if the action was successful. The system will automatically remove the request from the worklist.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Can I request to add a new application to Approval Central? 
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Yes, Click the <b>Give Feedback</b> tab on the right side to report an issue, provide feedback or request addition of approvals from a new application to Approval Central. 
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                How do I provide feedback on Approval Central? 
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Click the <b>Give Feedback</b> tab on the right side and share your thoughts and ideas.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                How do I know if there are submitted approval requests? 
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                You can log in to see the requests on the tiles or you can use <a target="_blank" href="https://dxchub.dxc.com/">DXC Hub</a> on a regular basis for quick access to most of the frequently used DXC sites and applications. Look for notifications in the top right corner with the bell when there is a new request available for your review.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                I approved few requests in original application and I still see them in Approval Central. 
              </v-expansion-panel-header>
              <v-expansion-panel-content>
               <ul> 
                 <li>Should I act on them?
                    <p>No, it may take up to 1 hour for the status from the original application gets reflected in Approval Central. After 1 hour these requests will move out of your work list. If you still see these requests after 1hour, please select the request and click <img src="../assets/alreadyaction.png" style="vertical-align: middle;" width="100px"/> button to remove the request from work list</p>
                  <p style="text-align:center;margin:10px 0"><img src="../assets/alreadyactionpopup.png" style="vertical-align: middle;" width="700px"/></p>
                  </li>
                 <li>Will it create issues if I act on them again in Approval Central?
                    <p>No. If you act on these requests Approval Central will display an error message and no action is taken at the original application.</p></li>
              </ul> 
              </v-expansion-panel-content>
            </v-expansion-panel>
        
          </v-expansion-panels>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
  import Toolbar from '../components/viewComponents/toolbar';
  export default {
    components: {
      Toolbar
    }
  }
</script>
<style>
  .v-application--is-ltr .faqblock .v-expansion-panel-header{
    font-size: 16px;
    font-weight: bold;
  }
  .faqblock .v-expansion-panel-content__wrap{
    font-size: 14px;
  }
  </style>