<template>
  <v-card max-width="100%" class="mt-5">
    <v-row no-gutters>
      <v-col cols="8" xs='12' sm='12' md="7" lg="8">
        <v-container max-height="100%">
            <v-layout row wrap>
              <Overlay :card="card" />
              <v-flex xs12 md3 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Report ID</h4>
                    <p class="apprInfo">{{card.header.referenceId.replace(/[^-]*-/,"")}}</p>
                  </div>
                </v-container>
              </v-flex>
               <v-flex xs12 md3 lg3>
              <v-container >
                  <div>
                    <h4 class="apprHeader">Date Submitted</h4>
                    <p>{{showDate(card.header.sourceCreatedAt)}}</p>
                  </div>
                </v-container>
              </v-flex> 
               <v-flex xs12 md3 lg3>
              <v-container >
                  <div>
                    <h4 class="apprHeader">Request Name</h4>
                    <p>{{card.header.title}}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md3 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Requester Name</h4>
                    <p class="apprInfo">{{ card.data.concurDetails.employeeName }}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md3 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">System/Approval Type</h4>
                    <p>{{ `${card.header.source} / ${card.header.type}`}}</p>
                  </div>
                </v-container>
              </v-flex>
            
              <v-flex xs12 md2 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Reason for Trip</h4>
                    <p>{{card.data.concurDetails.reasonForTrip}}</p>
                  </div>
                </v-container>
              </v-flex>
               
              <v-flex xs12 md3 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Departure Date</h4>
                    <p>{{showDate(card.data.concurDetails.departureDate)}}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md3 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Return Date</h4>
                    <p> {{showDate(card.data.concurDetails.returnDate)}}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md3 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Origin City/Country</h4>
                    <p> {{card.data.concurDetails.originCityOrCountry}}</p>
                  </div>
                </v-container>
              </v-flex>
               <v-flex xs12 md3 lg3>
              <v-container >
                  <div>
                    <h4 class="apprHeader">Destination City/Country</h4>
                    <p>{{card.data.concurDetails.destinationCityOrCountry}}</p>
                  </div>
                </v-container>
              </v-flex>
             
              <v-flex xs12 md3 lg3>
              <v-container >
                  <div>
                    <h4 class="apprHeader">Requested Total</h4>
                    <p>{{showCurrency(card.data.concurDetails.requestTotal)}} {{card.data.concurDetails.amountCurrency}}</p>
                  </div>
                </v-container>
              </v-flex>
             
              <v-flex xs12 md3 lg3>
              <v-container >
                  <div>
                    <h4 class="apprHeader">Client</h4>
                    <p>{{card.data.concurDetails.client}}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md3 lg3>
              <v-container >
                  <div>
                    <h4 class="apprHeader">Salesforce ID</h4>
                    <p>{{card.data.concurDetails.salesforceId}}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md3 lg3>
              <v-container >
                  <div>
                    <h4 class="apprHeader">Business Purpose</h4>
                    <p>{{card.data.concurDetails.businessPurpose}}</p>
                  </div>
                </v-container>
              </v-flex>
               <v-flex xs12 md3 lg3>
              <v-container >
                  <div>
                    <h4 class="apprHeader">No. Of DXC Attendees</h4>
                    <p>{{card.data.concurDetails.noOfDXCEmployees}}</p>
                  </div>
                </v-container>
              </v-flex>
               <v-flex xs12 md3 lg3>
              <v-container >
                  <div>
                    <h4 class="apprHeader">No. Of External Attendees</h4>
                    <p>{{card.data.concurDetails.noOfExternalEmployees}}</p>
                  </div>
                </v-container>
              </v-flex>
               <v-flex xs12 md3 lg4>
              <v-container >
                  <div>
                    <h4 class="apprHeader">DXC Emp Attending Meeting</h4>
                    <p>{{card.data.concurDetails.employeesAttendingMeetng}}</p>
                  </div>
                </v-container>
              </v-flex>
             </v-layout>
        </v-container>
      </v-col>
      <v-col cols="12" sm='12' md="5" lg="4" align="center" justify="center">
          <v-container style="backgroundColor: rgba(0, 121, 153, 0.04)" fill-height>
             <v-col cols="12" sm="12" md="12"
                          class="flex-grow-0 flex-shrink-1"
                          justify="center" align="center">
                      <v-row  justify="center" align="center">
                            <v-btn
                              v-if="card.delegated"
                              color="primary"
                              class="custom-btn delegated-btn"
                              dark
                              large
                              text
                              v-bind="attrs"
                              v-on="on"
                            >
                              DELEGATED
                            </v-btn>
                      </v-row >
                    
            <v-card-actions class="fixIeFormatting">
              <v-row align="center" justify="center">
                <v-col cols="12" sm="12" md="12"
                  style="min-width: 100px;"
                      class="flex-grow-0 flex-shrink-1"
                      justify="center" align="center">
                  <v-row  justify="center" align="center">
                   <Review class="review-block" :card="card" @reviewed="review" />
                    <v-col sm='12' md="5" lg="5">
                      <v-btn text @click="show = !show" style="color: #00607A">
                        Details
                        <v-icon>
                          {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                      </v-btn>
                    
                    </v-col>
                      <v-divider vertical class="vDivider"></v-divider>
                    <v-col
                      sm='12' md="5" lg="5"
                    >
                        <v-btn dark text class="pa-2" style="color: #00607A" target="_blank" :href="card.header.sourceLink">
                          Go To App
                          <v-icon>
                            mdi-launch
                          </v-icon>
                        </v-btn>
                    </v-col>
                  
                  </v-row >
                </v-col>
              </v-row>
            </v-card-actions>
            </v-col>
          </v-container>
      </v-col>
    </v-row>

      <v-expand-transition>
        <div v-show="show" class="grey lighten-5">
          <v-divider horizontal></v-divider>
          <v-layout row wrap justify-end>
            
            <v-flex shrink style="padding-right: 30px; padding-top: 10px">
              <v-btn icon @click="show = null">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-container
            id="scroll-target"
            style="max-height: 420px"
            class="overflow-y-auto"
          >
            <v-card outlined class="mt-5">
              <v-col cols=12>
                 <h3 class="content-title">Details</h3>
                <v-row   
                  child-flex
                >
                   <ConcurtravelDetails v-if="card.data.concurDetails" :header="card.header" :cardData="card.data.concurDetails"></ConcurtravelDetails>
                </v-row>
              </v-col>
            </v-card>
          </v-container>  
        </div>
      </v-expand-transition>
    </v-card>
</template>

<script>
import ConcurtravelDetails from '../TravelCard/ConcurtravelDetails';
import Review from '../viewComponents/Review';
import Overlay from '../viewComponents/Overlay';

export default {
  props: ['card'],
  components: {
    ConcurtravelDetails,
    Review,
    Overlay,

  },
  data() {
   return  {
      show: null,
      receipt: '',
       dialog: false,
        showing: false,
        iframe:'',
        loaded: false
    }
  },


  methods: {
     async imageFile ()  {
       this.dialog = true;
      this.showing = true;
     },
    load: function(){
        this.loaded = true;
      },
      showCurrency(amount) {
      if (!amount) return ""
      return (
        this.$store.state.currencyFormat === "comma"
        ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
        : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
      )
    },
      showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },
      review(card){
        this.$emit('reviewed', card);
      },
  }
}
</script>
<style>
.v-application .v-card p{
  font-size: 13px;
}
 .v-icon.v-icon.close-btn{
    background: none !important;
    border: 0;
    position: absolute;
    right: 15px;
    top: 10px;
    transition: 1s ease-in-out;
    font-size: 18px;
  }
.v-icon.v-icon.close-btn:hover{
        transform: rotate(180deg);
    }
.review-block .theme--light.v-btn.v-btn--outlined.v-btn--text{
    letter-spacing: 0;
    padding: 0 10px;
}
@media screen and (max-width: 1919px){
  .v-dialog{
    background: #000;
    width: 800px!important;
  }
}  
@media (min-width:1920px) and (max-width:2400px){
  .v-dialog{
    width: 1200px!important;
   
  }
   .v-dialog iframe{
      height: 600px!important;
   } 
}   
</style>
