<template>
 <v-container>
   <div class="job-title">
   <p> Job Title : <span>{{jobtitle}}</span> </p>
  <p class="totalhr">Total - {{totalRequest}}<span>(hr)</span></p>
   </div>
  <v-simple-table fixed-header class="approval-table time-table">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Day</th>
          <th class="text-left">Cost Center</th>
          <th class="text-left static" v-bind:class="{active: isstActive}">Standard Time</th>
          <th class="text-left static" v-bind:class="{active: isActive}">Standard Time - Hours</th>
          <th class="text-left static" v-bind:class="{active: daysActive}">Standard Time - Days</th>
          <th class="text-left static" v-bind:class="{active: monthActive}">Standard Time - Month</th>          
          <th class="text-left static" v-bind:class="{active: ishalfActive}">Standard Time Half Days</th>
          <th class="text-left static" v-bind:class="{active: isfullActive}">Standard Time Full Days</th>
          <th class="text-left">Standard Time - Prorate Days</th>
          <th class="text-left">Over Time</th>
          <th class="text-left">Double Time</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="timedays in cardData.timeentries" :key="timedays.id">
          <td>{{showDate(timedays.timeentrydate)}} ({{showdays(new Date(timedays.timeentrydate).getUTCDay())}})</td>
          <td>{{timedays.costCenter}}</td>
          <td v-if="timedays['billablehours(st/hr)'] === '0.00' && timedays['billablehours(st/mo)'] === '0.00' && timedays['billablehours(st/halfday/day)'] === '0.00' && timedays['billablehours(st/fullday/day)'] === '0.00' && timedays['billablehours(st/day)'] === '0.00' ">0.00</td>
          <td v-if="timedays['billablehours(st/hr)'] != '0.00' ">{{timedays["billablehours(st/hr)"]}}</td>
          <td v-if="timedays['billablehours(st/day)'] != '0.00'">{{timedays["billablehours(st/day)"]}}</td>
          <td v-if="timedays['billablehours(st/mo)'] != '0.00'">{{timedays["billablehours(st/mo)"]}}</td>
          <td v-if="timedays['billablehours(st/halfday/day)'] != '0.00'">{{timedays["billablehours(st/halfday/day)"]}}</td>
          <td v-if="timedays['billablehours(st/fullday/day)'] != '0.00' "> {{timedays["billablehours(st/fullday/day)"]}}</td>
          <td>{{timedays["billablehours(dailyProRate/day)"]}} </td>
          <td>{{timedays["billablehours(ot/hr)"]}}</td>
          <td>{{timedays["billablehours(dt/hr)"]}}</td>         
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  </v-container> 
</template>

<script>
export default {
  props: ['cardData', 'jobtitle'],
 data: () => ({
      isActive: false,
      daysActive: false,
      monthActive: false,
      ishalfActive: false,
      isfullActive: false,
      isstActive:false,
  }),

   computed: {
    totalRequest: function () {
      let sum = 0;
      this.cardData.timeentries.forEach(function(item){
        sum += (parseFloat(item["billablehours(st/hr)"]) + parseFloat(item["billablehours(st/mo)"]) + parseFloat(item["billablehours(st/day)"]) + parseFloat(item["billablehours(st/halfday/day)"]) + parseFloat(item["billablehours(st/fullday/day)"])+ parseFloat(item["billablehours(ot/hr)"]) + parseFloat(item["billablehours(dt/hr)"]) + parseFloat(item["billablehours(dailyProRate/day)"]));
      });
      return sum
    }
  },
 async mounted (){
   const hrs= 'billablehours(st/hr)';
   const day= 'billablehours(st/day)';
   const month= 'billablehours(st/mo)';
   const halfday= 'billablehours(st/halfday/day)';
   const fullday= 'billablehours(st/fullday/day)';
   const test = this.cardData.timeentries;
   //test.forEach(function(item){
    for (var i =0; i<test.length; i++) { 
      if(test[i][hrs] != "0.00"){
       this.isActive = true;
      } 
      else if(test[i][day] != "0.00"){
        this.daysActive = true;
      } 
      else if(test[i][month] != "0.00"){
        this.monthActive = true;
      }
      else if(test[i][halfday] != "0.00"){
        this.ishalfActive = true;
      }
      else if(test[i][fullday] != "0.00"){
        this.isfullActive = true;
      }
      else if(test[i][hrs] == "0.00" && test[i][day] == "0.00" && test[i][month] == "0.00" && test[i][halfday] == "0.00" && test[i][fullday] == "0.00" ){
        this.isstActive = true;
      }
      else{
        this.isActive = false;
        this.daysActive = false;
        this.monthActive = false;
        this.ishalfActive = false;
        this.isfullActive = false;
        this.isstActive = false;
      }
    }
  },
 methods: { 
   showdays(day){
      var n;
        var weekday = new Array(7);
        weekday[0] = "Sun";
        weekday[1] = "Mon";
        weekday[2] = "Tue";
        weekday[3] = "Wed";
        weekday[4] = "Thu";
        weekday[5] = "Fri";
        weekday[6] = "Sat";
        n = weekday[day];
      return n;
   }, 
    showCurrency(amount) {        
        if (!amount) return ""
        return (
          this.$store.state.currencyFormat === "comma"
          ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
          : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
        )
      },
    showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },      
  }
}
</script>
<style scoped>
.time-table.theme--light.v-data-table tr td{
  text-align: center !important;
}
.job-title{
  overflow: hidden;
}
.job-title p{
  float: left;
  font-size: 16px;
  font-weight: bold;
  margin: 0 5px;
  padding: 0 0 5px;
  color:#000;
  text-transform: uppercase;
}
.job-title p span{
  font-weight:normal;
  text-transform:capitalize;
  font-size: 15px;
}
.job-title p.totalhr{
  text-align: right;
  float: right;
}
.static{
  display: none;
}
.static.active{
  display: block;
}
th.static.active, th.static.active-hrs, th.static.active-days, th.static.active-month{
  display: table-cell;
}
tr.static.active{
  display: table-row;
}
</style>