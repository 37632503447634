<template v-slot:default>
<v-container>
  <v-simple-table fixed-header class="approval-table">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Role</th>
          <th class="text-left">Approver(s)</th>
          <th class="text-left">Status </th>
          <th class="text-left">Comments</th>
          <th class="text-left">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in table.approvalFlow" :key="row.id">
          <td >{{row.reason}}</td>
          <td><p class="mt-2" v-for="approver in row.approvers" :key="approver.id">{{approver.name ? approver.name : approver.id}}</p></td>
          <td >{{row.status}}</td>
          <td >{{row.comments}}</td>
          <td >{{row.activationDate === null ? '' :  showDate(row.activationDate)}}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table> 
  </v-container>
</template>

<script>
export default {
  props: ['table'],
  async mounted(){
    const test = this.table.approvalFlow;
    test.forEach(function(item,i){
        if(item.reason === "Manager"){
          test.splice(i, 1);
          test.unshift(item);
        }
    });
    //console.log(test);
   },
  methods: {  
    showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      }
  }
  }
</script>

<style lang='scss' scoped>
.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
    margin-left: 15.5px;
    margin-right: 40px;
    max-width: 100%;
}
</style>