<template v-slot:default>
 <v-container>
  <v-simple-table fixed-header>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Approver(s)</th>
          <th class="text-left">Status</th>
          <th class="text-left">Required</th>
          <th class="text-left">Reason</th>          
          <th class="text-left">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in table" :key="row.id">
          <td>
            <p class="mt-2" v-for="approver in row.approvers" :key="approver.id">
              <span v-if="approver.id != 'private_user' ">{{approver.name ? approver.name : approver.id}}</span>
             <span v-else-if="approver.id === 'private_user' && row.groupName == null"> {{approver.name}} </span>
             <span v-else> {{ row.groupName }}</span>            
            </p>
          </td>
          <td >{{row.status}}</td>
          <td >{{row.approvalRequired == false ? 'No': 'Yes'}}</td>
          <td >{{row.reason}}</td>          
          <td >{{row.activationDate === null ? '' :  showDate(row.activationDate)}}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table> 
   </v-container>
</template>

<script>
export default {
  props: ['table'],
  methods: {
    showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      }
  }
  }
</script>

<style lang='scss' scoped>
.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
    margin-left: 15.5px;
    margin-right: 40px;
    max-width: 100%;
}
</style>