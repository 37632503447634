<template>
 <v-container>
  <v-simple-table fixed-header class="approval-table time-table">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Day</th>
          <th class="text-left">Type Name</th>
          <th class="text-left">WBS Code</th>
          <th class="text-left">WBS SO Name</th>
          <th class="text-left">Total Hours</th>
          <th class="text-left">Comments</th>
         
        </tr>
      </thead>
      <tbody>
        <tr v-for="timedays in cardData.timeentries" :key="timedays.id">
          <td>{{showDate(timedays.timeentrydate)}} ({{showdays(new Date(timedays.timeentrydate).getUTCDay())}})</td>
          <td>{{timedays.attendanceTypeName}}</td>
          <td>{{timedays.WBS_SO_Code}}</td>
          <td>{{timedays.WBS_SO_Name}}</td>
          <td>{{timedays.totalHours}}</td>
          <td>{{timedays.comments}}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  </v-container> 
</template>

<script>
export default {
  props: ['cardData'],

 
 methods: { 
   showdays(day){
      var n;
        var weekday = new Array(7);
        weekday[0] = "Sun";
        weekday[1] = "Mon";
        weekday[2] = "Tue";
        weekday[3] = "Wed";
        weekday[4] = "Thu";
        weekday[5] = "Fri";
        weekday[6] = "Sat";
        n = weekday[day];
      return n;
   }, 
    showCurrency(amount) {        
        if (!amount) return ""
        return (
          this.$store.state.currencyFormat === "comma"
          ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
          : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
        )
      },
    showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },      
  }
}
</script>
<style scoped>
.time-table.theme--light.v-data-table tr td{
  text-align: center !important;
}
.job-title{
  overflow: hidden;
}
.job-title p{
  float: left;
  font-size: 16px;
  font-weight: bold;
  margin: 0 5px;
  padding: 0 0 5px;
  color:#000;
  text-transform: uppercase;
}
.job-title p span{
  font-weight:normal;
  text-transform:capitalize;
  font-size: 15px;
}
.job-title p.totalhr{
  text-align: right;
  float: right;
}
.static{
  display: none;
}
.static.active{
  display: block;
}
th.static.active, th.static.active-hrs, th.static.active-days, th.static.active-month{
  display: table-cell;
}
tr.static.active{
  display: table-row;
}
</style>