<template>
<v-card max-width="100%" class="mt-5">
            <v-row no-gutters>
              <v-col cols="8" xs='12' sm='12' md="7" lg="8">
                <v-container max-height="100%">
                  <v-layout row wrap>
                    <Overlay :card="card" />
                    <v-flex xs12 md4 lg4>
                      <v-container >
                        <div>
                          <h4 class="apprHeader">Request ID</h4>
                          <p>{{card.header.referenceId.replace(/[^-]*-/,"")}}</p>
                        </div>
                      </v-container >
                    </v-flex>
                    <v-flex xs12 md4 lg4>
                          <v-container>
                          <div>
                            <h4 class="apprHeader">Created</h4>
                            <p>{{ showDate(card.header.sourceCreatedAt) }}</p>
                          </div>
                        </v-container >
                        </v-flex>
                         <v-flex xs12 md4 lg4>
                          <v-container >
                          <div>
                            <h4 class="apprHeader">Days Outstanding</h4>
                            <p>{{Math.round((new Date() - new Date(card.header.availableForApprovalAt))/1000/60/60/24)}}</p>
                          </div>
                        </v-container>
                        </v-flex>
                        <v-flex xs12 md4 lg4>
                          <v-container >
                          <div>
                            <h4 class="apprHeader">System/Approval Type</h4>
                            <p>{{ `${card.header.source} / ${card.header.type}`}}</p>
                          </div>
                        </v-container>
                        </v-flex>
                        <v-flex xs12 md4 lg4>
                         <v-container>
                          <div>
                            <h4 class="apprHeader">On Behalf of</h4>
                            <p>{{card.header.requester.name}}</p>
                          </div>
                        </v-container>
                        </v-flex>
                       
                 </v-layout>
               </v-container>
              </v-col>
              <v-col cols="12" sm='12' md="5" lg="4" align="center" justify="center">
              <v-container style="backgroundColor: rgba(0, 121, 153, 0.04)" fill-height>
                <v-card-actions class="fixIeFormatting">
                  <v-row align="center" justify="center">
                <v-col cols="12" sm="12" md="12"
                      style="min-width: 100px;"
                          class="flex-grow-0 flex-shrink-1"
                          justify="center" align="center">
                        <v-row  justify="center" align="center">
                          <Review :card="card"  @reviewed="review" />                         
                          <v-col sm='12' md="5" lg="5">
                            <v-btn text @click="show = !show" style="color: #00607A">
                            Details
                            <v-icon>
                              {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-btn>
                         
                          </v-col>
                          <v-divider vertical class="vDivider"></v-divider>
                          <v-col
                          sm='12' md="5" lg="5"
                        >
                        <v-btn text class="pa-2" style="color: #00607A" target="_blank" :href="card.header.sourceLink">
                            Go To App
                            <v-icon>
                              mdi-launch
                            </v-icon>
                          </v-btn>
                        <attachmentModal v-if="card.data.attachments" class="pt-1" :attachments="card.data.attachments" :cardHeader="card.header" ></attachmentModal>
                          </v-col>
                        </v-row >
                        
                    </v-col>
                    </v-row>
                </v-card-actions>
              </v-container>
              </v-col>
            </v-row>
          <v-expand-transition>
            <div v-show="show" class="grey lighten-5">
              <v-layout row wrap justify-end>
                  <v-flex shrink style="padding-right: 70px; padding-top: 10px">
                   <v-btn icon @click="show = null">
                      <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-container
                  id="scroll-target"
                  style="max-height: 420px"
                  class="overflow-y-auto"
                >
                <v-card class="">                   
                  <v-col cols=12 >
                    <v-row child-flex>
                      <v-container>
                          <h3 class="content-title">Details</h3>
                          <v-row>
                            <v-flex xs12 md4 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Currency</h4>
                                  <p class="apprInfo">{{card.data.polariswwasDetails.localCurrency}}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md4 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">General Limit</h4>
                                  <p class="apprInfo">{{showCurrency(card.data.polariswwasDetails.generalLimit)}}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md4 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Capital Limit</h4>
                                  <p class="apprInfo">{{showCurrency(card.data.polariswwasDetails.capitalLimit)}}</p>
                                </div>
                              </v-container>
                            </v-flex> 
                             <v-flex xs12 md4 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Reason For Request</h4>
                                  <p class="apprInfo">{{card.data.polariswwasDetails.comment}}</p>
                                </div>
                              </v-container>
                            </v-flex>      
                             <v-flex xs12 md4 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Location Code</h4>
                                  <p class="apprInfo">{{card.data.polariswwasDetails.AuthLocCode}}</p>
                                </div>
                              </v-container>
                            </v-flex>      
                             <v-flex xs12 md4 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Requestor Contact</h4>
                                  <p class="apprInfo">{{card.data.polariswwasDetails.AuthPhone}}</p>
                                </div>
                              </v-container>
                            </v-flex>         
                          </v-row>
                      </v-container>
                     <v-col cols=12 >
                      <v-row>
                            <v-container >
                              <h3 class="content-title">Business Case</h3>
                              <v-simple-table fixed-header outline>
                                <template v-slot:default v-if="isTruthy(card.data.polariswwasDetails.businessCase)" >
                                  <thead>
                                    <tr>
                                      <th class="text-left">Business Case Name</th>
                                      <th class="text-left">Business Case Limit</th>
                                    </tr>
                                  </thead>
                                      <tbody>
                                  <tr v-for="businessCase in card.data.polariswwasDetails.businessCase" :key="businessCase.businessCaseName">
                                    <td> {{ businessCase.businessCaseName }} </td>
                                    <td> {{ businessCase.businessCaseLimit }} </td>
                                  </tr>
                                  </tbody>
                                </template>
                                 <template v-slot:default v-else  >
                                    <thead ><tr><td>Business Case not selected</td></tr></thead>
                                </template>
                              </v-simple-table>
                            </v-container>
                       </v-row>
                     </v-col>
                     </v-row>
                  </v-col>
                </v-card>
              </v-container>
            </div>
          </v-expand-transition>
          </v-card>
</template>

<script>
import Overlay from '../viewComponents/Overlay';
import Review from '../viewComponents/Review';
import attachmentModal from '../viewComponents/attachmentModal';

export default {
  components: {
    attachmentModal,
    Overlay,
    Review
  },
  props: ['card'],
  data() {
    return {
      show: null,
      absolute: true,
      selectedIndex: null,
    }
  },
  methods: {
    showCurrency(amount) {
        if (!amount) return ""
        return (
          this.$store.state.currencyFormat === "comma"
          ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
          : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
        )
      },
      showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },
       isTruthy (businessCase) {
        return !!businessCase[0].businessCaseName && !!businessCase[0].businessCaseLimit
      },
      review(card){
        this.$emit('reviewed', card);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .custom-btn::before {
    color: transparent
  }
  .custom-btn:hover {
      color: white;
      cursor: default;
  }
  .detailsTitle {
    margin-left: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin-top: 10px;
  }
.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
    margin-left: 15.5px;
    margin-right: 40px;
    max-width: 100%;
} 
.theme--light.v-btn.v-btn--outlined{
    border-width: 2px;
    border-color: black;
}
</style>