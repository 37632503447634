
<template>
  <v-container>
    <v-row no-gutters>
     
            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">PO Number</h4>
                                  <p>{{ cardData.WaiverType.PONumber }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Total Value USD</h4>
                                  <p>{{ cardData.WaiverType.TotalValueUSD }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Total Value LocalDoc Currency</h4>
                                  <p>{{ cardData.WaiverType.TotalValueLocalDocCurrency }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Invoice Number</h4>
                                  <p>{{ cardData.WaiverType.InvoiceNumber }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">SAP Document Number</h4>
                                  <p>{{ cardData.WaiverType.SAPDocumentNumber }}</p>
                                </div>
                              </v-container>
                            </v-flex>                            
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Waiver Country</h4>
                                  <p>{{ cardData.WaiverType.WaiverCountry }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Company Code</h4>
                                  <p>{{ cardData.WaiverType.CompanyCode }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                          
      <!--  <v-tabs class="girs-tab nspta">
          <v-tab v-if="cardData.WaiverType">Waiver Type</v-tab>
          <v-tab-item v-if="cardData.WaiverType">
                            <v-row no-gutters>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Waiver Type</h4>
                                  <p>{{ cardData.WaiverType.waiverType }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Current Payment ID</h4>
                                  <p>{{ cardData.WaiverType.currentPaymentTermsID }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Requested Payment ID</h4>
                                  <p>{{ cardData.WaiverType.requestedPaymentTermsID }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">PO Number</h4>
                                  <p>{{ cardData.WaiverType.PONumber }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Invoice Posted</h4>
                                  <p>{{ cardData.WaiverType.IsInvoicePosted }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Total Value USD</h4>
                                  <p>{{ cardData.WaiverType.TotalValueUSD }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Total Value LocalDoc Currency</h4>
                                  <p>{{ cardData.WaiverType.TotalValueLocalDocCurrency }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Invoice Number</h4>
                                  <p>{{ cardData.WaiverType.InvoiceNumber }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">SAP Document Number</h4>
                                  <p>{{ cardData.WaiverType.SAPDocumentNumber }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Invoice Due Next Quarter</h4>
                                  <p>{{ cardData.WaiverType.InvoiceDueNextQuarter }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Invoice Has To Be Paid</h4>
                                  <p>{{ cardData.WaiverType.InvoiceHasToBePaid }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Waiver Country</h4>
                                  <p>{{ cardData.WaiverType.WaiverCountry }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Company Code</h4>
                                  <p>{{ cardData.WaiverType.CompanyCode }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Current Due Date</h4>
                                  <p>{{ showDate(cardData.WaiverType.CurrentDueDate) }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md3 lg3>
                              <v-container>
                                <div>
                                  <h4 class="apprHeader">Requested Due Date</h4>
                                  <p>{{ showDate(cardData.WaiverType.RequestedDueDate) }}</p>
                                </div>
                              </v-container>
                            </v-flex>
                          </v-row>
          </v-tab-item>
        </v-tabs> -->
    
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: ['cardData', 'header'],
 methods: {
    showCurrency(amount) {
        if (!amount) return ""
        return (
          this.$store.state.currencyFormat === "comma"
          ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
          : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
        )
      },
        showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },
 }
 }  
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr.compassTable > td{
  text-align: center;
}
.SubtotalB{
  overflow: hidden;
}
.SubtotalB .container{
  float: left;
  width: 50%;
}
h4.iwosummary{
  font-size: 18px;
    border-bottom: 1px solid #444;
    padding: 0 0 5px;
    margin: 0 0 10px;
    text-transform: uppercase;
}
.v-application .v-card p.apprInfo{
  min-height: 20px;

}
.v-tabs.girs-tab .v-tab{
  text-transform: capitalize;
}
.v-application .nspta h4 {
    font-size: 14px;
}
</style>