<template>
  <div class="team">
    <loading :active.sync="isLoading" 
      :is-full-page="fullPage">
    </loading>
    <v-text-field clearable @click:append="search" @keyup.enter="search" prepend-icon="mdi-text-search" append-icon="mdi-send" v-model="searchCriteria" placeholder="Search"></v-text-field>
    <v-dialog  min-height="300px" v-model="searchModal" >
      <v-card  style="background-color: #E5E5E5;" min-height="60vh">
        <v-col class="text-right">
          <v-btn @click="close">X</v-btn>
        </v-col>
        <v-card-title class="justify-center">Records matching: {{ searchCriteria }}</v-card-title>
        <v-col class="text-center" v-if="searchLoading">
          <h3>Loading Records</h3>
          <v-progress-circular  indeterminate  size="150" color="blue" style="margin: 20px;"/>
        </v-col>
        <v-card-text>
          <div class="searchGlobal" v-for="record in matchingRecords" :key="record.id">
            <SailpointRecord  v-if="record.header.source === 'sailpoint'" :card="record" @reviewed="removeCard"/>
            <UamRecord  v-if="record.header.source === 'uam'" :card="record" @reviewed="removeCard"/>
            <InvoiceRecord  v-if="record.header.type === 'invoices'" :card="record" @reviewed="removeCard" />
            <RequisitionRecord  v-if="record.header.type === 'requisitions'" :card="record" @reviewed="removeCard" />
            <FieldGlassRecord  v-if="record.header.type === 'workOrder'" :card="record" @reviewed="removeCard" />
            <APARSRecord  v-if="record.header.source === 'APARS'" :card="record" @reviewed="removeCard" />
            <VirtualSMRecord  v-if="record.header.source === 'VirtualSM' || record.header.source === 'VirtualSM_LSZ'" :card="record" @reviewed="removeCard" />
            <FieldGlassTimeRecord v-if="record.header.source === 'fieldglass' && record.header.type === 'timesheet' " :card="record" @reviewed="removeCard" />
            <RepliconTimesheet v-if="record.header.source === 'replicon'  && record.header.type === 'timesheet' " :card="record" @reviewed="removeCard" />
            <JobPostingRecord  v-if="record.header.type === 'jobPosting'" :card="record" @reviewed="removeCard" />
            <DxcDD  v-if="record.header.source === 'DXCDD'" :card="record" @reviewed="removeCard"/>
            <Compass  v-if="record.header.source === 'compass'" :card="record" @reviewed="removeCard"/>
            <Concur  v-if="record.header.source === 'concur'" :card="record" @reviewed="removeCard"/>
            <Girs  v-if="record.header.source === 'GIRS'" :card="record" @reviewed="removeCard"/>
            <ServiceNow  v-if="record.header.source === 'UPtime'" :card="record" @reviewed="removeCard"/>
            <LicenseManager v-if="record.header.source === 'LicenseManager'" :card="record" @reviewed="removeCard"/>
            <Nspta v-if="record.header.source === 'nspta'" :card="record" @reviewed="removeCard"/>
          </div>
          <h3 style="text-align: center; margin-top: 10vh;" v-if="!searchLoading && noRecords"><em>No Matching Records Were Found</em></h3>
        </v-card-text>               
      </v-card>
    </v-dialog>
    <v-container class="">
      <v-row
      justify="space-between"
    >
      <v-col md="4">
       <!-- <h1>Hi, {{userName}}</h1> -->
        <p>You have<b> {{ totalCount }} </b>outstanding request(s).</p>
      </v-col>
      <v-col md="4" class="data-refrenshes">
        <p>Please note data refreshes every hour.</p>
      </v-col>
    </v-row>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm6 md3 lg2 tile-block v-for="card in row" :key="card.name">
          <v-hover v-slot:default="{ hover }">
              <v-card 
              class="text-center ma-5" 
              :color="hover ? card.color1 : card.color2"
              :elevation="hover ? 12 : 4"
              :class="{ 'on-hover': hover }"
              width="100%"
              router :to="card.route"
              >
              <v-responsive >
                <v-list-item  :style="{backgroundColor: hover ? card.color2: card.color1 }" align-center justify-center three-line>
                  <v-list-item-content>
                    <v-list-item-avatar
                      tile
                      size="100"
                      :color="hover ? card.color1 : card.color2"
                    ><v-img contain :src="hover ? card.image1: card.image2" v-bind:style="{backgroundColor: hover ? card.color2: card.color1 }" height='100%' aspect-ratio="1"></v-img></v-list-item-avatar>
                    <v-list-item-title v-bind:style="{color: hover ? card.color1: card.color2, fontSize: '32px'}" class="headline mb-1">
                      {{card.name}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-responsive>
              <div class="sub">
                <v-card-text v-bind:style="{color: hover ? 'white': 'black', fontSize: '36px'}">{{ isNaN(card.amount) === true ? card.amount = '0' : card.amount}} <span v-bind:style="{fontSize: '16px'}">to resolve</span></v-card-text>
              </div>
              </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import SailpointRecord from '../components/searchRecord/SailpointRecord';
  import InvoiceRecord from '../components/searchRecord/InvoiceRecord';
  import RequisitionRecord from '../components/searchRecord/RequisitionRecord';
  import UamRecord from '../components/searchRecord/UamRecord';
  import FieldGlassRecord from '../components/searchRecord/FieldGlassWorkOrder';
  import FieldGlassTimeRecord from '../components/searchRecord/FieldGlassTimeRecord';
  import RepliconTimesheet from '../components/searchRecord/RepliconTimesheet';
  import VirtualSMRecord from '../components/searchRecord/VirtualSMRecord';
  import APARSRecord from '../components/searchRecord/AparsRecord';
  import JobPostingRecord from '../components/searchRecord/JobPostingRecord';
  import Compass from '../components/searchRecord/Compass';
  import DxcDD from '../components/searchRecord/DxcDD';
  import Concur from '../components/searchRecord/Concur';
  import Girs from '../components/searchRecord/Girs';
  import ServiceNow from '../components/searchRecord/ServiceNow';
  import LicenseManager from '../components/searchRecord/LicenseManager';
  import Nspta from '../components/searchRecord/Nspta';
  
export default {
  components: {
      Loading,
      SailpointRecord,
      InvoiceRecord,
      RequisitionRecord,
      FieldGlassRecord,
      UamRecord,
      VirtualSMRecord,
      APARSRecord,
      FieldGlassTimeRecord,
      RepliconTimesheet,
      JobPostingRecord,
      Compass,
      DxcDD,
      Concur,
      Girs,
      ServiceNow,
      LicenseManager,
      Nspta
    },
    data() {
      return {
        absolute: true,
        isLoading: false,
        fullPage: true,
        userName: null,
        searchCriteria: null,
        searchModal: null,
        matchingRecords: null,
        searchLoading: null,
        noRecords: null,
        cardOverlay: {successOverlay:false,deleteOverlay:false,approvalOverlay: false, rejectedOverlay: false, pendingOverlay: false, failedOverlay: false},
        row: [
          { name: 'Finance', amount: '0', role: ' ',image1: require('../assets/Finance_Icon_Color-new.png'), image2:require('../assets/Finance_Icon_White.png') ,color1:'#6CC24A',color2:'#FFFFFF', route: '/finance'},
          { name: 'Procurement', amount: ' ', role: ' ' , image1: require('../assets/Procurement_Icon_Color.png'), image2:require('../assets/Procurement_Icon_White.png') ,color1:'#5F249F',color2:'#FFFFFF', route: '/procurement'},
          { name: 'Time', amount: '0', role: ' ',image1: require('../assets/timer.png'), image2:require('../assets/timer-white.png') ,color1:'#ED9B33',color2:'#FFFFFF', route: '/time'},
          { name: 'DXC IT', amount: ' ', role: ' ',image1: require('../assets/dxcit-green.png'), image2:require('../assets/dxcit-white.png') ,color1:'#00968F',color2:'#FFFFFF', route: '/dxcit'},
          { name: 'Travel & Expense', amount: '0', role: ' ' ,image1: require('../assets/Travel_Icon_Color-new.png'), image2:require('../assets/Travel_Icon_White.png') ,color1:'#0088C9',color2:'#FFFFFF', route: '/travel'},
          /*{ name: 'HR', amount: '0', role: ' ', image1: require('../assets/HR_Icon_Color.png'), image2:require('../assets/HR_Icon_White.png') ,color1:'#F38F20',color2:'#FFFFFF', route: '/hr'}*/
        ]
      }
    },
  mounted () {
    this.isLoading = true
    this.updateUserName();
  },
  updated () {
    this.$nextTick(() => {
      if (Number.isInteger(this.procurementCount) && Number.isInteger(this.userAccessCount)) {
        this.row[0].amount = this.financeCount;
        this.row[1].amount = this.procurementCount;
        this.row[2].amount = this.timeCount;
        this.row[3].amount = this.userAccessCount;
        this.row[4].amount = this.travelCount;        
        //this.row[5].amount = this.assetsCount;
        this.isLoading = false;
      }
    });
    

  },
  computed: {
    financeCount () {
        return this.$store.state.financeCount;
    }, 
    procurementCount () {
        return this.$store.state.procurementCount;
    },
    timeCount () {
        return this.$store.state.timeCount;
    }, 
    userAccessCount () {
        return this.$store.state.userAccessCount;
    }, 
    travelCount () {
        return this.$store.state.travelCount;
    }, 
   /* assetsCount () {
        return this.$store.state.assetsCount;
    },*/
    totalCount () {
      return this.$store.state.totalCount;
    } 
  },
  methods : {
   async updateUserName () {
     const user = this.$msal.getAllAccounts()[0];
     this.userName = `${user.name}` 
    },
    close() {
      this.searchCriteria = null;
      this.matchingRecords = null;
      this.searchModal = null;
      this.noRecords = null;
    },
    async search() {
      this.searchModal = true;
      this.searchLoading = true;      
      this.matchingRecords = await this.$axios.get(`/requests?search=${this.searchCriteria}&where={"status":"pending"}`)
        .then(response => {
          return response.data.map(card => card = {...card, ...this.cardOverlay, commentDialog: null, reviewType: ''});
        })
      this.searchLoading = false;
      if (!this.matchingRecords.length) {
        this.noRecords = true;
      }
    },
    removeCard (index) {
      this.matchingRecords.splice(index, 1);
      this.noRecords = !this.matchingRecords.length;
      },
  },
}
</script>

<style>
.sub {
  position: relative;
  padding: 20px 0 0;
}
body .row{
  margin: 0 -12px;
}
.sub .v-card__text{
 padding: 15px 0;
}
 body .v-application .delegated-btn{
    cursor: default !important;
    font-size: 11px ;
    height: auto !important;
    padding: 5px 10px !important;
    background: #5f249f;
    color: #fff !important;
  }
  .v-application .tile-block{
    margin:0 18px 10px;
  }
  .v-application .tile-block .ma-5{
     margin: 0 !important;
  }
  .v-application .v-avatar--tile{
    height: 70px !important;
  }
  .v-application .v-avatar--tile .headline{
    font-size: 18px !important;
    font-weight: 500;
    text-transform: uppercase;
  }
  .v-application .headline{
    font-size: 1.3rem!important;
  }
  .data-refrenshes p{
        text-align: right;
    font-size: 16px;
  }
  .v-input.v-text-field{
    margin: 4px 20px 0;
  }
  .v-application .v-messages, .v-application .v-text-field__details{
        min-height: auto;
  }
  body{
    font-family:Arial, Helvetica, sans-serif !important;
  }
  .v-application h4{
    color:#000; 
    font-size:15px ;   
  }
  .v-application p{
    font-size: 14px;
    color:#495057;
  }
  
 .detailsTitle {
    margin-left: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin-top: 10px;
  }
  .content-title{
    border-bottom: 1px solid #ddd;
    font-size:18px;
    text-transform: uppercase;
    padding: 0 0 5px;
    margin: 0 0 10px;
    color: #000;
  }
  .v-application .mt-5.v-card {
    margin: 0 0 20px !important;
}
 .v-application .v-card p{
   margin-bottom: 0;
 }

 body .v-application .flex-shrink-1{
       padding: 0;
 }
 body .v-application .v-data-table{
   margin: 0 !important;
 }
 .theme--light.v-data-table.v-data-table--fixed-header thead th {
    background:#63666A;
    color: #fff !important;
    border: 1px solid #ddd;
    font-size:12px;
    text-transform: uppercase;
    text-align: center !important;
  }
 .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
  border: 1px solid #ddd;
 } 
 .v-text-field .v-input__slot .v-label{
    top: 10px;
 }
 .v-application .v-divider{
   margin:0;
 }
 .v-application .v-btn--icon.v-size--default{
    height: 20px;
    width: 20px;
 }
 body .v-window {
    overflow: inherit;
}
.searchGlobal .v-btn:not(.v-btn--round).v-size--default{
  font-size: 13px !important;
  padding: 5px !important;
  margin: 0 0px 5px 0 !important;
}
.searchGlobal .v-btn > .v-btn__content .v-icon {
    font-size: 14px !important;
}
</style>
