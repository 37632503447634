import Vue from 'vue';
import { default as msalPlugin } from "vue-msal-browser";
const msalConfig = {
  auth: {
    tenant: process.env.VUE_APP_ENTRA_TENANT_ID,
    clientId: process.env.VUE_APP_ENTRA_CLIENT_ID,
    authority: process.env.VUE_APP_ENTRA_DOMAIN,
    redirectUri: process.env.VUE_APP_ENTRA_REDIRECTURL, // It has to be configured on your Azure tenant
    scopes: [ "openid","email", "profile","User.Read"],
    navigateToLoginRequestUrl: true,
    prompt: "consent",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
},
mode: "popup",
}
Vue.use(msalPlugin, msalConfig);