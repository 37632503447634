<template>
  <div>
    <v-container >
      <v-row justify="center" align="center">
        <v-col v-for="comment in comments" :key="comment.date" class="grey comment-block lighten-5 col-sm-12 col-ms-12 col-lg-10">
          <v-row no-gutters>
            <v-col
              cols="3"
              sm="3"
              
            >
            <div>
              <h4 style="color: #00607A" class="apprHeader">{{comment.title}}</h4>
            </div>
            </v-col>
            <v-col
              cols="6"
              sm="6"
            >
            <div>
              <p class="apprHeader">{{comment.text}}</p>
            </div>
            </v-col>
            <v-col
              cols="3"
              sm="3"
              
            >
              <div>
              <p class="apprHeader">{{showDate(comment.date)}}</p>
            </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ['comments'],
  methods: {
    showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      }
  }
  }
</script>

<style lang='scss' scoped>
  .apprHeader {
    color: #9e9e9e;
  }

.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
    margin-left: 15.5px;
    margin-right: 40px;
    max-width: 100%;
}
.v-application .comment-block{
  padding: 0 20px;
}
</style>