<template>
  <v-card max-width="100%" class="mt-5 nspta">
    <v-row no-gutters>
      <v-col cols="8" xs='12' sm='12' md="7" lg="8">
        <v-container max-height="100%">
            <v-layout row wrap>
              <Overlay :card="card" />
           
              <v-flex xs12 md3 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Request ID</h4>
                    <p class="apprInfo">{{card.header.referenceId.replace(/[^-]*-/,"")}}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md3 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Waiver Status</h4>
                    <p>{{ card.data.nsptaDetails.BasicInformation.waiverStatus }}</p>
                  </div>
                </v-container>
              </v-flex>
               <v-flex xs12 md3 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Created</h4>
                    <p>{{ showDate(card.header.sourceCreatedAt) }}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md3 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Days Outstanding</h4>
                    <p>{{Math.round((new Date() - new Date(card.header.availableForApprovalAt))/1000/60/60/24)}}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md3 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">System/Approval Type</h4>
                    <p>{{ `${card.header.source} / ${card.header.type}`}}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md3 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Region</h4>
                    <p>{{ card.data.nsptaDetails.BasicInformation.region }}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md3 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Requester Email</h4>
                    <p>{{ card.data.nsptaDetails.BasicInformation.requesterEmail }}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md3 lg3>
                <v-container>
                  <div>
                    <h4 class="apprHeader">On Behalf Requester Email</h4>
                    <p>{{ card.data.nsptaDetails.BasicInformation.onBehalfRequesterEmail }}</p>
                  </div>
                </v-container>
              </v-flex>
               
          </v-layout>
        </v-container>
      </v-col>
      <v-col cols="12" sm='12' md="5" lg="4" align="center" justify="center">
          <v-container style="background-color: rgba(0, 121, 153, 0.04)" fill-height>
             <v-col cols="12" sm="12" md="12"
                          class="flex-grow-0 flex-shrink-1"
                          justify="center" align="center">
                      <v-row  justify="center" align="center">
                            <v-btn
                              v-if="card.delegated"
                              color="primary"
                              class="custom-btn delegated-btn"
                              dark
                              large
                              text
                              v-bind="attrs"
                              v-on="on"
                            >
                              DELEGATED
                            </v-btn>
                      </v-row >
                    
            <v-card-actions class="fixIeFormatting">
              <v-row align="center" justify="center">
                <v-col cols="12" sm="12" md="12"
                  style="min-width: 100px;"
                      class="flex-grow-0 flex-shrink-1"
                      justify="center" align="center">
                  <v-row  justify="center" align="center">
                   <Review  :card="card" @reviewed="review"/>
                    <v-col sm='12' md="5" lg="5">
                      <v-btn text @click="show = !show" style="color: #00607A">
                        Details
                        <v-icon>
                          {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                      </v-btn>
                    
                    </v-col>
                      <v-divider vertical class="vDivider"></v-divider>
                    <v-col
                      sm='12' md="5" lg="5"
                    >
                        <v-btn dark text class="pa-2" style="color: #00607A" target="_blank" :href="card.header.sourceLink">
                          Go To App
                          <v-icon>
                            mdi-launch
                          </v-icon>
                        </v-btn>
                     
                    </v-col>
                  </v-row >
                </v-col>
              </v-row>
            </v-card-actions>
            </v-col>
          </v-container>
      </v-col>
    </v-row>

      <v-expand-transition>
        <div v-show="show" class="grey lighten-5">
          <v-divider horizontal></v-divider>
          <v-layout row wrap justify-end>
            
            <v-flex shrink style="padding-right: 30px; padding-top: 10px">
              <v-btn icon @click="show = null">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-container
            id="scroll-target"
            style="max-height: 420px"
            class="overflow-y-auto"
          >
            <v-card outlined class="mt-5">
              <v-col cols=12>
                 <h3 class="content-title">Details</h3>
                <v-row   
                  child-flex
                >
                <NsptaDetails v-if="card.data && card.data.nsptaDetails" :cardData="card.data.nsptaDetails" :header="card.header"></NsptaDetails>
                
                  </v-row>
              </v-col>
            </v-card>
          </v-container>  
        </div>
      </v-expand-transition>
    </v-card>
</template>

<script>
import NsptaDetails from '../FinanceCard/NsptaDetails';
import Review from '../viewComponents/Review';
import Overlay from '../viewComponents/Overlay';

export default {
  props: ['card'],
  components: {
    NsptaDetails,
    Review,
    Overlay,    
  },
  data() {
   return  {
      show: null,
    }
  },
  async mounted(){
   
  },
  methods: {    
      showCurrency(amount) {
      if (!amount) return ""
      return (
        this.$store.state.currencyFormat === "comma"
        ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
        : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
      )
    },
      showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },
      review(card){
        this.$emit('reviewed', card);
      },
  }
}
</script>
<style scoped>
.v-application .nspta h4 {
    font-size: 14px;
}
</style>