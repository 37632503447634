<template>
  <v-dialog v-model="reloadNeeded">
    <v-card id="error-modal">
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      reloadNeeded: null
    }
  },
 async created() {
    /*if (!await this.$msal.isAuthenticated()) {
      this.reloadNeeded = true;
      await this.$msal.login();
    } */
  },
}
</script>