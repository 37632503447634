<template>
  <div>
    <loading :active.sync="isLoading" 
      :is-full-page="fullPage">
    </loading>
    <Toolbar v-bind:title="'Time'" @searchParam="changeSearch" v-bind:imgSrc="require('../assets/time-tab.png')"></Toolbar>
  <v-row>
    <v-col sm="12">
      <v-container style="padding-top:0; padding-bottom:0">
        <v-row no-gutters >
          <v-col
          class=""
            cols="6"
            sm="6"
          >
              <h2>Pending Requests</h2>
          </v-col>
          <v-col
            cols="4"
            sm="1"
            lg="2"
            style="max-width: 125px; margin-left: 39%;"
          >
              <v-select
              style="max-width: 100px;"
              :items="sorters"
              label="Sort By"
              @input="sortCards"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    <v-container>
      <div :key="cardKey">
        <v-tabs v-model="tab">
            <v-tab v-for="tabname in tablist" :key="tabname.id" v-on:click="currentTab(tabname)">{{tabname}}</v-tab>
            <v-tabs-items v-model="tab">
            <v-tab-item  v-for="item in tablist" :key="item.tab">
              <div class="text-xs-center">
                <div class="delete-block">
                          <v-checkbox style="display:inline-block" v-model="allSelected" @click="selectAll"                
                            :label="`Select All`"
                          ></v-checkbox>
                            
                </div>
                <v-card  v-bind:class="{active: actionBtnall}" justify="center"  style="display: none;">
                  <Overlay :card="cardOverlay" />  
                  <div class="release-right" style="text-align:center">
                       <!-- <Bulkapproved  :card="card" :selectedCheck="selectCheckbox" />-->
                       <v-btn class="close-btn" v-on:click='cancel()'>
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                       <v-simple-table fixed-header outline class="bulkapprove">
                            <template>
                              <thead>
                                <tr>
                                  <th class="text-left">Requests Selected</th>
                                  <th class="text-left">Reference Id</th>
                                  <th class="text-left">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                <td>{{selectCheckbox.length}}</td>
                                <td><p>{{selectCheckbox.map(elem => elem.replace(/[^-]*-/,"")).toString()}}</p></td>
                                <td>
                                <v-col>
                                  
                                  <v-btn style="margin-left: 5px;fontSize: 12px;height: 30px;" color="{ requestType == 'Approve' ? primary : danger }" @click="commentDialogdelete=true;"> Already Actioned </v-btn>
                                
                                </v-col>
                                <v-dialog  v-model="commentDialogdelete" max-width="700px">
                                  <v-card style="background-color: #E5E5E5; padding:10px" >
                                    <v-card-title class="justify-center">Request : {{selectCheckbox.map(elem => elem.replace(/[^-]*-/,"")).toString()}}?</v-card-title>
                                  <v-form ref="vsmform">
                                    <p class="delete-text">Are you sure you want to remove the request(s) from Approval Central work list?</p>
                                    <v-card-actions class="justify-center concur-btn" >         
                                    <v-btn  color="{ requestType == 'Approve' ? primary : danger }"  v-on:click='deleteReq()'>Yes</v-btn>
                                    <v-btn v-on:click='cancel()'>No</v-btn>
                                  </v-card-actions>
                                  </v-form>
                                  </v-card>
                                </v-dialog> 
                                
                              <!--    <v-btn outlined rounded text style="margin-left: 5px; backgroundColor: white; fontSize: 13px" v-on:click="deleteReq()"> Delete </v-btn>--></td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                      </div>
                  </v-card>
                </div>
            <v-flex v-for="card in visibleCards" :key="card.id" style="position: relative;">
             
               <div v-if="item === 'timesheet'">
                <v-checkbox @click="select" class="selectbox timesheet"
                        v-model="selectCheckbox" :value="card.header.referenceId" :id="card.header.referenceId"
                      ></v-checkbox> 
                <FieldGlassTimeRecord style="padding: 0 0 0 60px;" v-if="card.header.source === 'fieldglass'" :card="card"  @reviewed="removeCard" />    
                <RepliconTimesheet style="padding: 0 0 0 60px;" v-if="card.header.source === 'replicon'" :card="card"  @reviewed="removeCard" />
               </div>
               <div v-if="item === 'timeOff'" >
                <v-checkbox @click="select" class="selectbox timeOff"
                        v-model="selectCheckbox" :value="card.header.referenceId" :id="card.header.referenceId"
                      ></v-checkbox> 
                <RepliconTimeoff style="padding: 0 0 0 60px;" v-if="card.header.type === 'timeOff'" :card="card"  @reviewed="removeCard" />    
               </div>
              </v-flex>
             </v-tab-item>
            </v-tabs-items>
          </v-tabs>
      </div>
            <div class="text-center mt-5" v-bind:class="{active: actionBtnall}">
              <!--<v-pagination
                v-model="page"
                :length="Math.ceil(tabCards.length/perPage)"
                :class="{ hide: seen }"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              ></v-pagination>-->
              <Paginations :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" >
              </Paginations>
              <ul class="showItems">
            <li>Show Items:
              <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                <option :value= "5">5</option>
                <option :value="10"> 10 </option>               
                <option :value= "totalRecords">All</option>
              </select>
            </li>
      </ul> 
            </div>
      
    </v-container>
  </v-col>
  </v-row>    
  </div>
</template>

<script>
  import Toolbar from '../components/viewComponents/toolbar';
  import Loading from 'vue-loading-overlay';
  import FieldGlassTimeRecord from '../components/searchRecord/FieldGlassTimeRecord'
  import RepliconTimeoff from '../components/searchRecord/RepliconTimeoff'
  import RepliconTimesheet from '../components/searchRecord/RepliconTimesheet'
  import 'vue-loading-overlay/dist/vue-loading.css';
  import Overlay from '../components/viewComponents/Overlay';
  import Paginations from '../components/viewComponents/Paginations';

  export default {
    components: {
      Toolbar,
      Loading,
      FieldGlassTimeRecord,
      RepliconTimeoff,
      RepliconTimesheet,
      Overlay,
      Paginations 
    },
    data: () => ({
      status: '',
      statusCode: null,
      searchInput: '',
      selectedIndex: null,
      cardOverlay: {successOverlay:false,deleteOverlay:false,approvalOverlay: false, rejectedOverlay: false, pendingOverlay: false, failedOverlay: false},
      isLoading: false,
      fullPage: true,
      page: 1,
      perPage: 5,
      cards: [],
      sorters:['newest', 'oldest'],
      sort: '',
      red: false,
      cardKey: 0,     
      cardDataData : [],
      tablist:[],
      tab:null,
      tabCards:[],
      tablistCard:[],
      seen:false,
      actionBtnall:false,
      selectCheckbox:[],
      allSelected:false,
      commentDialog:'',
      commentDialogdelete:'',
      reviewType: '',
      comment:'',
      rules: [],
      timeoffblock: false,
      totalPages: 0,
      totalRecords: 0,
      recordsPerPage: 5,
      enterpageno: '',
      totalSource:[],
      currentSource:'',
      totalId:[],
      pagelimit: 0,
    }),
    async mounted () {
    /*  this.isLoading = true
      const timesheet = await this.$axios.get(`/time?where={"status":"pending"}`).then(response => {
        return response.data.map(card => card = {...card, ...this.cardOverlay, commentDialog: null, reviewType: ''});
      })
      if (this.cards.length !== this.$store.state.timeCount) {
        await this.$store.dispatch('fetchCounts')
      }
      this.isLoading = false;
      this.cards = timesheet;
      this.tablistCard= timesheet;
      this.sortCards('oldest');
      //Tab Intergartion
      for (var j=0;j< this.cards.length; j++){
         var sourceName = this.cards[j].header.type;
         if (!this.tablist.includes(sourceName)){
            this.tablist.push(sourceName)
          }
      }
       this.currentTab(this.tablist[0]);

      */ 
    },
    computed: {
      visibleCards () {
      //return this.changeSearch(this.searchInput).slice((this.page - 1)* this.perPage, this.page * this.perPage)
      if(this.searchInput != ''){
          return this.loadListItem(this.searchInput);
        }else{
          return this.cards;
        }  
    },
      currencyFormat () {
        return this.$store.state.currencyFormat;
      },
      dateFormat () {
        return this.$store.state.dateFormat;
      }
    },
    async created () {
      this.loadListItem();
    },
    methods:{
       //Pagination
async loadListItem (param = ' ') {
              this.isLoading = true;
              if(param != null){
                const request = await this.$axios.get(`/time?where={"status":"pending","type":"${this.currentSource}"}&searchparam=${param}&limit=${this.recordsPerPage}&page=${this.page}`)
                .then(response => {          
                  //console.log('response', response)
                  this.isLoading = false;          
                  this.$store.state.visibleCards = response.data.data         
                  this.totalPages = Math.ceil(response.data.count / this.recordsPerPage) // Calculate total records
                  this.totalRecords = response.data.count;
                  this.totalSource = response.data.type ;
                  this.totalId = response.data.requestID;  
                  return response.data.data.map(card => card = {...card, ...this.cardOverlay,  commentDialog: null, reviewType: ''});
                })
                  this.cards = request;
                  this.tablistCard=request;
                  
              }
             else{              
              const request = await this.$axios.get(`/time?where={"status":"pending","type":"${this.currentSource}"}&limit=${this.recordsPerPage}&page=${this.page}`)
              .then(response => {          
                //console.log('response', response)
                this.isLoading = false;          
                this.$store.state.visibleCards = response.data.data         
                this.totalPages = Math.ceil(response.data.count / this.recordsPerPage) // Calculate total records
                this.totalRecords = response.data.count;
                this.totalSource = response.data.type; 
                this.totalId = response.data.requestID;                   
                return response.data.data.map(card => card = {...card, ...this.cardOverlay, commentDialog: null, reviewType: ''});
              })
              
                this.cards = request;
                this.tablistCard=request; 
               
              }
            //this.sortCards('newest');
            for (var j=0;j< this.totalSource.length; j++){
              var sourceName = this.totalSource[j];
              console.log(this.totalSource)
              if (!this.tablist.includes(sourceName)){
                  this.tablist.push(sourceName)
                }
            }
            //this.currentTab(this.tablist[3]);
            this.pagelimit =  Math.ceil(this.totalRecords - this.recordsPerPage)
        },
          onPageChange (page) {
            this.page = page;
            this.loadListItem()           
          },
          
          onChangeRecordsPerPage () {
            this.loadListItem();           
          },

      //Bulk Approved Request
      selectAll(){
         this.selectCheckbox = [];
         this.actionBtnall = true;
          if (this.allSelected) {
              //for (var i=0;i< this.cards.length; i++){          
              //deleteId[i] = this.cards[i].header.referenceId;
              //this.selectCheckbox = [...this.selectCheckbox, this.cards[i].header.referenceId]
              //} 
              this.selectCheckbox =  this.totalId;
            }
            if (this.selectCheckbox.length == 0){
              this.actionBtnall = false;
              this.selectCheckbox = [];
              this.allSelected = false;
            }
       },
       select(){
         this.allSelected = false;
         this.actionBtnall = true;
         if (this.selectCheckbox.length == 0){
            this.actionBtnall = false;
            this.allSelected = false;
          }
        },
     
        async deleteReq(){
          this.commentDialogdelete = false;
        this.cardOverlay.pendingOverlay = true;        
        try {
          const deleteRe = await this.$axios.post('requests/archive',this.selectCheckbox);
          console.log(deleteRe);
          this.cardOverlay.pendingOverlay = false;
          this.cardOverlay.deleteOverlay = true;
          await new Promise(resolve => {
              setTimeout(resolve, 2000);
            })
            this.cardOverlay.deleteOverlay = false;
            window.location.reload();
        }
        catch (err){
          this.cardOverlay.pendingOverlay = false;
          this.cardOverlay.failedOverlay = true;         
          this.$axios.post(`/errors`, { referenceId: this.selectCheckbox, system: "VirtualSM", action: this.reviewType}).catch(() => console.log('error failed to create'));
          await new Promise(resolve => {
              setTimeout(resolve, 7000);
            })
          this.cardOverlay.failedOverlay = false;
        }
          this.actionBtnall = false;
          this.selectCheckbox = [];
          this.allSelected = false;
       },

       cancel() {
        this.commentDialogdelete = false; 
        this.rules = [];
        this.actionBtnall = false;
        this.selectCheckbox = [];
        this.allSelected = false;
      },
      //Tab Intergartion
      currentTab(source){
        this.actionBtnall = false;
        this.selectCheckbox = [];
        this.allSelected = false; 
        this.tabCards = this.tablistCard.filter(_ => _.header.type === source)
        this.cards = this.tabCards;
        this.page = 1;
        this.currentSource  = source
        this.loadListItem();
      },

      searchClear(params){
          if(params === '' || params === undefined){
            this.searchInput = '';
            this.seen = false;
            return this.cards;
          }
        },

      changeSearch (params) {
        this.searchClear();
        if (params) {
          params = params.toLowerCase();
        }
        this.loadListItem(params);

        /*
        this.searchClear();
        if (params) {
          this.searchInput = params.toLowerCase();
        }
        if(params === '' || params === undefined){
          this.searchInput = '';
          return this.cards;
        }
        if(this.searchInput != ''){
          this.seen = true;
          return this.cards.filter(card => {
            let cardArr = Object.values(card.header).map(value => {      
              if (typeof(value) === 'string') { 
                return value 
                  } else if (!value) {
                    return
                  } else {
                    return Object.values(value)
                  }
              })
            cardArr = cardArr.flat().filter(str => {
              return typeof str === 'string';
            })
            const matches = cardArr.filter(string => new RegExp(this.searchInput).test(string.toLowerCase()) && this.searchInput != '')
            if (matches.length > 0) {
              this.page = 1;
              return card;
            }
          })
        }*/
      },

      removeCard (cardData) {
        this.cards= this.cards.filter(_ => cardData.id != _.id )    
        return this.cards
        },
      sortCards (event) {
        this.cards.sort((a,b) => {
            if (a.header.availableForApprovalAt > b.header.availableForApprovalAt) {
              return event === 'newest' ? -1 : 1
            } else if (a.header.availableForApprovalAt < b.header.availableForApprovalAt) {
              return event === 'newest' ? 1 : -1
            } else {
              return 0;
            }
          });
      },
      
    }
  }
</script>
<style>
.v-text-field__details{
  min-height: inherit !important;
}
.v-input.v-text-field {
    padding-top: 0; 
    margin-top: 0; 
}
.v-text-field__details .v-messages{
min-height: inherit !important;
}
.v-tabs-bar{
    border-bottom: 1px solid #ccc !important;
    margin: 0 0 10px !important;
    height: 35px !important;
}
.v-tab {
    font-size: 13px;
    color: #444 !important;
    background: #ddd;
    border-radius: 5px 5px 0 0;
    margin: 0 0 0 3px;
    font-weight: bold;
}
.v-tab--active{
    font-size: 14px;
    color: #fff !important;
    background-color: #5f249f;
}
.v-tab:before, .v-tabs-slider-wrapper{
  display: none;
}
</style>