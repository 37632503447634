<template>
<v-row>
  <v-container>
    <v-row>
      <v-flex xs12 md4 lg3>
          <v-col sm="12">
            <v-container>
              <div>
                <h4 class="apprHeader">Request Type</h4>
                <p class="apprInfo">{{cardData.requestType}}</p>
              </div>
            </v-container>
          </v-col>
      </v-flex>
      <v-flex xs12 md4 lg3>
          <v-col sm="12">
            <v-container>
              <div>
                <h4 class="apprHeader">Title</h4>
                <p class="apprInfo">{{header.title}}</p>
              </div>
            </v-container>
          </v-col>
      </v-flex>
      <v-flex xs12 md4 lg3>
        <v-col sm="12">
          <v-container>
              <div>
              <h4 class="apprHeader">Submitter</h4>
              <!-- 
                 <p class="apprInfo">{{header.requester.name}}</p> 
              -->
              <p class="apprInfo">{{header.requester ? header.requester.name: 'NA'  }}</p>
            </div>
          </v-container>
        </v-col>
      </v-flex>
      
      <v-flex xs12 md4 lg3>
        <v-col sm="12">
          <v-container>
            <div>
              <h4 class="apprHeader">Created At</h4>
              <p class="apprInfo">{{showDate(header.sourceCreatedAt)}}</p>
            </div>
          </v-container>
        </v-col>
      </v-flex>
      <v-flex xs12 md4 lg3>
        <v-col sm="12">
          <v-container>
            <div>
              <h4 class="apprHeader">Sponsor</h4>
              <p class="apprInfo">{{cardData.sponsorName}}</p>
            </div>
          </v-container>
        </v-col>
      </v-flex>
      <v-flex xs12 md4 lg3>
        <v-col sm="12">
          <v-container>
            <div>
              <h4 class="apprHeader">Processor</h4>
              <p class="apprInfo">{{cardData.processorName}}</p>
            </div>
          </v-container>
        </v-col>
      </v-flex>
      <v-flex xs12 md4 lg3>
        <v-col sm="12">
          <v-container>
            <div>
              <h4 class="apprHeader">Facility Name</h4>
              <p>{{cardData.facilityName}}</p>
            </div>
          </v-container>
        </v-col>
      </v-flex>
       <v-flex xs12 md4 lg3>
        <v-col sm="12">
          <v-container>
            <div>
              <h4 class="apprHeader">Sponsor Comment</h4>
              <p>{{cardData.sponsorComment}}</p>
            </div>
          </v-container>
        </v-col>
      </v-flex>
       <v-flex xs12 md4 lg3>
        <v-col sm="12">
          <v-container>
            <div>
              <h4 class="apprHeader">Processor Comment</h4>
              <p>{{cardData.processorComment}}</p>
            </div>
          </v-container>
        </v-col>
      </v-flex>
       <v-flex xs12 md4 lg3>
        <v-col sm="12">
          <v-container>
            <div>
              <h4 class="apprHeader">Start Date</h4>
              <p>{{cardData.accessStart === null ? '' : showDate(cardData.accessStart)}}</p>
            </div>
          </v-container>
        </v-col>
      </v-flex>
      <v-flex xs12 md4 lg3>
        <v-col sm="12">
          <v-container>
            <div>
              <h4 class="apprHeader">End Date</h4>
              <p>{{cardData.accessEnd === null ? '' :showDate(cardData.accessEnd)}}</p>
            </div>
          </v-container>
        </v-col>
      </v-flex>
      <v-flex xs12 md4 lg3>
        <v-col sm="12">
          <v-container>
            <div>
              <h4 class="apprHeader">Justification</h4>
              <p>{{header.comment}}</p>
            </div>
          </v-container>
        </v-col>
      </v-flex>
  </v-row>

  <v-row no-gutters>
    <v-col cols="12" sm="12">
        <v-simple-table fixed-header outline >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Access Level</th>
                <th class="text-left">Authorizer</th>
                <th class="text-left">Owner</th>
                <th class="text-left">Approver Comment</th>
                <th class="text-left">Owner Comment</th>
                <!-- <th class="text-left"><span class="leftAlign">Approve / Reject</span></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in cardData.accessLevelDetails" :key="item.id">
                <td>{{item.accessLevelName}}</td>
                <td>{{item.authorizerName}}</td>
                <td>{{item.ownerName}}</td>
                <td>{{item.authorizerComment}}</td>
                <td>{{item.ownerComment}}</td> 
                <!-- <td>
                    <v-btn v-if="item.id === 3" v-show="true" outlined rounded text style="margin-right: 5px; backgroundColor: lightgrey; fontSize: 15px;cursor:not-allowed" @click="approve=false">
                      <v-icon v-if="item.id === 3" v-bind:style="{padding: '5px', color: 'grey'}">mdi-thumb-up</v-icon>
                      <b>Approve</b>
                    </v-btn>
                    <v-btn v-else v-show="true" outlined rounded text style="margin-right: 5px; backgroundColor: white; fontSize: 15px" @click="approve=true">
                      <v-icon v-bind:style="{padding: '5px', color: 'green'}">mdi-thumb-up</v-icon>
                      <b>Approve</b>
                    </v-btn>
                    <v-btn v-if="item.id === 3" v-show="true" outlined rounded text style="margin-left: 5px; backgroundColor: lightgrey; fontSize: 15px;cursor:not-allowed" @click="reject=false">
                      <v-icon v-if="item.id === 3" v-bind:style="{padding: '5px', color: 'grey'}">mdi-thumb-down</v-icon>
                      <b>Reject</b>
                    </v-btn>
                    <v-btn v-else v-show="true" outlined rounded text style="margin-left: 5px; backgroundColor: white; fontSize: 15px" @click="reject=true">
                      <v-icon v-bind:style="{padding: '5px', color: 'red'}">mdi-thumb-down</v-icon>
                      <b>Reject</b>
                    </v-btn>
                    
                </td> -->
               
               
              </tr>
            </tbody>
          </template>
        </v-simple-table>
         <!-- <v-dialog  :key="11" v-model="approve" max-width="500px">
          <v-card style="background-color: #E5E5E5;" >
            <v-card-title class="justify-center"> Request?</v-card-title>
            <v-textarea style="margin: 15px; background-color: white" placeholder="Please enter a comment about this request"></v-textarea>
            <v-card-actions class="justify-center" >
              <v-btn color="primary">Approve</v-btn>
              <v-btn color="danger"  @click="approve=false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
         <v-dialog  :key="11" v-model="reject" max-width="500px">
          <v-card style="background-color: #E5E5E5;" >
            <v-card-title class="justify-center"> Request?</v-card-title>
            <v-textarea style="margin: 15px; background-color: white" placeholder="Please enter a comment about this request"></v-textarea>
            <v-card-actions class="justify-center" >
              <v-btn color="primary">Reject</v-btn>
              <v-btn color="danger"  @click="reject=false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
     </v-col>
  </v-row>
  </v-container>
  
  
  
</v-row> 
</template>

<script>

export default {
   props: ['cardData', 'header'],    
   
  data: () => ({
    status: '',
    absolute: true,
    statusCode: null,
    selectedIndex: null,
    cardOverlay: {approvalOverlay: false, rejectedOverlay: false, failedOverlay: false},
    isLoading: false,
    fullPage: true,
    page: 1,
    perPage: 5,
    cards: [],
    sorters:['newest', 'oldest'],
    sort: '',
    red: false,
    cardKey: 0,
    approve: null,
    reject: null,
  }),
  methods: {
    showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      }
   }
}
//console.log(cardData.aparsDetails.length);
</script>
<style lang="scss" scoped> 
.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
    margin-left: 15.5px;
    margin-right: 40px;
    max-width: 100%;
}
 
  .theme--light.v-btn.v-btn--outlined{
      border-width: 2px;
      border-color: black;
  }
  .leftAlign{
    display: inline-block;
    margin-left:20%;
  }
.static{
   display: none;
 }
 .static.active{
   display: block;
 }
 tr.static.active{
   display: table-row;
 }

</style>