<template>
  <v-row>
    <v-col sm="12">
      <v-row>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Request Type</h4>
                <p class="apprInfo">{{cardData.requestType}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Owner Name</h4>
                <p>{{cardData.ownerFullName }}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Pending State</h4>
                <p>{{cardData.pendingState }}</p>
              </div>
            </v-container>
          </v-flex>
          </v-row> 
          <v-row>
        <v-container>
          <h4>Accesses:</h4>         
            <v-simple-table fixed-header outline style="margin-top:10px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Request Sub-Type</th>
                    <th class="text-left">Access Name</th>
                    <th class="text-left">Application Name</th>
                    <th class="text-left">Identity Name</th>
                  </tr>
                </thead>
               
                  <tbody v-for="row in cardData.accesses" :key="row.id">
                    <tr  v-for="access in row.accessName" :key="access.id">
                    <td >{{row.requestSubType}}</td>
                    <td >{{access}}</td>
                    <td >{{row.applicationName}}</td>
                    <td >{{row.identityFullName}}</td>
                    </tr>
                 
                </tbody>
              </template>
            </v-simple-table>
          </v-container>  
          </v-row>          
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['cardData']
}
</script>