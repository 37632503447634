<template>
    <nav style="z-index: 10;" v-bind:class="{active: navbarHeight}">    
        <v-app-bar dark fixed color="purple" flat>
        
            <v-list class="footer-link">
            <div class="notification-block"  v-bind:class="{active: notifActive}">
                    <div style="line-height: 12px;" v-html="notifContent"></div> 
                <v-icon  v-on:click="closeNotifi()" class="close-btn" :size="sizes.xLarge">mdi-close</v-icon>
                </div>
                <v-list-item>
                    <v-list-item-content color="primary">
                        <v-list-item-title v-on:click="releaseNote()"> <span> What's New</span></v-list-item-title>
                        <v-list-item-title><span>|</span></v-list-item-title>
                        <v-list-item-title> <a target="_blank" href="https://uptime.dxc.com/knowledges/detail/KB0113989">  <span>Know more </span></a></v-list-item-title>
                        <v-list-item-title><span>|</span></v-list-item-title>
                        <v-list-item-title> <a href="/faq">  <span>{{faqLinks.text}} </span></a></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
         <v-dialog v-model="releaseDialog" max-width="650px" content-class="releaeblock">
            <v-card justify="center"  style="border-radius: 0;">
              <div class="release-left">
                <h4>{{releaseData.Whatnew_Heading}}  {{releaseData.Version}} on {{releaseData.Date}}</h4>
              </div>
              <div class="release-right">
                <div v-show="showNew">
                <h3>{{releaseData.Title_New}}</h3>
                <ul>
                     <li v-for="contentNew in releaseData.New_Content" :key="contentNew.id"><p>{{contentNew}}</p></li>
                </ul>
                
                </div>
                <div v-show="showImprove" >
                <h3> {{releaseData.Title_Improvements}}</h3>
                 <ul>
                    <li v-for="contentImprove in releaseData.Impro_Content" :key="contentImprove.id"><p>{{contentImprove}}</p></li>                
                </ul>
                </div>
                <div v-show="showFix">
                <h3>{{releaseData.Title_Fix}}</h3>
                 <ul>
                    <li v-for="contentFix in releaseData.Fix_Content" :key="contentFix.id"><p>{{contentFix}}</p></li>
                </ul>
              </div>

              </div>

              <v-btn class="close-btn" @click="releaseDialog = false">
                 <v-icon>mdi-window-close</v-icon>
               </v-btn>
            </v-card>

         </v-dialog>  
<div style="display: flex; align-items: center;">
   
          
          <v-img
                    alt="AC Logo"
                    class="shrink ml-5"
                    style="margin-left:0 !important;margin-right: 12px;cursor: pointer;"
                    contain
                    src="../../assets/dxc_logo_white_rgb.png"
                    transition="scale-transition"
                    width="110"
                    v-on:click="myDxc"
                />
           
           <v-img
                alt="AC Logo"
                class="shrink mr-2"
                contain
                src="../../assets/App_Central_logo.png"
                transition="scale-transition"
                width="35"
                v-on:click="home"
            /> 
            
            <v-toolbar-title v-on:click="home" style="cursor: pointer;">
                <span>Approval </span>
                <span class="">Central</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            
            <v-menu offset-y dark ref='menu'>
                <template v-slot:activator="{ on }">
                    <span class="username" v-on="on" style="padding-right: 20px; padding-left: 5px;">
                        <v-icon>mdi-account</v-icon> {{ userName }}
                    </span>
                </template>
                   
                    <v-list>
                        <v-dialog v-model="preferencesDialog" max-width="600px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item v-bind="attrs" v-on="on" @click="preferences=true; feedback=false; ">
                                    <span> 
                                        <v-icon>mdi-account-cog</v-icon> Preferences 
                                    </span>
                                </v-list-item>
                               <!-- <v-list-item  v-bind="attrs" v-on="on" @click="feedback=true; preferences=false;">
                                    <span>
                                        <v-icon>mdi-mailbox-up</v-icon> Give Feedback
                                    </span>
                                </v-list-item>-->
                            </template>
                           <v-card align-center>
                                <Overlay :card='cardOverlay' />
                                 <!--<v-card v-if="feedbackForm">
                                    v-form
                                        ref="form"
                                        v-model="valid"
                                        lazy-validation
                                    >
                                        <v-card-title style="background-color: #5F249F; color: white;">
                                            <span class="headline">Give Feedback</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-select 
                                                label="* Topic: " 
                                                :items="topics" 
                                                v-model="userFeedback.topic"
                                                required
                                                :rules="topicRules"
                                            ></v-select>
                                            <v-text-field 
                                                v-model="userFeedback.title" 
                                                label="Title"
                                                required
                                                :rules="titleRules"
                                            ></v-text-field> 
                                            <v-textarea 
                                                label="Feedback"
                                                class="text-label" 
                                                v-model="userFeedback.comment"
                                                required
                                                :rules="commentRules"
                                            ></v-textarea>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="primary" v-on:click="submitFeedback()">Submit</v-btn>
                                            <v-btn v-on:click="close()">Close</v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </v-card>-->
                                <v-card v-if="preferencesForm">
                                <v-card-title style="background-color: #5F249F; color: white;">
                                    <span class="headline">Display Preferences</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-radio-group v-model="dateFormat">
                                        <template v-slot:label>
                                            <div>
                                                <strong>Preferred Date Format: </strong>
                                            </div>    
                                        </template>
                                        <v-radio value="USA">
                                            <template v-slot:label>
                                                <div>MM/DD/YYYY - for example, 04/29/2020 </div>
                                            </template>
                                        </v-radio>
                                        <v-radio value="EUR">
                                            <template v-slot:label>
                                                <div>DD/MM/YYYY - for example, 29/04/2020 </div>
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                    <v-radio-group v-model="currencyFormat">
                                        <template v-slot:label>
                                            <div>
                                                <strong>Preferred Currency Format: </strong>
                                            </div>    
                                        </template>
                                        <v-radio value="decimal">
                                            <template v-slot:label>
                                                <div> $5,003.45 - five thousand three dollars and 45 cents </div>
                                            </template>
                                        </v-radio>
                                        <v-radio value="comma">
                                            <template v-slot:label>
                                                <div> $5 003,45 - five thousand three dollars and 45 cents </div>
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn color="primary" v-on:click="savePreferences()">Save</v-btn>
                                    <v-btn v-on:click="close()">Close</v-btn>
                                </v-card-actions>
                                </v-card>
                            </v-card>
                        </v-dialog>
                    </v-list>
            </v-menu>
           
            <v-btn outlined rounded v-if="this.userAccount.length === 0" v-on:click='login'>Login</v-btn>
            <v-btn outlined rounded v-if="this.userAccount.length != 0" v-on:click='logout'>Logout</v-btn>
               <v-icon  class="grey-text mr-3" :size="sizes.xLarge" @click="drawer = !drawer">mdi-menu</v-icon> 
            
            </div>
            </v-app-bar>
        <v-navigation-drawer v-model="drawer" fixed clipped temporary right>
            <loading :active.sync="isLoading" 
                :is-full-page="fullPage">
            </loading>

            <v-list>
                <v-list-item v-for="link in visibleLinks" :key="link.text" router :to="link.route" :disabled="!['Home', 'Admin'].includes(link.text) && link.amount === 0 ? true : false" >
                    <v-list-item-action >
                        <v-icon :style="{color: link.color1 }">{{ link.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content color="primary">
                    <v-badge
                        inline
                        v-if="link.amount > 0"
                        :color="link.color1"
                        :content="link.amount"
                        >
                        <v-list-item-title>{{ link.text }}</v-list-item-title>
                    </v-badge>
                    <v-list-item-title v-if="link.amount === 0">{{ link.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer> 
       

         <v-dialog v-model="preferencesDialog" max-width="650px" >
                            <template v-slot:activator="{ on, attrs }">
                               
                                <div class="feedback" v-bind="attrs" v-on="on" @click="feedback=true; preferences=false;">
                                    <span>
                                        Give Feedback
                                         <v-icon>mdi-comment-alert</v-icon>
                                    </span>
                                </div>
                            </template>
                            <v-card class="feedbackform" align-center>
                                
                                <Overlay :card='cardOverlay' />
                                <v-card v-if="feedbackForm">
                                    <v-form
                                        ref="form"
                                        v-model="valid"
                                        
                                    >
                                        <v-card-title style="background-color: #5F249F; color: white;">
                                            <span class="headline">Give Feedback</span>
                                            
                                                <v-icon v-on:click="close()" class="close-btn" :size="sizes.xLarge">mdi-close</v-icon>
                                     
                                        </v-card-title>
                                        <v-card-text>
                                            <p class="feedbacktext">We’d love to hear from you! Do you have suggestions on what should be on Approval Central? Use feedback form to share your experience.</p>
                                            <p class="feedbacktext feedbacknote">Note: Feedback will not be sent anonymously. The recipients may contact you for further inquiries about your feedback.</p>
                                            <div>
                                                <p class="required">Rate your overall experience with Approval Central</p>
                                                <v-rating  color="purple darken-3"
                                                background-color="purple darken-1"
                                                hover
                                                small
                                                required
                                                max="5"
                                                v-model="rating"
                                                increment="0.5"
                                                ></v-rating>
                                            </div>
                                            <p class="required">Does Approval Central help you in your daily job activities?</p>
                                            <v-radio-group v-on:change="enableBtn" v-model="is_helpful" row required>
                                                <v-radio 
                                                    label="Yes"
                                                    value="Yes"
                                                ></v-radio>
                                                <v-radio
                                                    label="No"
                                                    value="No"
                                                ></v-radio>
                                                </v-radio-group>
                                                 <p>What approvals would you like to see on Approval Central that are currently not here?</p>
                                            <v-textarea 
                                                label="Application Onboarding"
                                                class="text-label" 
                                                v-model="userFeedback.onboarding_request"
                                                
                                            ></v-textarea>
                                            <p>I would like to give feedback.</p>
                                            <v-textarea 
                                                label="Feedback"
                                                class="text-label" 
                                                v-model="userFeedback.feedback_comment"
                                                
                                            ></v-textarea>
                                             <p>I want to report a possible issue/problem.</p>
                                             <v-textarea 
                                                label="Issue"
                                                class="text-label" 
                                                v-model="userFeedback.issue_description"
                                                
                                            ></v-textarea>
                                          
                                            <p>Upload your documents</p>
                                           <p class="errMsg" v-bind:class="{active: errMsg}" > {{ errors[0]}}</p>
                                           
                                           <input  type="file" accept="image/*" v-bind:class="{active: fileUpload}" @change="onFileChange( $event )"/>
                                            
                                            
                                             <!-- <p>Other questions or comments?</p>
                                           <v-textarea 
                                                label="Issue/problem."                                           
                                                class="text-label" 
                                                v-model="userFeedback.comment"
                                                required
                                                :rules="commentRules"
                                            ></v-textarea>
                                          <v-select 
                                                label="* Topic: " 
                                                :items="topics" 
                                                v-model="userFeedback.topic"
                                                required
                                                :rules="topicRules"
                                            ></v-select>
                                            <v-text-field 
                                                v-model="userFeedback.title" 
                                                label="Title"
                                                required
                                                :rules="titleRules"
                                            ></v-text-field> -->
                                        </v-card-text>
                                        
                                        <v-card-actions>
                                            <v-btn color="primary" :disabled="isDisabled" v-on:click="submitFeedback()">Send</v-btn>
                                           <!-- <v-btn v-on:click="close()">Close</v-btn> -->
                                        </v-card-actions>
                                    </v-form>
                                </v-card>
                            </v-card>
                    </v-dialog>
                    
    </nav>
</template>

<script>
import { sizes } from '@/use/sizes'
import Loading from 'vue-loading-overlay';
import Overlay from './Overlay';
import { msalMixin } from 'vue-msal'; 
export default {   
    mixins: [msalMixin],
    components: {
      Loading,
      Overlay,
    },
    data() {
        return {
            feedback: null,
            cardOverlay: {successOverlay:false,deleteOverlay:false,approvalOverlay: false, rejectedOverlay: false, failedOverlay: false, pendingOverlay: false},
            preferences: null,
            userFeedback: {issue_description:' ', feedback_comment:' ', onboarding_request:' '},
            //topicRules: [v => !!v || 'Please select a topic'],
            //titleRules: [v => !!v || 'Title is required'],
            commentRules: [v => !!v || 'Comment is required'],
            sizes,
            userName: null,
            preferencesDialog: null,
            authenticated: null,
            isLoading: false,
            fullPage: true,
            drawer: false,
            datePreference: null,
            currencyPreference: null,
            links: [
                { icon: "mdi-home", text: "Home", route: "/", amount: 0},
                { icon: "mdi-piggy-bank", text: "Finance", route: "/finance", amount: 0, color1:'#97BE35',color2:'#FFFFFF'},
                { icon: "mdi-cart-outline", text: "Procurement", route: "/procurement", amount: 0, color1:'#6F2C91',color2:'#FFFFFF'},
                { icon: "mdi-key-chain", text: "DXC IT", route: "/dxcit", amount: 0, color1:'#0067B3',color2:'#FFFFFF'},
                { icon: "mdi-timer", text: "Time", route: "/time", amount: 0, color1:'#F38F20',color2:'#FFFFFF'}, 
                { icon: "mdi-airplane", text: "Travel", route: "/travel", amount: 0, color1:'#28AEE4',color2:'#FFFFFF'},                
                /*{ icon: "mdi-account-group", text: "HR", route: "/hr", amount: 0 , color1:'#F38F20',color2:'#FFFFFF'}, */
            ],
            /*topics: [
                { text: 'Issue', value: 'Issue' },
                { text: 'Feedback', value: 'Feedback' },
                { text: 'Application Onboarding', value: 'Application Onboarding' }
            ],*/
            valid: true,
            version: process.env.VUE_APP_VERSION,
            isDisabled:true,
            rating:0,
            is_helpful:null,
            file : null,
            //data:'',
            //selectedfiles:null,
            //url: null,
            /*heading: process.env.VUE_APP_WHATNEW_HEADING,
            date: process.env.VUE_APP_DATE,    
            newTitle: process.env.VUE_APP_TITLE_NEW,
            improvementsTitle: process.env.VUE_APP_TITLE_IMPROVEMENTS,
            fixTitle: process.env.VUE_APP_TITLE_FIX,
            newContent: process.env.VUE_APP_TITLE_NEW_CONTENT,
            improContent: process.env.VUE_APP_TITLE_IMPRO_CONTENT,
            fixContent: process.env.VUE_APP_TITLE_FIX_CONTENT,*/
            //test:[{releasetest:'test'},{releasetest:'test2'}],
            //releaseContent:[],
            releaseDialog: false,
            showing: false,
            //whatsnew: process.env.VUE_APP_WHATSNEW,
            //relFixcontent:'',
            //relImprocontent:'',
            //relNewcontent:'',
            releaseData:'',
            showNew:false,
            showImprove:false,
            showFix:false,
            todayDate:'',
            faqLinks:{text: "FAQs", route: "/faq"},
            errors:[],
            notifActive: false,
            navbarHeight:false,
           
            errMsg:false ,
            btnDisabled:false,
            notifContent:'',
            fileUpload:false,
            previewImage:'',
            s3imageUrl: process.env.VUE_APP_S3_URL, 
            token:'', 
            userAccount:'',    
        };
    },
    computed: {
        
        visibleLinks() {
            if (this.$store.state.isAdmin) {
                return [...this.links, { icon: "mdi-shield-account", text: "Admin", route: "/admin", amount: 0 }];
            } else {
                return this.links;
            }
        },
        feedbackForm() {
            return this.feedback && this.preferencesDialog            
        },
        preferencesForm() {
            return this.preferences && this.preferencesDialog
        },
        dateFormat: {
            get: function () {
                return this.$store.state.dateFormat;
            },
            set: function (newValue) {
                this.datePreference = newValue;
            }
            
        },
        currencyFormat: {
            get: function () {
                return this.$store.state.currencyFormat;
            },
            set: function (newValue) {
                this.currencyPreference = newValue;
            }
        },   
         financeCount () {
            return this.$store.state.financeCount;
        },        
        procurementCount () {
            return this.$store.state.procurementCount;
        },
        userAccessCount () {
            return this.$store.state.userAccessCount;
        },
        timeCount () {
            return this.$store.state.timeCount;
        }, 
        travelCount () {
            return this.$store.state.travelCount;
        },
        /*assetsCount () {
            return this.$store.state.assetsCount;
        } */
        newfileName(){
            return new Date().valueOf();
        },
        fileExtension(){
            return this.file.type.split('/')[1];
        },
        baseUrl(){
            return this.s3imageUrl;
        },
        fileName(){
            return `${this.newfileName}`
        }, 
        
      
    },
async mounted (){
        await this.$msal.handleRedirectPromise();
        this.userAccount = this.$msal.getAllAccounts();
       
        //after login      
            this.isLoading = true; 
            await this.$msal.handleRedirectPromise();
            const user = await this.$msal.getAllAccounts()[0]           
            if (this.userAccount.length === 0) {
                // No user signed in
                this.$msal.loginRedirect({
                scopes: [ "openid","email", "profile","User.Read"], // Replace with your required scopes
                })
                this.$msal.loginRedirect();
            }
           
            this.userName = `${user.name}` 
            if (this.userAccount.length != 0) { 
                this.authenticated = false;
                await this.$msal.handleRedirectPromise();
                //this.token =  this.$msal.getAllAccounts()[0].idToken;  
                
                const entraTokenRequest = {
                    scopes: [ "openid","email", "profile","User.Read"],
                    account: this.$msal.getAllAccounts()[0],
                };    
                const entraToken = await this.$msal.acquireTokenSilent(entraTokenRequest).then(tokenResponse => {
                    let newentraToken = tokenResponse.accessToken;
                    return newentraToken   
                })
                this.token = entraToken
                //const token = await this.$msal.getActiveAccount()
                if (this.$store.state.totalCount === null) {                 
                    await this.$store.dispatch('fetchCounts', this.token);
                }
                this.links[1].amount = this.financeCount;
                this.links[2].amount = this.procurementCount;
                this.links[3].amount = this.userAccessCount;
                this.links[4].amount = this.timeCount;
                this.links[5].amount = this.travelCount;                
                //this.links[5].amount = this.assetsCount;
                if (!this.$store.state.currencyFormat) {
                    await this.$store.dispatch('fetchPreferences', this.token);
                }
            }
            else if (this.userName != 'Logout') {
                this.authenticated = true;
                this.$msal.loginRedirect()
            }
            this.isLoading = false;
            
            
        //Notification    
         //this.todayDate = new Date().toJSON().slice(0,10).split('-').reverse().join('');
     const notifDate = await this.$axios.get("/notifications")
                        .then(response => {
                            return response.data;
                        });
        this.todayDate = notifDate;
        const typedCharacters = this.todayDate.Content.length;
        if (typedCharacters >= 200){
            this.notifContent += '<marquee>' + this.todayDate.Content + '</marquee>'
        }
        else{
            this.notifContent = this.todayDate.Content 
        }
        let currentDate = new Date();
        let notificationDate = new Date(this.todayDate.Time);
        if (notificationDate > currentDate){
            this.notifActive = true;
            this.navbarHeight = true;
        }
        else if(this.todayDate.Content == '' ){
            this.notifActive = false;
            this.navbarHeight = false;
        }
        else{
            this.notifActive = false;
            this.navbarHeight = false;
        }
        
    
        
    },

    
    methods: {

    //ReleaseNote     
     async releaseNote ()  { 
      const releaseData = await this.$axios.get("/releasenotes")
      .then(response => {
        return response.data;
      });
      this.releaseData = releaseData;
        if(this.releaseData.Title_New !=""){
            this.showNew = true;
        }
        if(this.releaseData.Title_Improvements !=""){
            this.showImprove =true;
        }
       if( this.releaseData.Title_Fix !=""){
            this.showFix = true;
        } 
     
       this.releaseDialog = true;
       this.showing = true;

     },
     
        enableBtn: function(){          
                this.isDisabled= false;                
        },
        
      
        close() {
            this.preferencesDialog = false; 
            this.preferences = false; 
            this.feedback = false;            
            this.$refs.menu.isActive = false;            
            this.rating=0;
            this.is_helpful = null;
            this.errors = [];
           
            this.btnDisabled = false,
            //this.$refs.form.reset(); 
            this.isDisabled= true;           
        },
        closeNotifi(){
            this.navbarHeight = false;
            this.notifActive = false; 
        },
        async home() {
            this.$router.push({path: '/'}).catch(()=>{});
            await this.$store.dispatch('fetchCounts');
        },
        myDxc () {  
            window.open("https://my.dxc.com/", "_blank");  
                },
        async savePreferences () {
            const newPrefs = { 
                dateFormat: this.datePreference ? this.datePreference : this.dateFormat, 
                currencyFormat: this.currencyPreference ? this.currencyPreference : this.currencyFormat
            }
            await this.$store.dispatch('updatePreferences', { newPrefs } )
                .then(async() => {
                    this.cardOverlay.pendingOverlay = false;
                    this.cardOverlay.successOverlay = true;
                    await new Promise(resolve => {
                        setTimeout(resolve, 1000);
                    })
                    this.cardOverlay.successOverlay = false;
                }).catch(async err => {
                    this.cardOverlay.pendingOverlay = false;
                    this.cardOverlay.failedOverlay = true;
                    await new Promise(resolve => {
                        setTimeout(resolve, 2000);
                    })
                    this.cardOverlay.failedOverlay = false;
                    console.log(err.message)
                })
            this.preferencesDialog = false;
            this.$refs.menu.isActive = false; 
        },
          //Feedback Attachment
          async onFileChange(event){
            this.file = event.target.files[0];            
                const reader = new FileReader();
                reader.readAsDataURL(this.file);
                reader.onload = e =>{
                    this.previewImage = e.target.result;                    
                };               
            this.fileUpload = true; 
            if(this.file.size <= 1024 * 1024 ){
                this.errors= '';
                this.btnDisabled = true;
            }
            else{
                this.btnDisabled = false;
                this.errors = ['File is too large size, Maximum file size is 1MB.']               
                this.errMsg = true;
            }   
        },
        
     
        async submitFeedback() {
           /* if (!this.$refs.form.validate()) {
                return
            }*/
            if (this.file != null){  
                this.attachment_url = `${this.baseUrl}/${this.fileName}.${this.fileExtension}`          
            }
            this.cardOverlay.pendingOverlay = true;
            const user = await this.$msal.getAllAccounts()[0];
            await this.$axios.post('/feedback/upload', {   
                ...this.userFeedback,attachment_url: this.attachment_url, base64Img: this.previewImage,fileName: this.fileName, rating:this.rating, is_helpful:this.is_helpful, userId: user.username, version: process.env.VUE_APP_VERSION }, {
                headers:{'Content-Type':'application/json'}
            })            
                .then(async() => {
                    this.cardOverlay.pendingOverlay = false;
                    this.cardOverlay.successOverlay = true;
                    await new Promise(resolve => {
                        setTimeout(resolve, 1000);
                    })
                    this.cardOverlay.successOverlay = false;
                }).catch(async err => {
                    this.cardOverlay.pendingOverlay = false;
                    this.cardOverlay.failedOverlay = true;
                    
                    await new Promise(resolve => {
                        setTimeout(resolve, 2000);
                    })
                    this.cardOverlay.failedOverlay = false;
                    this.isDisabled=true;
                    this.rating=0;
                    this.btnDisabled = false;
                    this.fileUpload = false;
                    console.log(err.message)
                })

            this.$refs.form.reset();
            this.preferencesDialog = false;
            this.$refs.menu.isActive = false;
            this.isDisabled=true;
            this.btnDisabled = false;
            this.rating=0;
            this.is_helpful = null;
            this.errors = [];
            this.userFeedback.issue_description =' ', 
            this.userFeedback.feedback_comment =' ',
            this.userFeedback.onboarding_request = ' ',
            this.file = null
        },
        
        async logout () {
            await this.$msal.logout();
            sessionStorage.removeItem("nsptatoken");
            //sessionStorage.setItem("nspta_isvaild", null);
            sessionStorage.removeItem("nspta_isvaild");
            //this.nsptasignout= false;
            this.userName = null
        },
        login() {
            this.$msal.loginRedirect()
        },
    },

}
</script>
<style>
header.v-app-bar.v-app-bar--fixed{
height: auto !important;
}
.notification-block{
    background-color: rgb(237, 155, 51);
    text-align: center;
    padding: 5px 10%;
    font-size: 12px;
    display: none;
    color: #000;
}
.notification-block.active{
    display: block;
}
.notification-block .v-icon.close-btn{
    font-size: 18px !important;
    color: #000;
    top: 3px !important;
    height: 20px !important;
}
nav{
    height: 105px;
}
nav.active{
    height: 132px;
}
.v-application .v-toolbar.purple{
    background-color: #5F249F !important;
    border-color: #5F249F !important;
    box-shadow: 0 1px 5px #D9D9D6 !important;
}
.v-sheet.v-footer{
    background-color: #ECEEF1;
}
.v-toolbar .v-toolbar__content{
    display: block;
    padding: 0;
    height: auto !important;
}
.mdi-menu{
    margin-left: 10px;      
}
.v-application .mr-2.shrink{
    margin-right: 12px !important;
}    
.v-toolbar__title{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}

.v-menu__content .theme--dark.v-list {
    background: #ECEEF1;
    color: #1D2833;
    padding: 20px 0 0;
}
.v-menu__content .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #1D2833 !important;
}
.v-menu__content .v-list-item--link .v-icon{
    color: #1D2833 !important;
  }
  body .v-application .primary {
    background-color: #5F249F !important;
  }
  body .v-application .primary--text {
    color: #5F249F !important;
  }
  .v-textarea.v-text-field .v-label{
    left:10px !important;
  }
  .username:hover{
    cursor: pointer;
  }
  .feedback{
    position: fixed;
    top: 50%;
    right: -68px;
    transform: rotate(270deg);
    z-index: 100;
  }
  .feedback span{
    display: block;
    background: linear-gradient(270deg, #6A3093 0%, #A044FF 100%);
    box-shadow: 0 40px 40px 0 rgb(255 255 255 / 90%);
    color: #FFFFFF;
    font-size: 18px;
    padding: 10px 10px 10px 10px;
    border: 0px;
    transition: transform .5s;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: 'Open Sans', sans-serif !important;
  }
  .feedback span:hover{
    transform: scale(1.1);
  }
  .feedback .theme--light.v-icon{
    color:#fff;
    transform: rotate(90deg);
    right: 0px;
    position: relative;
    font-size: 26px;
  }
  body .v-application .v-card__title{
    display: block ;
    text-align: center;
    text-transform: capitalize;
  }
  body .v-card__title .close-btn{
    background: none !important;
    border: 0;
    box-shadow: none;
    color: #fff;
    position: absolute;
    right: 15px;
    top: 10px;
    transition: 1s ease-in-out;
    font-size: 18px;
  }
    body .v-card__title .close-btn:hover{
        transform: rotate(180deg);
    }
    body .v-application .v-card__actions {
    display: block;
    padding: 10px 0 8px;
    text-align: center;
    }
    .v-textarea.theme--light.v-text-field > .v-input__control > .v-input__slot:before{
        border:0;
    }
    .theme--light.v-text-field .v-input__slot .v-label {
        top: 10px;
        background: #fff;
    }
    body .feedbackform {
        font-family: 'Open Sans', sans-serif !important;
    }
    body .feedbackform .v-textarea textarea {
        border: 1px solid #ccc;
        border-radius: 0;
        height:10vh;
        margin-bottom: 5px;
    }
    body .feedbackform .v-input.v-text-field {
        margin: 0;
        padding-top:0 ;
    }
    body .feedbackform .theme--light.v-label {
        font-size: 12px;
    }
    body .feedbackform .v-card__title{
        padding: 10px 15px;
        border-radius: 10px 10px 0 0;
    }
    body .v-dialog:not(.v-dialog--fullscreen) {
        max-height: 100%;
    }
    body .feedbackform .v-card__text {
        max-height: 438px;
        overflow-y: auto;
        padding: 0 10%;
    }
    body .feedbackform .v-card__text .v-input--selection-controls{
        margin-top: 5px;
        padding-top: 0;
    }
    body .feedbackform .v-card__title + .v-card__text {
        padding-top: 15px;
    }
    body .feedbackform .v-card__text p{
        margin: 5px 0px 4px;
        font-size: 12px;
        font-weight: 600;
    }
    .v-application .feedbackform p.feedbacktext{
        font-size: 12px;
        color: #63666a;
        margin-bottom: 5px;
        text-align: center;
        padding: 5px 5%;
        font-weight: 300;
    }
    .v-application .feedbackform p.feedbacknote{
        font-weight: bold;
        padding-bottom:20px ;
    }
    .v-application .v-rating .v-icon{
        padding: 2px;
        font-size: 26px !important;
    }
    .v-application .v-dialog{
        border-radius: 10px;
    }
    .feedbackform p.required::after{
        content: '*';
        color: #dc3545;
        margin: 1px;
        font-size: 14px;
        top: -4px;
    }
    .feedbackform .v-input__slot{
        margin-bottom: 0;
    }
    .feedbackform input[type="file"]{
        border:1px solid #ccc;
        box-sizing:border-box;
        display:inline-block;
        font-size:14px;
        margin:0 10px 0 0;
        position:relative;
        overflow:hidden;
        width:100%;
        z-index:2;
    }
.feedbackform input[type="file"]:after {
    background: #fff;
    content: 'Allowed file types : .png, .jpeg, .jpg and.pdf';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 106px;
    width: 60%;
    z-index: 11;
    padding: 4px 16px;
    font-size: 12px;
}
.feedbackform input[type="file"].active:after {
    display: none;
}
.feedbackform input[type="file"]::-webkit-file-upload-button {
    color: rgba(255, 255, 255, 0.87)!important;
    pointer-events: painted;
    background-color: #5F249F !important;
    font:inherit;
    height: 30px;
    min-width: 64px;
    padding: 0 16px;
    position:relative;
    border:none;
    text-align:left;
    position:relative;
    z-index:100;
    -webkit-appearance:none;
}
    .v-sheet.footer-link{
    background: #ccc;
    padding: 0;
    text-align: right;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
}
.footer-link .v-list-item{
    min-height: auto;
}
.footer-link .v-list-item__content{
  display: block;
      padding: 5px 0 0;
}
.footer-link .v-list-item__content .v-list-item__title{
  display: inline-block;
  margin: 0 5px 0 0;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0071428571em;
  cursor: pointer;
  color:#000;
}
.footer-link .v-list-item__content a{
    color:#000;
}
.footer-link .v-list-item__content .v-list-item__title span{
  display: inline-block;
}
.v-application .v-dialog.releaeblock .v-sheet.v-card .v-btn.close-btn {
    background: none !important;
    border: 0;
    box-shadow: none;
    color: #fff;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: 1s ease-in-out;
    height: auto;
    min-width: auto;
    padding: 0;
 }
.v-application .v-dialog.releaeblock .v-sheet.v-card .v-btn.close-btn:hover{
    transform: rotate(180deg);
    background: none !important;
}
.releaeblock .v-sheet.v-card .v-btn.close-btn .v-icon.v-icon{
    font-size: 22px;
 }
 .release-left{
    background: #5F249F;
    width: 100%;
    padding: 10px;
    color: #fff;
    text-align: center;
 }
 .v-application .release-left h4{
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
 }
.release-right{
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  text-align: left;
 }
.v-application .v-card .release-right h3{
  text-transform:capitalize;
  margin: 15px 0 5px;
  color: #000;
  font-weight: bold;
  font-size: 15px;
}
.v-application .v-card .release-right ul {
    padding-left: 20px;
}
.v-application .v-card .release-right ul li {
    font-size: 14px;
}
.v-application .v-card .release-right p{
  margin-bottom: 5px;
  font-size: 14px;
}
.v-application .v-dialog.releaeblock{
    overflow-y: visible;
}
.v-application .releaeblock .v-sheet.v-card{
    border-radius: 5px !important;
}
nav .footer-link .v-list-item__content .v-list-item__title span{
    border-bottom: 1.5px solid transparent;
    font-weight: 500;
}
nav .footer-link .v-list-item__content .v-list-item__title span:hover{
    border-color: #5F249F;
    color:#5F249F;
}
.v-application .v-card .release-right .v-data-table tr td{
    word-break: break-all;
}
body .feedbackform .v-card__text p.errMsg{
    color: green;
}
body .feedbackform .v-card__text p.errMsg.active{
    color: red;
}
body .feedbackform .uploadbtn{
    background-color: rgba(0, 0, 0, 0.12) !important;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.26) !important;
    height: 30px !important;
}
body .feedbackform .uploadbtn.active{
    color: rgba(255, 255, 255, 0.87)!important;
    pointer-events: painted;
    background-color: #5F249F !important;
}
</style>