<template>
  
      <v-container>
        <v-row>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Request Type</h4>
                <p class="apprInfo">{{cardData.requestType}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Pending State</h4>
                <p>{{cardData.pendingState}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Supplier</h4>
                <p>{{cardData.supplier}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Cost Center</h4>
                <p>{{cardData.costCenter}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Site Code</h4>
                <p>{{cardData.siteCode}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Location</h4>
                <p>{{cardData.location}}</p>
              </div>
            </v-container>
          </v-flex>
          
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Work Order Start date</h4>
                <p>{{showDate(cardData.workOrderStartDate)}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Work Order End Date</h4>
                <p>{{showDate(cardData.workOrderEndDate)}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Average Rate Used to Calculate Spending</h4>
                <p>{{cardData.avgRateUsedToCalcSpend}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Bill Rate ST Per Hour</h4>
                <p>{{cardData.billRateSTPerHour}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Bill Rate OT Per Hour</h4>
                <p>{{cardData.billRateOTPerHour}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Bill Rate DT Per Hour</h4>
                <p>{{cardData.billRateDTPerHour}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Spend to Date</h4>
                <p>{{cardData.spendToDate}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Cumlative Comitted Spend</h4>
                <p>{{showCurrency(cardData.cumulativeCommittedSpend)}} {{cardData.currency}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Committed Spend</h4>
                <p>{{showCurrency(cardData.committedSpend)}} {{cardData.currency}}</p>
              </div>
            </v-container>
          </v-flex>
        </v-row>
      </v-container>
     
</template>

<script>
export default {
  props: ['cardData'],
 methods: {
    showCurrency(amount) {        
        if (!amount) return ""
        return (
          this.$store.state.currencyFormat === "comma"
          ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
          : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
        )
      },
    showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },      
  }
}
</script>