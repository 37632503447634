<template>
  <div class="text-xs-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <v-badge
          v-on="on"
          overlap
          :content="attachments.length"
        >
        <v-icon :size="sizes.xLarge" @click="dialog = true">mdi-attachment</v-icon>
        </v-badge>
      </template>

      <v-card justify="center" align="center">
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Attachments 
        </v-card-title>
        <v-card-text v-for="link in attachments" :key="link.filename">
          {{link.filename}} {{link.contentLength/1024}}.kb 
          <v-btn
          v-on:click="getFile(link.id, link.filename)"
          text tile color="primary"
        ><b><u>Download</u></b></v-btn>
        /
          <v-btn
          v-on:click="showFile(link.id)"
          text tile color="primary"
        ><b><u>View</u></b></v-btn>
        <div>
        </div>
        </v-card-text>
        <clip-loader justify="center" align="center" v-if="loading === true" color="#00607A" height="20" width="20"></clip-loader>
            <div v-if="showing === true " style="width: 100%">
               <!-- <pdf v-if="showing === true" ref="pdf" style="border: 1px solid black" :src="src"></pdf>-->
                <iframe id="fred" style="border:1px solid #666CCC" title="PDF in an i-Frame" :src="src" frameborder="1" scrolling="auto" width="100%" height="600px" ></iframe>
                
                <div justify="center" align="center">
                <v-btn
                  v-on:click="showing = false"
                  text tile color="primary"
                  justify="center" align="center"
                ><b><u>clear</u></b></v-btn>
                </div>
            </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialog = false"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<!-- script start from here -->
<script>
import { sizes } from '@/use/sizes' 
import FileDownload from 'js-file-download';
//import pdf from 'vue-pdf'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

    export default {
    props: ['attachments','cardHeader', 'realm'],
    components: {
      //pdf: pdf,
      ClipLoader,
    },
    data () {
      return {
        sizes,
        publicPath: process.env.VUE_APP_API_URL,
        hover: false,
        dialog: false,
        showing: false,
        src:'',
        loading: false,
      }
    },
    created() {
      for (const attachment of this.attachments) {
        attachment.filename = attachment.filename || attachment.fileName;
      }
    },
    methods: {
      async getFile (attachmentId, filename) {
        const response  = await this.$axios.post('/procurement/attachments', {
          referenceId: this.cardHeader.referenceId,
          attachmentId,
          realm: this.realm,
          type: this.cardHeader.type
        }, 
        { responseType: 'arraybuffer'}).catch(err => console.log(err)) 

        FileDownload(response.data, filename);
      },
      async showFile (attachmentId) {
        this.loading = true;
        const response  = await this.$axios.post('/requests/attachments', {
          referenceId: this.cardHeader.referenceId,
          attachmentId,
          realm: this.realm,
          type: this.cardHeader.type
        }, 
        { responseType: 'arraybuffer'}) 
        let file = new Blob([response.data], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        this.showing = true;
        this.loading = false;
        this.src = fileURL;
      }
    }
  }
</script>