<template>
<v-card max-width="100%" class="mt-5">
        <v-row no-gutters>
          <v-col cols="8" xs='12' sm='12' md="7" lg="8">
            <v-container max-height="100%">
                <v-layout row wrap>
                      <Overlay :card="card" />
                  <v-flex  xs12 md4 lg4>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Invoice</h4>
                        <p>{{card.header.referenceId.replace(/[^-]*-/,"")}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md4 lg4>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Supplier Invoice #</h4>
                        <p>{{card.data[card.header.source+'Details'].supplierInvoiceId}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md4 lg4>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Supplier</h4>
                        <p>{{card.data[card.header.source+'Details'].supplierName}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md4 lg4>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Company Code</h4>
                        <p>{{card.data[card.header.source+'Details'].companyCode}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md4 lg4>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Created</h4>
                        <p>{{ showDate(card.header.sourceCreatedAt) }}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md4 lg4>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Days Outstanding</h4>
                        <p>{{Math.round((new Date() - new Date(card.header.availableForApprovalAt))/1000/60/60/24)}}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md4 lg4>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">System/Approval Type</h4>
                        <p>{{ card.header.source }} / {{ card.header.type }}</p>
                      </div>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md4 lg4>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">On behalf of</h4>
                        <!--<p class="apprInfo">{{card.header.requester.name ? card.header.requester.name : card.header.requester.id}}</p>-->
                        <p class="apprInfo">
                        
                        <span v-if="card.header.requester.id != 'private_user'"> {{card.header.requester ? card.header.requester.name : card.data[card.header.source+'Details'].requester ? card.data[card.header.source+'Details'].requester.id : 'NA'}}
                        </span>
                        <span v-else-if="card.header.requester.id === 'private_user' && card.data[card.header.source+'Details'].approvalFlow.groupName == null "> {{card.header.requester ? card.header.requester.name : card.data[card.header.source+'Details'].requester ? card.data[card.header.source+'Details'].requester.id : 'NA'}}
                        </span>                         
                        <span v-else>  {{card.data[card.header.source+'Details'].approvalFlow.groupName }}</span>
                      </p>  
                        </div>
                    </v-container>
                  </v-flex>
                  <v-flex xs12 md4 lg4>
                    <v-container>
                      <div>
                        <h4 class="apprHeader">Total Amount</h4>
                        <p><b>{{showCurrency(card.data[card.header.source+'Details'].amount)}} {{card.data[card.header.source+'Details'].amountCurrency}}</b></p>
                      </div>
                    </v-container>
                  </v-flex>
              </v-layout>
            </v-container>
          </v-col>
          <v-col cols="12" sm='12' md="5" lg="4" align="center" justify="center">
              <v-container style="backgroundColor: rgba(0, 121, 153, 0.04)" fill-height>
                <v-col cols="12" sm="12" md="12"
                          class="flex-grow-0 flex-shrink-1"
                          justify="center" align="center">
                      <v-row  justify="center" align="center">
                            <v-btn
                              v-if="card.delegated"
                              color="primary"
                              class="custom-btn delegated-btn"
                              dark
                              large
                              text
                              v-bind="attrs"
                              v-on="on"
                            >
                              DELEGATED
                            </v-btn>
                      </v-row >
                    
                <v-card-actions class="fixIeFormatting">
                  <v-row justify="center" align="center">
                    <v-col style="padding:0 20px" v-if="card.header.source === 'ezbuy' && card.header.type === 'invoices'">                      
                    <Review :card="card" @reviewed="review" />
                    </v-col>
                    <v-col cols="12" sm="12" md="12"
                      style="min-width: 100px;"
                          class="flex-grow-0 flex-shrink-1"
                          justify="center" align="center">
                      <v-row  justify="center" align="center">
                        <v-col sm='12' md="5" lg="5">
                          <v-btn text @click="show = !show" style="color: #00607A">
                            Details
                            <v-icon>
                              {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-btn>
                        
                        </v-col>
                          <v-divider vertical class="vDivider"></v-divider>
                        <v-col
                          sm='12' md="5" lg="5"
                        >
                          <v-btn text class="pa-2" style="color: #00607A" target="_blank" :href="card.header.sourceLink">
                            Go To App
                            <v-icon>
                              mdi-launch
                            </v-icon>
                          </v-btn>
                          <attachmentModal v-if="card.data[card.header.source+'Details'].attachments" class="pt-1" :attachments="card.data[card.header.source+'Details'].attachments" :cardHeader="card.header" :realm="card.data[card.header.source+'Details'].realm"></attachmentModal>
                        </v-col>
                      </v-row >
                    </v-col>
                  </v-row>
                </v-card-actions>
                </v-col>
              </v-container>
          </v-col>
        </v-row>
        
          <v-expand-transition>
            <div v-show="show" class="grey lighten-5">
              <v-divider horizontal></v-divider>
                <v-layout row wrap justify-end>
                  
                  <v-flex shrink style="padding-right: 70px; padding-top: 10px">
                    <v-btn icon @click="show = null">
                      <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>

                <v-container
                  id="scroll-target"
                  style="max-height: 420px"
                  class="overflow-y-auto"
                >
                <h3 class="content-title">Details</h3>
                <ProcurementDetails :items="card.data[card.header.source+'Details'].items" :header="card.header"></ProcurementDetails>
                <br/>
                <h3 class="content-title">Approval Flow</h3>
                <ProcurementApprovals :table="card.data[card.header.source+'Details'].approvalFlow"></ProcurementApprovals>
                <br/>
                <h3 class="content-title">Comments</h3>
                <ProcurementComments :comments="card.header.priorComment"></ProcurementComments>
              </v-container>
            </div>
          </v-expand-transition>
        </v-card>
</template>

<script>
import Overlay from '../viewComponents/Overlay';
import ProcurementDetails from '../procurementCard/ProcurementDetails';
import ProcurementApprovals from '../procurementCard/ProcurementApprovals'
import ProcurementComments from '../procurementCard/ProcurementComments';
import Review from '../viewComponents/Review';
import attachmentModal from '../viewComponents/attachmentModal';

export default {
  components: {
    attachmentModal,
    Overlay,
    ProcurementDetails,
    ProcurementApprovals,
    ProcurementComments,
    Review
  },
  props: ['card'],
  data() {
    return {
      show: null,
      absolute: true,
    }
  },
 
 
  methods: {
    
    showCurrency(amount) {
        if (!amount) return ""
        return (
          this.$store.state.currencyFormat === "comma"
          ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
          : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
        )
      },
      showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },
      review(card){
        this.$emit('reviewed', card);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .custom-btn::before {
    color: transparent
  }
  .custom-btn:hover {
      color: white;
      cursor: default;
  }
  .detailsTitle {
    margin-left: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin-top: 10px;
  }
.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
    margin-left: 15.5px;
    margin-right: 40px;
    max-width: 100%;
} 
.theme--light.v-btn.v-btn--outlined{
    border-width: 2px;
    border-color: black;
}
</style>