<template>
  <div>
  
            <v-radio
              v-for="reason in reasondata"
              :key="reason.id"
              :label="`${reason.description}`"
              :value="reason.id"
            ></v-radio>
  </div>
  
</template>

<script>
export default {
  props: ['cardData', 'cardDatatime', 'headertype','cardDatajobposting'],
   data() {
    return {
      reasondata: [] 
    };
  }, 
  async mounted () {
    if(this.headertype.type === 'workOrder'){
      const moduleId = this.cardData.moduleId;
      const reasondata = await this.$axios.get("/fieldglasses/reason/" + moduleId)
      .then(response => {
        return response.data;
      });
       this.reasondata = reasondata;       
    }
    else if(this.headertype.type === 'jobPosting'){
      const moduleIdjobposting = this.cardDatajobposting.moduleId;
      const reasondata = await this.$axios.get("/fieldglasses/reason/" + moduleIdjobposting)
      .then(response => {
        return response.data;
      });
       this.reasondata = reasondata;       
    }
    else{
      const moduleIdtime = this.cardDatatime.moduleId;
      const reasondata = await this.$axios.get("/fieldglasses/reason/" + moduleIdtime)
      .then(response => {
        return response.data;
      });
      this.reasondata = reasondata;      
    }
  }
  
}
</script>
