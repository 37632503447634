<template>
  <v-row >
    <v-col sm="12">
      <v-container>
        <v-row>
        
           <v-flex xs12 md4 lg2>
            <v-container>
              <div>
                <h4 class="apprHeader">Accounts</h4>
                <div class="scrolling accounts-scroll">
                  <div v-for="item in cardData.accounts" :key="item.id">{{item}}</div>
                </div>  
                 
              </div>
            </v-container>
          </v-flex>
           
          <v-flex xs12 md4 lg3> 
            <v-container>
              <div>
                <h4 class="apprHeader">Group Identifier</h4>
                <p>{{cardData.groupIdentifier}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Group Name</h4>
                <p>{{cardData.group}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg4>
            <v-container>
              <div>
                <h4 class="apprHeader">Assign Hosts</h4>
                <div class="scrolling">
                  <div v-for="item in cardData.assignHosts" :key="item.id">{{item}}</div>
                </div>
                 
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg2>
            <v-container>
              <div>
                <h4 class="apprHeader">Ticket</h4>
                <p>{{cardData.ticket}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              
                <h4 class="apprHeader">Commands</h4>
                <div class="scrolling">
                  <div v-for="item in cardData.commands" :key="item.id">{{item.replace(/,/,"")}}</div>
                </div>
              
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Permission</h4>
                <div class="scrolling">
                  <div v-for="item in cardData.permission" :key="item.id">{{item}}</div>
                </div>
               
              </div>
            </v-container>
          </v-flex>
           <v-flex xs12 md4 lg4>
            <v-container>
              <div v-if="header.type === 'Group Member'">
                <h4 class="apprHeader">Domain</h4>
                <p>{{cardData.domain}}</p> 
               </div>
              <div v-else>
                <h4 class="apprHeader">Application</h4>
                <p>{{cardData.application}}</p>                
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg11>
            <v-container>
              <div>
                <h4 class="apprHeader">Business Justification</h4>
                <p>{{cardData.businessJustification}}</p>
              </div>
            </v-container>
          </v-flex>
        </v-row>
      </v-container>
      </v-col>
    </v-row>
</template>

<script>
export default {
  props: ['cardData', 'card', 'header'],
}
</script>
<style scoped>
.scrolling{
  max-height: 64px;
  overflow: auto;
  width: 90%;
  font-size: 14px;
}
.v-application .vsm-block p{
    margin-bottom: 5px !important;
    font-size: 14px !important;
  }

</style>