<template>
  <v-row >
    <v-col sm="12">
      <v-container>
        <v-row>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Request Type</h4>
                <p class="apprInfo">{{cardData.requestType}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Title</h4>
                <p>{{header.title}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">ERB ID</h4>
                <p>{{cardData.eprId}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">ARB ID</h4>
                <p>{{cardData.arbId}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Description</h4>
                <p>{{cardData.description}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Business Justification</h4>
                <p>{{cardData.justification}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Subscription Type</h4>
                <p>{{cardData.subscriptiontype}}</p>
              </div>
            </v-container>
          </v-flex>
        </v-row>
         <h3 class="content-title">Source</h3>
         <v-row>
                    <v-flex xs12 md4 lg3>
                        <v-container>
                          <div>
                            <h4 class="apprHeader">Data Object</h4>
                            <p>{{cardData.dataObjectName}}</p>
                          </div>
                        </v-container>
                      </v-flex>
                      <v-flex xs12 md4 lg3>
                        <v-container>
                          <div>
                            <h4 class="apprHeader">Data Source</h4>
                            <p>{{cardData.dataSourceName}}</p>
                          </div>
                        </v-container>
                      </v-flex>
                      <v-flex xs12 md4 lg3>
                        <v-container>
                          <div>
                            <h4 class="apprHeader">Data Object Group</h4>
                            <p>{{cardData.dataObjectGroupName}}</p>
                          </div>
                        </v-container>
                      </v-flex>
                    </v-row>
                    <h3 class="content-title">Members</h3>
                    <v-row>
                      <v-flex xs12 md4 lg3>
                        <v-container>
                          <div>
                            <h4 class="apprHeader">User Members:</h4>
                              
                              <p v-for="memberlist in cardData.members" :key="memberlist.id">
                                <span v-if="memberlist.type === 'User'">{{memberlist.name}}</span>
                                </p>
                            
                          </div>
                        </v-container>
                      </v-flex>
                      <v-flex xs12 md4 lg3>
                        <v-container>
                          <div>
                            <h4 class="apprHeader">Admin Members:</h4>
                            <p v-for="memberlist in cardData.members" :key="memberlist.id">
                                <span v-if="memberlist.type === 'Admin'">{{memberlist.name}}</span>
                                </p>
                          </div>
                        </v-container>
                      </v-flex>
                      
                    </v-row>
                  
                
      </v-container>
      </v-col>
    </v-row>
</template>

<script>
export default {
  props: ['cardData', 'header'],
}
</script>
<style scoped>
.content-title{
      margin: 10px 0;
      background: #ccc;
      padding: 5px;
      font-size: 16px;
}
</style>