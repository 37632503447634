<template>
  <ul class="pagination">
  <li class="pagination-item">
      <a title="First page" href="#" @click.prevent="onClickFirstPage" :class="isInFirstPage? 'disabled':''" :disabled="isInFirstPage"><i class="mdi mdi-chevron-double-left"></i></a>
    </li>
    <li class="pagination-item">
      <a href="#" @click.prevent="onClickPreviousPage" :class="isInFirstPage? 'disabled':''" :disabled="isInFirstPage"><i class="mdi mdi-menu-left"></i></a>
    </li>
    <li :key="page.id" v-for="page in pages" class="pagination-item">
      <a href="#" @click.prevent="onClickPage(page.name)" :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name) }">{{ page.name }}</a>
    </li>
    <li class="pagination-item">
      <a href="#" @click.prevent="onClickNextPage" :class="isInLastPage? 'disabled':''" :disabled="isInLastPage"><i class="mdi mdi-menu-right"></i></a>
    </li>
  <li class="pagination-item">
      <a title="Last page" href="#" @click.prevent="onClickLastPage" :class="isInLastPage? 'disabled':''" :disabled="isInLastPage"><i class="mdi mdi-chevron-double-right"></i> </a>
    </li> 
  </ul>
</template>

<script>
export default {
  name: 'pagination',
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5
    },
    totalPages: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    isInFirstPage () {
      return this.currentPage === 1
    },
    isInLastPage () {
      if (this.totalPages === 0) {
        return true
      }
      return this.currentPage === this.totalPages
    },
    startPage () {
      // When on the first page
      if (this.currentPage === 1) {
        return 1
      }
      // When on the last page
      if (this.totalPages < this.maxVisibleButtons) {
        return 1
      }
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1
      }
      // When in between
      //console.log("current page",this.currentPage);
      return this.currentPage - 1
    },
    endPage () {
      if (this.totalPages === 0) {
        return 1
      }
      if (this.totalPages < this.maxVisibleButtons) {
        return this.totalPages
      }
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages)
    },
    pages () {
      const range = []
      for (let i = this.startPage; i <= this.endPage; i++) {
        range.push({
          name: i,        
          isDisabled: i === this.currentPage
          
        })
      }
      return range
    }
  },
  methods: {
    onClickFirstPage () {
      if (this.isInFirstPage) {
        return false
      }
      this.$emit('pagechanged', 1)
    },
    onClickPreviousPage () {
      if (this.isInFirstPage) {
        return false
      }
      this.$emit('pagechanged', this.currentPage - 1)
    },
    onClickPage (page) {
      this.$emit('pagechanged', page)
    },
    onClickNextPage () {
      if (this.isInLastPage) {
        return false
      }
      this.$emit('pagechanged', this.currentPage + 1)
    },
    onClickLastPage () {
      console.log('onClickLastPage')
      if (this.isInLastPage) {
        return false
      }
      this.$emit('pagechanged', this.totalPages)
    },
    isPageActive (page) {
      return this.currentPage === page
    }
  }
}
</script>
<style lang="scss" scoped>
  .pagination {
    list-style-type: none;
    margin: 10px 0;
    .pagination-item {
      display: inline-block;
      color: #ddd;
      a {
        text-decoration: none;
        color: #2c3e50;
        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        height: 32px;
        width: 32px;
        margin: 0.3rem 10px;
        display: block;
        line-height: 32px;        
      }
      a.disabled {
        color: #ccc;
        cursor: no-drop;
      }
      .active {
        background-color: #5f249f!important;
        color: #ffffff !important;
        font-weight: bold;
        border-color: #1976d2 !important;
        box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
      }
    }
  }
  button[disabled], html input[disabled] {
    cursor: default;
    color: lightgray;
  }

</style>