<template>
  <v-card max-width="100%" class="mt-5 timeoff">
    <v-row no-gutters style="border-bottom:1px solid #ddd;">
      <v-col cols="9" xs='12' sm='12' md="7" lg="9">
        <v-container max-height="100%">
            <v-layout row wrap>
              <Overlay :card="card" />
              <v-flex xs12 md4 lg4>
                <v-container>
                  <div >
                    <h4 class="apprHeader">Employee</h4>
                    <p class="apprInfo">{{card.data.repliconDetails.userName}}</p>
                  </div>
                   
                </v-container>
              </v-flex>
              <v-flex xs12 md4 lg2>
                <v-container>                 
                   <div>
                    <h4 class="apprHeader">Time Off Type</h4>
                    <p>{{card.data.repliconDetails.timeOffType}}</p>
                  </div>
                </v-container>
              </v-flex> 
              <v-flex xs12 md4 lg2>
                <v-container>                 
                   <div>
                    <h4 class="apprHeader">Time Off Hours</h4>
                    <p>{{card.data.repliconDetails.timeOffHours}}</p>
                  </div>
                </v-container>
              </v-flex>  
              <v-flex xs12 md4 lg2>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Start Date</h4>
                    <p>{{ showDate(card.data.repliconDetails.leaveStartDate) }}</p>
                  </div>
              
                </v-container>
              </v-flex>
              <v-flex xs12 md4 lg2>
                <v-container>
                  <div>
                    <h4 class="apprHeader">End Date</h4>
                    <p>{{ showDate(card.data.repliconDetails.leaveEndDate) }}</p>
                  </div>
                </v-container>
              </v-flex>
              
          </v-layout>
        </v-container>
      </v-col>
      <v-col cols="12" sm='12' md="5" lg="3" align="center" justify="center">
          <v-container style="backgroundColor: rgba(0, 121, 153, 0.04)" fill-height>
            <v-card-actions class="fixIeFormatting">
              
                <v-col cols="12" sm="12" md="12"
                  style="min-width: 100px;"
                      class="flex-grow-0 flex-shrink-1"
                      justify="center" align="center">
                  <v-row  justify="center" align="center">
                   <Review :card="card" @reviewed="review" />                  
                   <!--  <v-col
                      sm='12' md="5" lg="5"
                    >
                     <v-btn text class="pa-2" style="color: #00607A" target="_blank" :href="card.header.sourceLink">
                        Go To App
                        <v-icon>
                          mdi-launch
                        </v-icon>
                      </v-btn> 
                    </v-col>-->              
                  </v-row >
                </v-col>
             
            </v-card-actions>
          </v-container>
      </v-col>
    </v-row>

      
    </v-card>
</template>

<script>
import Overlay from '../viewComponents/Overlay'
import Review from '../viewComponents/Review'

export default {
  props: ['card'],
  components: {
    Overlay,
    Review
  },
  data() {
   return  {
      show: null,
    }
  },

methods: {
    showCurrency(amount) {
        if (!amount) return ""
        return (
          this.$store.state.currencyFormat === "comma"
          ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
          : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
        )
      },
    showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },
      review(card){
        this.$emit('reviewed', card);
      },
      
  }
}
</script>
<style>
.timeoff .layout .flex .container{
  padding: 0 12px;
}
body .v-application .timeoff .v-card__actions{
      padding: 0;
}
body .v-application .timeoff .v-card__actions .col{
      padding: 0;
}
body .timeOff .theme--light.v-btn{
  margin-right: 0 !important;
}
.v-application .timeoff.v-card h4{
  font-size: 13px;
}
.v-application .timeoff.v-card p{
  font-size: 12px;
}
</style>