<template>

  <v-card max-width="100%" class="mt-5 vsm-block">
     <loading :active.sync="isLoading" 
      :is-full-page="fullPage">
    </loading>
    <v-row no-gutters>
      <v-col cols="8" xs='12' sm='12' md="7" lg="8">
        <v-container max-height="100%">
            <v-layout row wrap>
               <Overlay :card="card" />
              <v-flex xs12 md4 lg4>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Request ID</h4>
                    <p class="apprInfo">{{card.header.referenceId.replace(/[^-]*-/,"")}}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md4 lg4>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Created</h4>
                    <p>{{showDate(card.header.sourceCreatedAt)}}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md4 lg4>
                <v-container>
                  <div>
                    <h4 class="apprHeader">Days Outstanding</h4>
                    <p>{{Math.round((new Date() - new Date(card.header.availableForApprovalAt))/1000/60/60/24)}}</p>
                  </div>
                </v-container>
              </v-flex>
              <v-flex xs12 md4 lg4>
                <v-container>
                  <div>
                    <h4 class="apprHeader">System/Approval Type</h4>
                    <p>{{ `${card.header.source} / ${card.header.type}`}}</p>
                  </div>
                </v-container>
              </v-flex>              
              <v-flex xs12 md4 lg4>
              <v-container>
                  <div>
                    <h4 class="apprHeader">Requester</h4>
                    <p>{{card.data[card.header.source.toLowerCase()+'Details'].requesterName}}</p>
                   
                  </div>
                </v-container>
              </v-flex>
               <v-flex xs12 md4 lg4>
              <v-container>
                  <div>
                    <h4 class="apprHeader">Requester Email</h4>
                    <p>{{card.data[card.header.source.toLowerCase()+'Details'].requesterEmail}}</p>
                   
                  </div>
                </v-container>
              </v-flex>
          </v-layout>
        </v-container>
      </v-col> 
      <v-col cols="12" sm='12' md="5" lg="4" align="center" justify="center">
          <v-container style="backgroundColor: rgba(0, 121, 153, 0.04)" fill-height>
             <v-col cols="12" sm="12" md="12"
                          class="flex-grow-0 flex-shrink-1"
                          justify="center" align="center">
                      <v-row  justify="center" align="center">
                            <v-btn
                              v-if="card.delegated"
                              color="primary"
                              class="custom-btn delegated-btn"
                              dark
                              large
                              text
                              
                            >
                              DELEGATED
                            </v-btn>
                      </v-row >
                    </v-col>
            
            <v-card-actions class="fixIeFormatting">
              <v-row align="center" justify="center">
                <Review :card="card"  @reviewed="review" />
                <v-col cols="12" sm="12" md="12"
                  style="min-width: 100px;"
                      class="flex-grow-0 flex-shrink-1"
                      justify="center" align="center">
                  <v-row  justify="center" align="center">
                    <v-col sm='12' md="5" lg="5">
                      <v-btn text @click="show = !show" style="color: #00607A">
                        Details
                        <v-icon>
                          {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                      </v-btn>
                    
                    </v-col>
                      <v-divider vertical class="vDivider"></v-divider>
                    <v-col
                      sm='12' md="5" lg="5"
                    >
                     <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                      <v-btn dark v-bind="attrs" v-on="on" text class="pa-2" style="color: #00607A" target="_blank" :href="card.header.sourceLink">
                        Go To App
                        <v-icon>
                          mdi-launch
                        </v-icon>
                      </v-btn>
                      </template>
                      <span style="font-size:11px;">Pulse secure connection required</span>
                     </v-tooltip>
                    </v-col>
                  </v-row >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-container>
      </v-col>
    </v-row>

      <v-expand-transition>
        <div v-show="show" class="grey lighten-5">
          <v-divider horizontal></v-divider>
          <v-container style="padding-bottom: 0;">
          <v-layout row wrap justify-end>
           
            <v-flex shrink style="padding-right: 70px; padding-top: 10px">
              <v-btn icon @click="show = null">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          </v-container>
          <v-container
            id="scroll-target"
            style="max-height: 420px"
            class="overflow-y-auto"
          >
            <v-card outlined>
              <v-col cols=12>
                 <h3 class="content-title">Details</h3>
                <VsmDetails v-if="card.data" :cardData="card.data[card.header.source.toLowerCase()+'Details']" :header="card.header"></VsmDetails>
              </v-col>  
               <div class="approval-flow-block">
                <h3 class="content-title">Approval Flow </h3>
                <VsmApprovalflow :table="card.data[card.header.source.toLowerCase()+'Details']"></VsmApprovalflow>
                 
              </div>
            </v-card>
          </v-container>  
        </div>
      </v-expand-transition>
    </v-card>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VsmDetails from '../useraccessCard/VsmDetails';
import VsmApprovalflow from '../useraccessCard/VsmApprovalflow';
import Review from '../viewComponents/Review';
import Overlay from '../viewComponents/Overlay';


export default {
  props: ['card'],
  components: {
    VsmDetails,
    Review,
    Loading,
    Overlay,
    VsmApprovalflow
  },
  data() {
   return  {
      show: null,
      isLoading: false,
      fullPage: true,
      cardOverlay: {approvalOverlay: false, rejectedOverlay: false, failedOverlay: false, pendingOverlay: false},
      
    }
  },
  async mounted(){
    return {...this.cardOverlay, commentDialog: null, reviewType: ''}
  },
  methods: {
    
    showCurrency(obj, category) {
      console.log(this.card);
        const amount = obj.header ? obj.header[category] : obj[category];
        if (!amount) return ""
        return (
          this.currencyFormat === "comma"
          ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
          : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
        )
        
      },
      showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },
      async review(card){
         this.$emit('reviewed', card)
       }
  }
}
</script>

<style lang='scss' scoped>
 .v-application .vsm-block p{
    margin-bottom: 5px !important;
    font-size: 14px !important;
  }
  .approval-flow-block{
    padding:0 0 20px ;
  }
  .approval-flow-block h3{
      padding: 0 0 5px;
    border-bottom: 2px solid #ccc;
    margin: 10px;
    text-transform: uppercase;
  }</style>