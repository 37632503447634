<template>
      <v-container>
        <v-row>
          <v-flex xs12 md4 lg12>
            <v-container>
              <div>
                <h4 class="apprHeader">Location</h4>
                <p class="apprInfo">{{cardData.location}} </p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Estimated Spend</h4>
                <p>{{showCurrency(cardData.estimatedSpend)}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">WMA ID</h4>
                <p>{{cardData.wpnId}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Cost Center</h4>
                <p>{{cardData.costCenter}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Allocation%</h4>
                <p>{{cardData.percentageAllocation}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Reason Postion Needed</h4>
                <p>{{cardData.reasonpositionNeeded}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg4>
            <v-container>
              <div>
                <h4 class="apprHeader">Expected Payable DT Hours/Week</h4>
                <p>{{cardData.expectedpayableDThoursperweek}}</p>
              </div>
            </v-container>
          </v-flex>
        <v-flex xs12 md4 lg4>
            <v-container>
              <div>
                <h4 class="apprHeader">Expected Payable OT Hours/Week</h4>
                <p>{{cardData.expectedpayableOThoursperweek}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg12>
            <v-container>
              <div>
                <h4 class="apprHeader">Description</h4>
                <p>{{cardData.description}}</p>
              </div>
            </v-container>
          </v-flex>
          </v-row>
      </v-container>
</template>
<script>
export default {
  props: ['cardData', 'header'],
 methods: {
    showCurrency(amount) {
        if (!amount) return ""
        return (
          this.$store.state.currencyFormat === "comma"
          ? amount.toLocaleString("fr-FR", {minimumFractionDigits: 2}) 
          : amount.toLocaleString("en-US", {minimumFractionDigits: 2})
        )
      }
 }
 }  
</script>
