<template>
    <v-card max-width="100%" :elevation="4" style="padding: 10px; padding-bottom: 0px">
        <v-row>
            <v-col xs="3" sm="2" md="1" lg="7" style="padding-bottom:0">
                <v-img :src="imgSrc" contain min-height="50" min-width="80" max-height="80" max-width="80" style="right: 10px;top:-10px; float: left;"></v-img>
                <span class="pageTitle">{{ title }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col xs="3" sm="3" md="3" style="padding-bottom:0">
                      <v-text-field
                      ref="inputRef"
                    outlined
                    clearable
                    label="Search"
                    v-on:keyup.enter="sendMessage"
                    v-model="searchInput"
                    :append-outer-icon="searchInput ? 'mdi-send' : 'mdi-magnify'"
                    @click:append-outer="sendMessage"
                    @click:clear="clearMessage"
                    clear-icon="mdi-close-circle"
                    class="mt-5 page-search"
                    style="width: 350px"
                ></v-text-field>           
            </v-col>
        </v-row>
        <v-row wrap no-gutters>
            <v-col cols="12" sm='12' md="5">
                <v-btn text style="color: #5f249f"   v-on:click='back()'>
                    <v-icon>
                        mdi-chevron-left
                    </v-icon>
                    Back
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<!--script start from here -->
<script>
export default {
    props: ['title', 'imgSrc', 'status'],
    data() {
        return {
            alert: true,
            searchInput: '',
            clearable: false,     
            card:[],     
        };
    }, 
    created() {
        setTimeout(() => {
            this.alert = false
        }, 5000)
    },
    methods: {
        async back(){
            this.$router.push({ path: "/" });
            //this.$router.go(-1);
           await this.$store.dispatch('fetchCounts');           
        },
        typing (){
            this.$emit("searchParam", this.searchInput)
        },
        searchReset (params){
            if(params.trim().length === 0) {
            this.searchInput = '';
            }
            return this.$store.state.searchClear;
        },
        sendMessage () {
            this.$emit("searchParam", this.searchInput.trim());
            //this.clearMessage()
        },
        clearMessage () {
            this.searchInput = '';           
            this.$emit("searchParam", this.searchInput);
            return this.$store.state.searchClear;
        }
    }
}
</script>
<style>
    .pageTitle{
        /*position: absolute; */
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        color: #000000;
        top: 22px;
        left: 92px;
        float: left;
        margin: -15px 0 0;
    }
    .v-application .mt-5.page-search{
        margin: 0 !important;
    }
    .v-application .page-search .v-input__slot{
        min-height: 40px !important;
        margin: 0;
    }
    .page-search.v-text-field--outlined .v-label{
        top: 10px;
    }
    .v-application--is-ltr .v-text-field .v-input__append-inner{
        margin-top: 8px;
    }
    .v-text-field--outlined .v-input__append-outer{
            margin-top: 8px;
    }
    .page-search .v-text-field__details{
        margin: 0 !important;
        min-height: auto;
    }
    .page-search .v-messages{
        min-height: auto;
    }
    .v-input__append-outer .mdi-send{
          display: none;  
    }
    .hide{
    display: none;
    }
</style>