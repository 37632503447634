<template>
  <v-row >
    <v-col sm="12">
      <v-container>
        <v-row>
          <v-flex xs12 md4 lg2>
            <v-container>
              <div>
                <h4 class="apprHeader">Request Type</h4>
                <p class="apprInfo">{{cardData.assetInfo.request_type}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg4>
            <v-container>
              <div>
                <h4 class="apprHeader">Description</h4>
                <p>{{cardData.cat_item.description}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Reason for Approval</h4>
                <p>{{cardData.assetInfo.json_storage.request_context}}</p>
              </div>
            </v-container>
          </v-flex>          
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Priority</h4>
                <p>{{cardData.priority}}</p>
              </div>
            </v-container>
          </v-flex>
        
          <v-flex xs12 md4 lg2>
            <v-container>
              <div>
                <h4 class="apprHeader">Worker Type</h4>
                <p>{{cardData.assetInfo.employee_type}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg4>
            <v-container>
              <div>
                <h4 class="apprHeader">Requested Device</h4>
                <p>{{cardData.assetInfo.new_asset}}</p>
              </div>
            </v-container>
          </v-flex>
         
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Stage</h4>
                <p>{{cardData.stage}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Availability</h4>
                <p>{{cardData.cat_item.availability}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg2>
            <v-container>
              <div>
                <h4 class="apprHeader">Cost Center</h4>
                <p>{{cardData.assetInfo.cost_center}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg4>
            <v-container>
              <div>
                <h4 class="apprHeader">Shipping Address</h4>
                <p>{{cardData.assetInfo.delivery_address}}</p>
              </div>
            </v-container>
          </v-flex>
          
          <v-flex xs12 md4 lg4>
            <v-container>
              <div>
                <h4 class="apprHeader">Comments</h4>
                <p v-html="cardData.comments.replace(/(?:\r\n|\r|\n)/g, '<br />')"></p>
              </div>
            </v-container>
          </v-flex>
           <!-- <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">WBS</h4>
                <p>{{cardData.WBS}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Asset Model</h4>
                <p>{{cardData.assetInfo.new_asset_model}}</p>
              </div>
            </v-container>
          </v-flex>
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">New Asset Shelf</h4>
                <p>{{cardData.assetInfo.new_asset_shelf}}</p>
              </div>
            </v-container>
          </v-flex>
       
          <v-flex xs12 md4 lg3>
            <v-container>
              <div>
                <h4 class="apprHeader">Asset Model Type</h4>
                <p>{{cardData.assetInfo.new_asset_model}}</p>
              </div>
            </v-container>
          </v-flex>-->
      
        </v-row>
        <v-row no-gutters  style="display:none;">
    <v-col cols="12" sm="12">
      <h3 class="content-title" style="font-size:16px; margin:10px 0" >Category Items  
        <span small @click="viewJson()" style=" font-size: 12px; color: #5F249F !important; cursor: pointer; padding: 0 10px;">
                <v-icon
                  small
                >
                  mdi-code-json
                </v-icon>
                View JSON
              </span>  
      </h3>
     
            <v-dialog scrollable persistent content-class="releaeblock" v-model="uptimejsonDialog" >
              <v-card>
                <div class="release-left">
                  <h4>Source JSON</h4> 
                </div>
                <div  class="release-right" >
                  <pre style="overflow: auto; font-size: 12px;">{{malformed}} </pre>
                </div>
                <v-btn class="close-btn" @click="uptimejsonDialog = false;">
                 <v-icon>mdi-window-close</v-icon>
               </v-btn>     
              </v-card>
            </v-dialog>
        <v-simple-table fixed-header outline >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Quantity</th>
                <th class="text-left">Price</th>
                <th class="text-left">Description</th>
                <th class="text-left">Availability</th>
                <th class="text-left">Delivery Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{cardData.cat_item.name}}</td>
                <td>{{cardData.cat_item.quantity}}</td>
                <td>{{cardData.cat_item.price}}</td>                
                <td>{{cardData.cat_item.description}}</td>
                <td>{{cardData.cat_item.availability}}</td> 
                <td>{{cardData.cat_item.delivery_time}}</td>  
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        
     </v-col>
  </v-row>
      </v-container>
      </v-col>
    </v-row>
</template>

<script>
export default {
  props: ['cardData', 'requestee'],

data() {
    return {
      uptimejsonDialog: null,
      malformed: null,

    }
  },
  methods: {
    viewJson() {
        this.uptimejsonDialog = true;
        this.malformed = this.cardData.assetInfo.json_storage;
        var formatedJson = JSON.parse(this.malformed);
        this.malformed = formatedJson;
      },
  }
}
</script>