<template>
  <v-row >
    <v-col sm="12">
      
      <v-container>
        <h3 class="content-title">Approval Flow</h3>
        
        <v-simple-table fixed-header >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Approver</th>
         <!-- <th class="text-left">Approver level</th>-->
          <th class="text-left">Status</th>         
        </tr>
      </thead>
      <tbody  v-for="lmApproval in cardData.approvalFlow" :key="lmApproval.id">
        <tr  v-for="lmApprovals in lmApproval.approvers" :key="lmApprovals.id">
          <td >{{lmApprovals.name}}</td> 
          <!--<td >{{lmApproval.approverLevel}}</td> --> 
          <td >{{lmApproval.status}}</td>  
        </tr>
      </tbody>
    </template>
  </v-simple-table> 
        
  </v-container>
      </v-col>
    </v-row>
</template>

<script>
export default {
  props: ['cardData'],
}
</script>